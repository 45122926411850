import React, { useEffect } from 'react';
import Routes from './Routes/index';

//import Custom Style scss
import './assets/scss/themes.scss';
import { store } from './Redux/store';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

function App() {
  var layoutMode = document.body.getAttribute('data-layout-mode');
  let persistor = persistStore(store);
  return (
    <React.Fragment>
      <CookiesProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <div
            // style={
            //   layoutMode === 'dark'
            //     ? { backgroundColor: '#303841' }
            //     : { backgroundColor: '#EDF2F9' }
            // }
            >
              <Routes />
            </div>
          </PersistGate>
        </Provider>
      </CookiesProvider>
    </React.Fragment>
  );
}

export default App;

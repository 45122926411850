import { PieChart } from '@mui/icons-material';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { pink } from '@mui/material/colors';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

import Chart from 'react-apexcharts';
import PieChartComponent from './PieChart';
import SparkLinesChart from './SparkLinesChart';

const ApexChart = () => {
  // Sample data
  const monthlySalesData = [
    { month: 'Jan', sales: 1000 },
    { month: 'Feb', sales: 1500 },
    { month: 'Mar', sales: 2000 },
    { month: 'Apr', sales: 2500 },
    { month: 'May', sales: 2200 },
    { month: 'Jun', sales: 1800 },
    { month: 'Jul', sales: 2100 },
    { month: 'Aug', sales: 2400 },
    { month: 'Sep', sales: 2800 },
    { month: 'Oct', sales: 3200 },
    { month: 'Nov', sales: 3500 },
    { month: 'Dec', sales: 3900 }
  ];

  // ApexCharts options
  const TotalShipmentOptions = {
    series: [
      {
        data: monthlySalesData
      }
    ],
    chart: {
      type: 'area',
      height: 160,
      sparkline: {
        enabled: true
      }
    },
    stroke: {
      curve: 'straight'
    },
    fill: {
      opacity: 0.3
    },
    yaxis: {
      min: 0
    },
    colors: ['#DCE6EC'],
    title: {
      text: '424,652',
      offsetX: 0,
      style: {
        fontSize: '24px'
      }
    },
    subtitle: {
      text: 'Total Shipments',
      offsetX: 0,
      style: {
        fontSize: '15px'
      }
    }
  };

  const DeliveredShipmentOptions = {
    series: [
      {
        data: monthlySalesData
      }
    ],
    chart: {
      type: 'area',
      height: 160,
      sparkline: {
        enabled: true
      }
    },
    stroke: {
      curve: 'straight'
    },
    fill: {
      opacity: 0.3
    },
    yaxis: {
      min: 0
    },
    colors: ['#DCE6EC'],
    title: {
      text: '314,521',
      offsetX: 0,
      style: {
        fontSize: '24px'
      }
    },
    subtitle: {
      text: 'Delivered Shipments',
      offsetX: 0,
      style: {
        fontSize: '15px'
      }
    }
  };

  const options = {
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    }
  };

  const TotalExpenseOptions = {
    series: [
      {
        data: monthlySalesData
      }
    ],
    chart: {
      type: 'area',
      height: 160,
      sparkline: {
        enabled: true
      }
    },
    stroke: {
      curve: 'straight'
    },
    fill: {
      opacity: 0.3
    },
    yaxis: {
      min: 0
    },
    colors: ['#DCE6EC'],
    title: {
      text: '$14,521',
      offsetX: 0,
      style: {
        fontSize: '24px'
      }
    },
    subtitle: {
      text: 'Total Expense',
      offsetX: 0,
      style: {
        fontSize: '15px'
      }
    }
  };

  const series = [
    {
      name: 'booked',
      data: [30, 40, 25, 50, 49, 21, 70, 51, 49, 21, 70, 51]
    }
    // {
    //   name: 'series-2',
    //   data: [23, 12, 54, 61, 32, 56, 81, 19]
    // }
    // {
    //   name: 'series-3',
    //   data: [24, 20, 5, 75, 42, 79, 72, 35]
    // }
  ];
  const pieOption = {
    labels: ['Red', 'Blue', 'Yellow']
  };
  return (
    <Box
      sx={{
        height: '90vh',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          display: 'flex',
          margin: 2,
          justifyContent: 'space-evenly'
        }}
      >
        <Card className='box_shadow' sx={{ height: 180 }}>
          <CardContent sx={{ textAlign: 'center' }}>
            <SparkLinesChart
              data={monthlySalesData}
              options={TotalShipmentOptions}
            />
          </CardContent>
        </Card>
        <Card className='box_shadow' sx={{ height: 180 }}>
          <CardContent sx={{ textAlign: 'center' }}>
            <SparkLinesChart
              data={monthlySalesData}
              options={DeliveredShipmentOptions}
            />
          </CardContent>
        </Card>
        <Card className='box_shadow' sx={{ height: 180 }}>
          <CardContent sx={{ textAlign: 'center' }}>
            <SparkLinesChart
              data={monthlySalesData}
              options={TotalExpenseOptions}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          margin: 2
        }}
      >
        <Card className='box_shadow'>
          <CardContent sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant='h4' component='div'>
              Montly Sales
            </Typography>
            <Chart
              height={'90%'}
              width={'220%'}
              options={options}
              series={series}
              type='bar'
            />
          </CardContent>
        </Card>
        <Card className='box_shadow'>
          <PieChartComponent />
        </Card>
      </Grid>
    </Box>
  );
};
export default ApexChart;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  UserDetailsObj: {},
  AgencyAdminDetails: {},
  UserColumnOrder: [],
  UserColumnVisibility: {}
};

export const userSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    setUserDetailsObj: (state, action) => {
      state.UserDetailsObj = action.payload;
    },
    setAgencyAdminDetails: (state, action) => {
      state.AgencyAdminDetails = action.payload;
    },
    setUserColumnOrder: (state, action) => {
      state.UserColumnOrder = action.payload;
    },
    setUserColumnVisibility: (state, action) => {
      state.UserColumnVisibility = action.payload;
    }
    // setisLoggedRedux: (state, action) => {
    //   state.name = action.payload;
    // }
  },
  extraReducers: {
    // Extra reducer comes here
  }
});

export const userLoginDetailsSlice = createSlice({
  name: 'userLogin',
  initialState: {
    password: '',
    rememberMe: false,
    email: '',
    token: '',
    agencyLoginID: '',
    userRolePermissionsArr: [],
    isAdmin: false,
    currentRoute: '/manage-shipments',
    assignedRoutesArray: []
  },
  reducers: {
    setPasswordRedux: (state, action) => {
      state.password = action.payload;
    },
    setRememberMeRedux: (state, action) => {
      state.rememberMe = action.payload;
    },
    setEmailRedux: (state, action) => {
      state.email = action.payload;
    },
    setUserToken: (state, action) => {
      state.token = action.payload;
    },
    setLoginAgencyID: (state, action) => {
      state.agencyLoginID = action.payload;
    },
    setUserRolePermissionsArr: (state, action) => {
      state.userRolePermissionsArr = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setCurrentRoute: (state, action) => {
      state.currentRoute = action.payload;
    },
    setAssignedRoutesArray: (state, action) => {
      state.assignedRoutesArray = action.payload;
    }
  }
});

// export const userEducation = createSlice({
//   name:"userEducation"
// })

export const {
  setUserDetailsObj,
  setAgencyAdminDetails,
  setUserColumnOrder,
  setUserColumnVisibility
} = userSlice.actions;
export const {
  setPasswordRedux,
  setRememberMeRedux,
  setEmailRedux,
  setUserToken,
  setLoginAgencyID,
  setUserRolePermissionsArr,
  setIsAdmin,
  setCurrentRoute,
  setAssignedRoutesArray
} = userLoginDetailsSlice.actions;

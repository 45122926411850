import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    width: 56,
    height: 26,
    padding: 0
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(30px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none'
      }
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.red,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },

  // Don't remove this empty class.
  checked: {},

  switchRoot: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    cursor: 'pointer',
    width: 0
  },
  label: {
    fontSize: 12,
    position: 'absolute',
    zIndex: 1,
    color: theme.palette.common.white,
    userSelect: 'none',
    pointerEvents: 'none'
  },
  left: {
    left: 6
  },
  right: {
    left: 27
  },
  inputRoot: {
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
      {
        '-webkit-appearance': 'none',
        margin: 0
      },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    }
  },
  input: {
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
      {
        '-webkit-appearance': 'none',
        margin: 0
      },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      }
    }
  },
  card: {
    minWidth: 275,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      minWidth: 200
    }
  }
}));

import React, { memo, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import ExpandAbleTable from './ExpandAbleTable';
import { base_url } from '../../../apis/constants';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Layout2 = props => {
  const UserIsAdmin = useSelector(state => state.userDetails.isAdmin);

  const history = useHistory();

  // useEffect(() => {
  //   if (!UserIsAdmin) {
  //     history.push('/quick-quote');
  //   }
  // }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          {' '}
          {base_url === 'https://beta.api.shiptechpro.com/'
            ? 'Shipments | Shiptechpro'
            : 'Shipments | TMS Shipping'}
        </title>
      </MetaTags>
      <ExpandAbleTable props={props?.location?.state} />
    </React.Fragment>
  );
};
export default memo(Layout2);

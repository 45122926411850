import React, { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import { Button, Col, Label, Row } from 'reactstrap';
import {
  Autocomplete,
  CardContent,
  Card,
  FormControlLabel,
  InputAdornment,
  Paper,
  TextField,
  ToggleButtonGroup,
  Box,
  CircularProgress
} from '@mui/material';
import Switch from '@mui/material/Switch';
import MergeTypeIcon from '@material-ui/icons/LocalShippingSharp';
import LocationIcon from '@material-ui/icons/PersonPinSharp';
import ToggleButton from '@mui/material/ToggleButton';
import PaymentIcon from '@material-ui/icons/PaymentSharp';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useState } from 'react';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useSelector } from 'react-redux';
import {
  GetCTQuoteRate,
  GetEstesQuoteRate,
  GetFedExQuoteRate,
  GetLocations,
  GetSAIAQuoteRate,
  GetShipments,
  GetXPOQuoteRate
} from '../../apis/Agency';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setCTQuoteRate,
  setEstesQuoteRate,
  setFedExRates,
  setRateValues,
  setSAIAQuoteRate,
  setXPOQuoteRate
} from '../../Redux/GetRatesSlice';
// import './getQuote.css';
import {
  setDeliveryCheckedRedux,
  setPickUpCheckedRedux
} from '../../Redux/GetServicesSwitchSlice';

function GetQuotes(props) {
  var layoutMode = document.body.getAttribute('data-layout-mode');
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const dateNow = new Date();
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  const UserDetails = useSelector(state => state.userDetails);
  const viewShipmentsData = useSelector(state => state.getShipments);
  const SelectedRates = useSelector(state => state.getCarrierRates);
  const ServicesSwitchChecks = useSelector(state => state.getServicesSwitch);
  const selectedTableRowID = props.location.state;
  const dispatch = useDispatch();
  const classes = useStyles();

  const PaymentTypeArr = ['Outbound Prepaid', 'Third Party'];
  const [open, setOpen] = useState(false);
  const [shipTypeOptions, setShipTypeOptions] = useState([]);
  const [hazmatSwitch, setHazmatSwitch] = useState(SelectedRates?.isHazmat);
  const [stackableSwitch, setStackableSwitch] = useState(
    SelectedRates?.commodityInfo?.[0]?.dimensions?.isStackable !== undefined
      ? SelectedRates?.commodityInfo?.[0]?.dimensions?.isStackable
      : false
  );
  const [pickUpChecked, setPickUpChecked] = useState(
    ServicesSwitchChecks.PickUpCheckedRedux
  );
  const [deliveryChecked, setDeliveryChecked] = useState(
    ServicesSwitchChecks.DeliveryCheckedRedux
  );

  //pickup switch states
  const [insidePickupSwitch, setInsidePickupSwitch] = useState(
    SelectedRates?.pickupOptions?.inside
      ? SelectedRates?.pickupOptions?.inside
      : false
  );
  const [liftgatePickupSwitch, setLiftgatePickupSwitch] = useState(
    SelectedRates?.pickupOptions?.liftGateServices
      ? SelectedRates?.pickupOptions?.liftGateServices
      : false
  );
  const [residentialPickupSwitch, setResidentialPickupSwitch] = useState(
    SelectedRates?.pickupOptions?.residential
      ? SelectedRates?.pickupOptions?.residential
      : false
  );
  const [secureAccesPickupSwitch, setSecureAccesPickupSwitch] = useState(
    SelectedRates?.pickupOptions?.secureAccess
      ? SelectedRates?.pickupOptions?.secureAccess
      : false
  );
  const [limitedPickupAccessSwitch, setLimitedPickupAccessSwitch] = useState(
    SelectedRates?.pickupOptions?.limitedAccess
      ? SelectedRates?.pickupOptions?.limitedAccess
      : false
  );
  const [freezeProtectionPickupSwitch, setFreezeProtectionPickupSwitch] =
    useState(
      SelectedRates?.pickupOptions?.freezeProtection
        ? SelectedRates?.pickupOptions?.freezeProtection
        : false
    );
  const [overlengthPickupSwitch, setOverlengthPickupSwitch] = useState(
    SelectedRates?.pickupOptions?.overLength
      ? SelectedRates?.pickupOptions?.overLength
      : false
  );
  //delivery switch states
  const [insideDeliverySwitch, setInsideDeliverySwitch] = useState(
    SelectedRates?.deliveryOptions?.inside
      ? SelectedRates?.deliveryOptions?.inside
      : false
  );
  const [liftgateDeliverySwitch, setLiftgateDeliverySwitch] = useState(
    SelectedRates?.deliveryOptions?.liftGateServices
      ? SelectedRates?.deliveryOptions?.liftGateServices
      : false
  );
  const [residentialDeliverySwitch, setResidentialDeliverySwitch] = useState(
    SelectedRates?.deliveryOptions?.residential
      ? SelectedRates?.deliveryOptions?.residential
      : false
  );
  const [limitedDeliverySwitch, setLimitedDeliverySwitch] = useState(
    SelectedRates?.deliveryOptions?.limitedAccess
      ? SelectedRates?.deliveryOptions?.limitedAccess
      : false
  );
  const [secureAccesDeliverySwitch, setSecureAccesDeliverySwitch] = useState(
    SelectedRates?.deliveryOptions?.secureAccess
      ? SelectedRates?.deliveryOptions?.secureAccess
      : false
  );
  const [
    callBeforeDeliveryServicesSwitch,
    setCallBeforeDeliveryServicesSwitch
  ] = useState(
    SelectedRates?.deliveryOptions?.callBeforeDelivery
      ? SelectedRates?.deliveryOptions?.callBeforeDelivery
      : false
  );
  const [newAddedRow, setNewAddedRow] = useState([]);
  const [newAddedClassRow, setNewAddedClassRow] = useState([]);
  // const [viewShipmentsData, setViewShipmentsData] = useState([]);
  const [shipmentDate, setShipmentDate] = useState(materialDateInput);
  const DataToShow = viewShipmentsData.ShipmentDetails[selectedTableRowID];
  const [loading, setLoading] = useState(false);
  const ShipmentType = DataToShow?.shipmentType;
  const ShipTypeArr = ['LTL', 'Parcel', 'TL', 'Tanker', 'Others'];
  const UnitTypeArr = ['Pallet', 'Tote', 'Box', 'Drum'];
  const [shipTypeValue, setShipTypeValue] = useState(ShipTypeArr[0]);
  const [unitTypeValue, setUnitTypeValue] = useState(UnitTypeArr[0]);

  const [shipLocation, setShipLocation] = useState([]);
  const [shipLocationValue, setshipLocationValue] = useState('');
  const [paymentTypeValue, setPaymentTypeValue] = useState('');
  const [backBtnClicked, setBackBtnClicked] = useState(false);

  const ClassArray = [
    '50',
    '55',
    '60',
    '65',
    '70',
    '77.5',
    '85',
    '92.5',
    '100',
    '110',
    '125',
    '150',
    '175',
    '200',
    '250',
    '300',
    '400',
    '500'
  ];

  const [classArrayValue, setClassArrayValue] = useState(
    SelectedRates?.commodityInfo?.[0]?.freightClass
      ? SelectedRates?.commodityInfo?.[0]?.freightClass
      : ClassArray[0]
  );
  const [NMFCnumber, setNMFCnumber] = useState(
    SelectedRates?.commodityInfo?.[0]?.nmfc
  );
  const [dimensionsWeightLBS, setDimensionsWeightLBS] = useState('');
  const [dimensionsWeightLBSErr, setDimensionsWeightLBSErr] = useState(false);
  const [nmbOfunitsErr, setNmbOfunitsErr] = useState(false);
  const [nmbOfunits, setNmbOfunits] = useState('');
  const [dimensions, setDimensions] = useState({
    Lenght: '',
    Width: '',
    Height: ''
  });
  const [dimensionsValueErr, setDimensionsValueErr] = useState({
    Lenght: false,
    Width: false,
    Height: false
  });
  const [CODAmount, setCODAmount] = useState(
    SelectedRates?.codAmount ? SelectedRates?.codAmount : ''
  );
  const [DeclaredValue, setDeclaredValue] = useState(
    SelectedRates?.declaredValue ? SelectedRates?.declaredValue : ''
  );
  const [getRateLoading, setGetRateLoading] = useState(false);
  const [selectClassErr, setSelectClassErr] = useState(false);
  const [dimensionsErr, setDimensionsErr] = useState(false);
  const history = useHistory();
  const [isOverLength, setIsOverLength] = useState(
    SelectedRates.pickupOptions?.overLength
      ? SelectedRates?.pickupOptions?.overLength
      : false
  );
  const [overLengthValue, setOverLengthValue] = useState(
    SelectedRates.pickupOptions?.overLengthValue
      ? SelectedRates?.pickupOptions?.overLengthValue
      : ''
  );
  const [alignment, setAlignment] = useState('Class');
  const DetailsArr = [];

  const ShipFrom =
    DataToShow?.originCity +
    ' ' +
    DataToShow?.originState +
    ' ' +
    DataToShow?.originZipCode;

  const ShipTo =
    DataToShow?.destinationCity +
    ' ' +
    DataToShow?.destinationState +
    ' ' +
    DataToShow?.destinationZipCode;
  const WeightLBS = DataToShow?.details[0].grossWeight;
  const WeightPieces = DataToShow?.details[0].pieces;

  const handleDimensionChange = (name, dimensionsVal) => {
    if (name === 'length') {
      setDimensions({ ...dimensions, Lenght: dimensionsVal });
      setDimensionsValueErr({ ...dimensionsValueErr, Lenght: false });
    } else if (name === 'width') {
      setDimensions({ ...dimensions, Width: dimensionsVal });
      setDimensionsValueErr({ ...dimensionsValueErr, Width: false });
    } else setDimensions({ ...dimensions, Height: dimensionsVal });
    setDimensionsValueErr({ ...dimensionsValueErr, Height: false });
  };
  const handleGetRates = e => {
    e.preventDefault();
    if (dimensionsErr) {
      if (!dimensionsWeightLBS) {
        setDimensionsWeightLBSErr(true);
      }
      if (!nmbOfunits) {
        setNmbOfunitsErr(true);
      }
      if (!dimensions.Lenght) {
        setDimensionsValueErr({ ...dimensionsValueErr, Lenght: true });
      }
      if (!dimensions.Width) {
        setDimensionsValueErr({ ...dimensionsValueErr, Width: true });
      }
      if (!dimensions.Height) {
        setDimensionsValueErr({ ...dimensionsValueErr, Height: true });
      }
    }
    if (!classArrayValue) {
      setSelectClassErr(true);
    } else if (classArrayValue) {
      setSelectClassErr(false);
      // setGetRateLoading(true);
      let obj = {
        shipmentType: ShipmentType,
        location: shipLocationValue,
        paymentType: paymentTypeValue,
        shipFrom: {
          city: DataToShow?.originCity.trim(),
          stateOrProvinceCode: DataToShow?.originState.trim(),
          postalCode: DataToShow?.originZipCode,
          countryCode: 'USA',
          residential: true,
          streetLines: DataToShow?.originAddress.trim()
        },
        shipTo: {
          city: DataToShow?.destinationCity.trim(),
          stateOrProvinceCode: DataToShow?.destinationState.trim(),
          postalCode: DataToShow?.destinationZipCode,
          countryCode: 'USA',
          residential: true,
          streetLines: DataToShow?.destinationAddress.trim()
        },
        shipmentDate: shipmentDate,
        isHazmat: hazmatSwitch,
        commodityInfo: [
          {
            freightClass: classArrayValue,
            packaging: DataToShow?.packagingType,
            pieces: WeightPieces,
            weight: {
              units: 'lbs',
              value: WeightLBS
            },
            nmfc: NMFCnumber ? NMFCnumber : '',
            dimensions: {
              length: dimensions.Lenght ? dimensions.Lenght : 0,
              width: dimensions.Width ? dimensions.Width : 0,
              height: dimensions.Height ? dimensions.Height : 0,
              dimensionsUom: 'cm',
              isStackable: stackableSwitch
            }
          }
        ],

        pickupOptions: {
          inside: insidePickupSwitch,
          liftGateServices: liftgatePickupSwitch,
          residential: residentialPickupSwitch,
          secureAccess: secureAccesPickupSwitch,
          limitedAccess: limitedPickupAccessSwitch,
          freezeProtection: freezeProtectionPickupSwitch,
          overLength: overlengthPickupSwitch,
          overLengthValue: overLengthValue ? parseFloat(overLengthValue) : 0,
          callBeforeDelivery: false
        },
        deliveryOptions: {
          inside: insideDeliverySwitch,
          liftGateServices: liftgateDeliverySwitch,
          residential: residentialDeliverySwitch,
          secureAccess: secureAccesDeliverySwitch,
          limitedAccess: limitedDeliverySwitch,
          freezeProtection: false,
          overLength: false,
          overLengthValue: 0,
          callBeforeDelivery: callBeforeDeliveryServicesSwitch
        },
        codAmount: CODAmount ? parseInt(CODAmount) : 0,
        declaredValue: DeclaredValue ? parseInt(DeclaredValue) : 0,
        agencyId: AgencyID ? AgencyID : 0
      };

      dispatch(setPickUpCheckedRedux(pickUpChecked));
      dispatch(setDeliveryCheckedRedux(deliveryChecked));
      dispatch(setRateValues(obj));
      let deepClone = JSON.parse(JSON.stringify(obj));
      deepClone.shipFrom.countryCode = 'USA';
      deepClone.shipTo.countryCode = 'USA';
      history.push('/get-rates', {
        state: obj,
        deepClone,
        totalLBSWeight: WeightLBS,
        quote: 'quote',
        DataToShow: DataToShow
      });
    }
  };
  const handlePickUpServicesChange = switchName => {
    if (switchName === 'hazmat') {
      setHazmatSwitch(c => !c);
    } else if (switchName === 'Stackable') {
      setStackableSwitch(c => !c);
    } else if (switchName === 'Inside Pickup') {
      setInsidePickupSwitch(c => !c);
    } else if (switchName === 'Inside Lift Gate Services') {
      setLiftgatePickupSwitch(c => !c);
    } else if (switchName === 'Residential Pickup') {
      setResidentialPickupSwitch(c => !c);
    } else if (switchName === 'Secure Access') {
      setSecureAccesPickupSwitch(c => !c);
    } else if (switchName === 'Freeze Protection') {
      setFreezeProtectionPickupSwitch(c => !c);
    } else if (switchName === 'Overlength') {
      setOverlengthPickupSwitch(c => !c);
      setIsOverLength(!isOverLength);
    } else setLimitedPickupAccessSwitch(c => !c);
  };

  const handleDeliveryServicesChange = switchName => {
    if (switchName === 'Inside Delivery') {
      setInsideDeliverySwitch(c => !c);
    } else if (switchName === 'Liftgate Delivery') {
      setLiftgateDeliverySwitch(c => !c);
    } else if (switchName === 'Residential Delivery') {
      setResidentialDeliverySwitch(c => !c);
    } else if (switchName === 'Secure Access') {
      setSecureAccesDeliverySwitch(c => !c);
    } else if (switchName === 'Call Before Delivery') {
      setCallBeforeDeliveryServicesSwitch(c => !c);
    } else setLimitedDeliverySwitch(c => !c);
  };
  const checkPickupStatus = () => {
    if (pickUpChecked) {
      // alert('asdas');
      setInsidePickupSwitch(false);
      setLiftgatePickupSwitch(false);
      setResidentialPickupSwitch(false);
      setSecureAccesPickupSwitch(false);
      setFreezeProtectionPickupSwitch(false);
      setOverlengthPickupSwitch(false);
      setLimitedPickupAccessSwitch(false);
      let obj = {
        shipmentType: ShipmentType,
        location: shipLocationValue,
        paymentType: paymentTypeValue,
        shipFrom: {
          city: DataToShow?.originCity.trim(),
          stateOrProvinceCode: DataToShow?.originState.trim(),
          postalCode: DataToShow?.originZipCode,
          countryCode: 'USA',
          residential: true,
          streetLines: DataToShow?.originAddress.trim()
        },
        shipTo: {
          city: DataToShow?.destinationCity.trim(),
          stateOrProvinceCode: DataToShow?.destinationState.trim(),
          postalCode: DataToShow?.destinationZipCode,
          countryCode: 'USA',
          residential: true,
          streetLines: DataToShow?.destinationAddress.trim()
        },
        shipmentDate: shipmentDate,
        isHazmat: hazmatSwitch,
        commodityInfo: [
          {
            freightClass: classArrayValue,
            packaging: DataToShow?.packagingType,
            pieces: WeightPieces,
            weight: {
              units: 'lbs',
              value: WeightLBS
            },
            nmfc: NMFCnumber ? NMFCnumber : '',
            dimensions: {
              length: dimensions.Lenght ? dimensions.Lenght : 0,
              width: dimensions.Width ? dimensions.Width : 0,
              height: dimensions.Height ? dimensions.Height : 0,
              dimensionsUom: 'cm',
              isStackable: stackableSwitch
            }
          }
        ],

        pickupOptions: {
          inside: false,
          liftGateServices: false,
          residential: false,
          secureAccess: false,
          limitedAccess: false,
          freezeProtection: false,
          overLength: false,
          callBeforeDelivery: false
        },
        deliveryOptions: {
          inside: insideDeliverySwitch,
          liftGateServices: liftgateDeliverySwitch,
          residential: residentialDeliverySwitch,
          secureAccess: secureAccesDeliverySwitch,
          limitedAccess: limitedDeliverySwitch,
          freezeProtection: false,
          overLength: false,
          callBeforeDelivery: callBeforeDeliveryServicesSwitch
        },
        codAmount: CODAmount ? CODAmount : 0,
        declaredValue: DeclaredValue ? DeclaredValue : 0
      };

      dispatch(setRateValues(obj));
    }
  };
  const checkDeliveryStatus = () => {
    if (deliveryChecked) {
      // alert('asdas');
      setInsideDeliverySwitch(false);
      setLiftgateDeliverySwitch(false);
      setResidentialDeliverySwitch(false);
      setSecureAccesDeliverySwitch(false);
      setCallBeforeDeliveryServicesSwitch(false);
      setLimitedDeliverySwitch(false);
      let obj = {
        shipmentType: ShipmentType,
        location: shipLocationValue,
        paymentType: paymentTypeValue,
        shipFrom: {
          city: DataToShow?.originCity.trim(),
          stateOrProvinceCode: DataToShow?.originState.trim(),
          postalCode: DataToShow?.originZipCode,
          countryCode: 'USA',
          residential: true,
          streetLines: DataToShow?.originAddress.trim()
        },
        shipTo: {
          city: DataToShow?.destinationCity.trim(),
          stateOrProvinceCode: DataToShow?.destinationState.trim(),
          postalCode: DataToShow?.destinationZipCode,
          countryCode: 'USA',
          residential: true,
          streetLines: DataToShow?.destinationAddress.trim()
        },
        shipmentDate: shipmentDate,
        isHazmat: hazmatSwitch,
        commodityInfo: [
          {
            freightClass: classArrayValue,
            packaging: DataToShow?.packagingType,
            pieces: WeightPieces,
            weight: {
              units: 'lbs',
              value: WeightLBS
            },
            nmfc: NMFCnumber ? NMFCnumber : '',
            dimensions: {
              length: dimensions.Lenght ? dimensions.Lenght : 0,
              width: dimensions.Width ? dimensions.Width : 0,
              height: dimensions.Height ? dimensions.Height : 0,
              dimensionsUom: 'cm',
              isStackable: stackableSwitch
            }
          }
        ],

        pickupOptions: {
          inside: insidePickupSwitch,
          liftGateServices: liftgatePickupSwitch,
          residential: residentialPickupSwitch,
          secureAccess: secureAccesPickupSwitch,
          limitedAccess: limitedPickupAccessSwitch,
          freezeProtection: freezeProtectionPickupSwitch,
          overLength: overlengthPickupSwitch,
          callBeforeDelivery: false
        },
        deliveryOptions: {
          inside: false,
          liftGateServices: false,
          residential: false,
          secureAccess: false,
          limitedAccess: false,
          freezeProtection: false,
          overLength: false,
          callBeforeDelivery: false
        },
        codAmount: CODAmount ? CODAmount : 0,
        declaredValue: DeclaredValue ? DeclaredValue : 0
      };

      dispatch(setRateValues(obj));
    }
  };
  const handlePickupServices = v => {
    setPickUpChecked(c => !c);
    checkPickupStatus();
    // setDeliveryChecked(false);
  };

  const handleDeliveryServices = () => {
    setDeliveryChecked(c => !c);
    checkDeliveryStatus();
    // setPickUpChecked(false);
  };
  const handleChangeToggle = (event, newAlignment) => {
    setAlignment(newAlignment);
    if (newAlignment === 'Dimensions') {
      setDimensionsErr(true);
    } else setDimensionsErr(false);
  };

  const handleSetShipLocation = arr => {
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i].smValue;
      shipLocation.push(element);
    }
  };
  useEffect(() => {
    GetLocations(AgencyID)
      .then(res => res.status === 200 && handleSetShipLocation(res.data.data))
      .catch(err => console.log(err));
  }, []);
  const handleCheckAddedRow = () => {};
  const handleAddNewRow = e => {
    const temp = [];
    if (e === 'class') {
      temp.push(...newAddedClassRow, {
        title: 'new row added'
      });
      setNewAddedClassRow(temp);
    } else {
      temp.push(...newAddedRow, {
        title: 'new row added'
      });
      setNewAddedRow(temp);
    }
  };

  const handleSubtractNewRow = (e, removedIndex) => {
    if (e === 'class') {
      const temp = newAddedClassRow.slice(0, removedIndex);
      // newAddedClassRow.pop(newAddedClassRow[index]);
      setNewAddedClassRow(temp);
    } else {
      const temp = newAddedRow.slice(0, removedIndex);
      setNewAddedRow(temp);
    }
  };
  useEffect(() => {
    handleCheckAddedRow();
  }, [newAddedClassRow]);

  const switchStyle = {
    borderRadius: 2,
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#d81b60'
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#d81b60'
    },
    '&:hover .MuiSwitch-switchBase': {
      // color: 'brown'
    }
  };
  useEffect(() => {
    // window.history.pushState({ page: 1 }, '', '');

    window.onpopstate = function (event) {
      if (event) {
        dispatch(setRateValues(''));
        dispatch(setPickUpCheckedRedux(false));
        dispatch(setDeliveryCheckedRedux(false));
      } else {
      }
    };
  }, []);

  return (
    <div
      style={
        layoutMode === 'dark'
          ? {
              backgroundColor: '#303841',
              display: 'flex',
              justifyContent: 'center',
              // margin: '10 0 0 0',
              padding: '10 0 10 0',
              height: '100%'
            }
          : {
              display: 'flex',
              justifyContent: 'center',
              // margin: '10 0 0 0',
              padding: '10 0 10 0',
              height: '100%'
              // backgroundColor: '#EDF2F9'
            }
      }
    >
      {loading ? (
        <Box sx={{ display: 'flex', marginTop: 20 }}>
          <CircularProgress />
        </Box>
      ) : (
        <div
        // style={{
        //   backgroundColor: '#EDF2F9'
        // }}
        >
          <Paper style={{ borderRadius: 20 }}>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <div style={{ display: 'flex' }}>
                  <div lg={3} sm={3}>
                    {ShipmentType !== undefined && (
                      <Autocomplete
                        disabled
                        size='small'
                        options={ShipTypeArr}
                        value={ShipmentType}
                        onChange={(event, newValue) => {
                          setShipTypeValue(newValue);
                        }}
                        // sx={{ marginLeft: 1 }}
                        renderInput={params => (
                          <TextField
                            sx={{ width: 260 }}
                            placeholder='Ship type'
                            variant='standard'
                            {...params}
                            InputProps={{
                              style: { fontSize: 12 },
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <MergeTypeIcon />
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                      />
                    )}
                  </div>
                  {/* {console.log(shipLocation, 'shipLocation')} */}
                  <div lg={3} sm={3}>
                    <Autocomplete
                      disabled
                      size='small'
                      options={shipLocation}
                      onChange={(event, newValue) => {
                        setshipLocationValue(newValue);
                      }}
                      sx={{ marginLeft: 1 }}
                      renderInput={params => (
                        <TextField
                          sx={{ width: 260 }}
                          placeholder='Location'
                          variant='standard'
                          {...params}
                          InputProps={{
                            style: { fontSize: 12 },
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position='start'>
                                <LocationIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Autocomplete
                      disabled
                      size='small'
                      options={PaymentTypeArr}
                      onChange={(event, newValue) => {
                        setPaymentTypeValue(newValue);
                      }}
                      sx={{ marginLeft: 1 }}
                      renderInput={params => (
                        <TextField
                          sx={{ width: 250 }}
                          placeholder='Payment Type'
                          variant='standard'
                          {...params}
                          InputProps={{
                            style: { fontSize: 12 },
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position='start'>
                                <PaymentIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20
                  }}
                >
                  <Label
                    style={{
                      margin: '1px 0px 1px 0px',
                      fontWeight: 700,
                      fontSize: 12
                    }}
                  >
                    Shipment Info
                  </Label>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                      <FormControl sx={{ mt: 1, minWidth: 200 }} size='large'>
                        <TextField
                          disabled
                          // ={
                          //   paymentTypeValue === 'Outbound Prepaid'
                          //     ? true
                          //     : false
                          // }
                          value={ShipFrom}
                          size='small'
                          sx={{ width: 260, marginRight: 1, fontSize: 12 }}
                          id='outlined-password-input'
                          label='Ship From'
                          type='Text'
                          autoComplete='current-password'
                          InputLabelProps={{ style: { fontSize: 12 } }}
                          inputProps={{
                            style: {
                              height: 15,
                              fontSize: 12
                            }
                          }}
                        />
                      </FormControl>
                    </div>
                    <div lg={3}>
                      <FormControl sx={{ mt: 1, minWidth: 200 }} size='large'>
                        <TextField
                          disabled
                          // ={
                          //   paymentTypeValue === 'Third Party' ? true : false
                          // }
                          value={ShipTo}
                          size='small'
                          sx={{ width: 260, marginRight: 1 }}
                          id='outlined-password-input'
                          label='Ship To'
                          type='Text'
                          autoComplete='current-password'
                          InputLabelProps={{ style: { fontSize: 12 } }}
                          inputProps={{
                            style: {
                              height: 15,
                              fontSize: 12
                            }
                          }}
                        />
                      </FormControl>
                    </div>
                    <div>
                      <FormControl sx={{ mt: 1, minWidth: 100 }} size='large'>
                        <TextField
                          onChange={e => setShipmentDate(e.target.value)}
                          size='small'
                          sx={{ width: 120, marginRight: 1 }}
                          id='outlined-password-input'
                          // label="Ship Date"
                          defaultValue={shipmentDate}
                          type='date'
                          // autoComplete='current-password'
                          InputLabelProps={{ style: { fontSize: 12 } }}
                          inputProps={{
                            style: {
                              height: 15,
                              fontSize: 12
                            }
                          }}
                        />
                      </FormControl>
                    </div>
                    <div>
                      {/* <FormControl sx={{ m: 0, minWidth: 30 }} size='large'> */}
                      <div
                        style={{ margin: '10 10 0 0' }}
                        className={classes.switchRoot}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={hazmatSwitch}
                              value={hazmatSwitch}
                              sx={switchStyle}
                              onChange={() =>
                                handlePickUpServicesChange('hazmat')
                              }
                              name='hazmat'
                            />
                          }
                          label={
                            <Typography
                              style={{
                                // margin: '0 0 0 -20',
                                fontSize: 12,
                                // whiteSpace: 'nowrap',
                                fontWeight: 700
                              }}
                            >
                              Hazmat
                            </Typography>
                          }
                        />
                      </div>
                      {/* </FormControl> */}
                    </div>
                  </div>
                </div>
                <div>
                  <Label
                    style={{
                      margin: '20px 0px 0px 0px',
                      fontWeight: 700,
                      fontSize: 12
                    }}
                  >
                    Commodity Info
                  </Label>
                  <Col lg={12}>
                    <ToggleButtonGroup
                      style={{
                        outlineColor: '#1f86ef'
                      }}
                      sx={{ mt: 1, minWidth: 200 }}
                      color='primary'
                      value={alignment}
                      exclusive
                      onChange={handleChangeToggle}
                      aria-label='Platform'
                    >
                      <ToggleButton
                        style={{
                          textTransform: 'capitalize',
                          backgroundColor:
                            alignment === 'Class' ? '#1f86ef' : 'white',
                          color: alignment === 'Class' ? 'white' : 'black',
                          fontWeight: 700,
                          fontSize: 12,
                          width: 150,
                          height: 30
                        }}
                        value='Class'
                      >
                        Class
                      </ToggleButton>
                      <ToggleButton
                        style={{
                          textTransform: 'capitalize',
                          backgroundColor:
                            alignment === 'Dimensions' ? '#1f86ef' : 'white',
                          color: alignment === 'Dimensions' ? 'white' : 'black',
                          fontWeight: 700,
                          fontSize: 12,
                          width: 150,
                          height: 30
                        }}
                        value='Dimensions'
                      >
                        Dimensions
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Col>
                </div>
                {alignment === 'Class' ? (
                  <>
                    <Row lg={12}>
                      <Col lg={4}>
                        <FormControl sx={{ minWidth: 180 }} size='large'>
                          <Label
                            style={{
                              margin: '10px 0px 6px 0px',
                              fontWeight: 700,
                              fontSize: 12
                            }}
                          >
                            Weight (lbs)
                          </Label>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <AddCircleSharpIcon
                              style={{ cursor: 'pointer' }}
                              // onClick={e => handleAddNewRow('class')}
                            />

                            <TextField
                              disabled
                              size='small'
                              sx={{ width: 220 }}
                              id='outlined-password-input'
                              // label='Ship From'
                              type='Text'
                              autoComplete='current-password'
                              value={WeightLBS}
                              inputProps={{
                                style: {
                                  height: 15,
                                  fontSize: 12
                                }
                              }}
                            />
                          </div>
                        </FormControl>
                      </Col>
                      <Col lg={4}>
                        <FormControl sx={{ minWidth: 180 }} size='large'>
                          <Label
                            style={{
                              margin: '10px 0px 6px 0px',
                              fontWeight: 700,
                              fontSize: 12
                            }}
                          >
                            Class
                          </Label>
                          <Autocomplete
                            size='small'
                            value={classArrayValue}
                            options={ClassArray}
                            onChange={(event, newValue) => {
                              // setBackBtnClicked(false);
                              setClassArrayValue(newValue);
                              setSelectClassErr(false);
                            }}
                            // sx={{ marginLeft: 1 }}
                            renderInput={params => (
                              <TextField
                                sx={{ width: 250 }}
                                // placeholder='Payment Type'
                                variant='outlined'
                                {...params}
                                InputProps={{
                                  style: { fontSize: 12, height: 32 },
                                  ...params.InputProps
                                }}
                              />
                            )}
                          />
                          {selectClassErr && (
                            <small style={{ color: 'red' }}>
                              Please select class
                            </small>
                          )}
                        </FormControl>
                      </Col>
                      <Col lg={4}>
                        <FormControl sx={{ minWidth: 180 }} size='large'>
                          <Label
                            style={{
                              margin: '10px 0px 6px 0px',
                              fontWeight: 700,
                              fontSize: 12
                            }}
                          >
                            NMFC
                          </Label>
                          <TextField
                            value={NMFCnumber}
                            onChange={e => setNMFCnumber(e.target.value)}
                            size='small'
                            sx={{ width: 250 }}
                            id='outlined-password-input'
                            // label='Ship From'
                            type='number'
                            autoComplete='current-password'
                            // value={'149980-02'}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{
                              style: {
                                height: 15,
                                fontSize: 12
                              }
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: 12,
                              fontWeight: 'bold',
                              color: '#1976d2',
                              margin: '8 0 0 40'
                            }}
                          >
                            <u>Total Shipment Weight {WeightLBS} (lbs)</u>
                          </Typography>
                        </FormControl>
                      </Col>
                    </Row>
                    {newAddedClassRow.map((item, index, arr) => (
                      <Row lg={12}>
                        <Col lg={4}>
                          <FormControl sx={{ minWidth: 180 }} size='large'>
                            <Label
                              style={{
                                margin: '1px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              Weight (lbs)
                            </Label>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              {index + 1 === arr.length ? (
                                <RemoveCircleIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={e =>
                                    handleSubtractNewRow('class', index)
                                  }
                                />
                              ) : (
                                <AddCircleSharpIcon
                                  style={{ cursor: 'pointer' }}
                                  // onClick={e => handleAddNewRow('class')}
                                />
                              )}

                              <TextField
                                size='small'
                                sx={{ width: 220 }}
                                id='outlined-password-input'
                                // label='Ship From'
                                type='Text'
                                autoComplete='current-password'
                                value={WeightLBS}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    fontSize: 12
                                  }
                                }}
                              />
                            </div>
                          </FormControl>
                        </Col>
                        <Col lg={4}>
                          <FormControl sx={{ minWidth: 180 }} size='large'>
                            <Label
                              style={{
                                margin: '1px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              Class
                            </Label>
                            <Autocomplete
                              size='small'
                              options={ClassArray}
                              onChange={(event, newValue) => {
                                setClassArrayValue(newValue);
                              }}
                              // sx={{ marginLeft: 1 }}
                              renderInput={params => (
                                <TextField
                                  sx={{ width: 250 }}
                                  // placeholder='Payment Type'
                                  variant='outlined'
                                  {...params}
                                  InputProps={{
                                    style: { fontSize: 12, height: 32 },
                                    ...params.InputProps
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Col>
                        <Col lg={4}>
                          <FormControl sx={{ minWidth: 180 }} size='large'>
                            <Label
                              style={{
                                margin: '1px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              NMFC
                            </Label>
                            <TextField
                              value={NMFCnumber}
                              onChange={e => setNMFCnumber(e.target.value)}
                              size='small'
                              sx={{ width: 250 }}
                              id='outlined-password-input'
                              // label='Ship From'
                              type='Text'
                              autoComplete='current-password'
                              // value={'149980-02'}
                              InputLabelProps={{ style: { fontSize: 14 } }}
                              inputProps={{
                                style: {
                                  height: 15,
                                  fontSize: 12
                                }
                              }}
                            />
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: '#1976d2',
                                margin: '8 0 0 40'
                              }}
                            >
                              <u>Total Shipment Weight {WeightLBS} (lbs)</u>
                            </Typography>
                          </FormControl>
                        </Col>
                      </Row>
                    ))}
                  </>
                ) : (
                  <>
                    <Row lg={12}>
                      <Col lg={4}>
                        <FormControl
                          sx={{ minWidth: 100, width: 120 }}
                          size='large'
                        >
                          <Label
                            style={{
                              margin: '10px 0px 6px 0px',
                              fontWeight: 700,
                              fontSize: 12
                            }}
                          >
                            Weight (lbs)
                          </Label>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginRight: 10
                            }}
                          >
                            <AddCircleSharpIcon
                              // onClick={e => handleAddNewRow('dimnensions')}
                              style={{ cursor: 'pointer' }}
                            />
                            <TextField
                              disabled
                              value={WeightLBS}
                              // value={dimensionsWeightLBS}
                              onChange={e => {
                                setDimensionsWeightLBS(e.target.value);
                                setDimensionsWeightLBSErr(false);
                              }}
                              size='small'
                              sx={{ width: 100 }}
                              id='outlined-password-input'
                              // label='Ship From'
                              type='number'
                              autoComplete='current-password'
                              InputLabelProps={{ style: { fontSize: 12 } }}
                              inputProps={{
                                style: {
                                  fontSize: 12
                                  // height: 15
                                }
                              }}
                            />
                          </div>
                          {dimensionsWeightLBSErr && (
                            <small style={{ color: 'red', margin: '0 0 0 25' }}>
                              Enter Weight
                            </small>
                          )}
                        </FormControl>
                        <FormControl sx={{ minWidth: 100 }} size='large'>
                          <Label
                            style={{
                              margin: '10px 0px 6px 0px',
                              fontWeight: 700,
                              fontSize: 12
                            }}
                          >
                            Unit Type
                          </Label>
                          <Autocomplete
                            disabled
                            // sx={{ m: 1 }}
                            size='small'
                            options={UnitTypeArr}
                            value={DataToShow?.packagingType}
                            onChange={(event, newValue) => {
                              setUnitTypeValue(newValue);
                            }}
                            // sx={{ marginLeft: 1 }}
                            renderInput={params => (
                              <TextField
                                disabled
                                sx={{ width: 115 }}
                                placeholder='Ship type'
                                variant='outlined'
                                {...params}
                                InputProps={{
                                  style: { fontSize: 12 },
                                  ...params.InputProps
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Col>

                      <Col lg={4}>
                        <FormControl sx={{ minWidth: 100 }} size='large'>
                          <Label
                            style={{
                              margin: '10px 0px 6px 0px',
                              fontWeight: 700,
                              fontSize: 12
                            }}
                          >
                            # of Units
                          </Label>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginRight: 10
                            }}
                          >
                            <TextField
                              disabled
                              value={WeightPieces}
                              onChange={e => {
                                setNmbOfunits(e.target.value);
                                setNmbOfunitsErr(false);
                              }}
                              size='small'
                              sx={{ width: 120 }}
                              id='outlined-password-input'
                              // label='Ship From'
                              type='number'
                              autoComplete='current-password'
                              InputLabelProps={{ style: { fontSize: 12 } }}
                              inputProps={{
                                style: {
                                  height: 15,
                                  resize: {
                                    fontSize: 1
                                  }
                                }
                              }}
                            />

                            <div
                              style={{ margin: '0 0 0 5' }}
                              className={classes.switchRoot}
                            >
                              <FormControlLabel
                                control={
                                  <Switch
                                    value={stackableSwitch}
                                    checked={stackableSwitch}
                                    onChange={() =>
                                      handlePickUpServicesChange('Stackable')
                                    }
                                    color='primary'
                                    name='hazmat'
                                  />
                                }
                                label={
                                  <Typography
                                    style={{
                                      fontSize: 12,
                                      fontWeight: 700
                                    }}
                                  >
                                    Stackable
                                  </Typography>
                                }
                              />
                            </div>
                          </div>
                          {nmbOfunitsErr && (
                            <small style={{ color: 'red', margin: '0 0 0 0' }}>
                              Enter Nmb of units
                            </small>
                          )}
                        </FormControl>
                      </Col>
                      <Col lg={4}>
                        <FormControl sx={{ minWidth: 100 }} size='large'>
                          <Label
                            style={{
                              margin: '10px 0px 6px 0px',
                              fontWeight: 700,
                              fontSize: 12
                            }}
                          >
                            Dimensions
                          </Label>
                          <div style={{ display: 'flex' }}>
                            <TextField
                              value={dimensions.Lenght}
                              onChange={e =>
                                handleDimensionChange('length', e.target.value)
                              }
                              type='number'
                              className='inputRounded_Length'
                              size='small'
                              sx={{ width: 90 }}
                              id='outlined-password-input'
                              label='Length (cm)'
                              // autoComplete='current-password'
                              // value={'L'}
                              InputLabelProps={{ style: { fontSize: 11 } }}
                              inputProps={{
                                style: {
                                  height: 15,
                                  textAlign: 'center',
                                  resize: {
                                    fontSize: 1
                                  }
                                }
                              }}
                            />

                            <TextField
                              value={dimensions.Width}
                              onChange={e =>
                                handleDimensionChange('width', e.target.value)
                              }
                              type='number'
                              className='inputRounded_Width'
                              size='small'
                              sx={{ width: 80 }}
                              id='outlined-password-input'
                              label='Width (cm)'
                              autoComplete='current-password'
                              // value={'W'}
                              InputLabelProps={{ style: { fontSize: 11 } }}
                              inputProps={{
                                style: {
                                  height: 15,
                                  textAlign: 'center',
                                  resize: {
                                    fontSize: 1
                                  }
                                }
                              }}
                            />
                            <TextField
                              value={dimensions.Height}
                              onChange={e =>
                                handleDimensionChange('height', e.target.value)
                              }
                              type='number'
                              className='inputRounded_Height'
                              size='small'
                              sx={{ width: 80 }}
                              id='outlined-password-input'
                              label='Height (cm)'
                              autoComplete='current-password'
                              // value={'H'}
                              InputLabelProps={{ style: { fontSize: 11 } }}
                              inputProps={{
                                style: {
                                  height: 15,
                                  textAlign: 'center',
                                  resize: {
                                    fontSize: 1
                                  }
                                }
                              }}
                            />
                          </div>
                          {(dimensionsValueErr.Lenght ||
                            dimensionsValueErr.Width ||
                            dimensionsValueErr.Height) && (
                            <small style={{ color: 'red' }}>
                              Enter Dimensions
                            </small>
                          )}

                          <Typography
                            style={{
                              fontSize: 12,
                              fontWeight: 'bold',
                              color: '#1976d2',
                              margin: '8 0 0 40'
                            }}
                          >
                            <u>Total Shipment Weight 24,000 (lbs)</u>
                          </Typography>
                        </FormControl>
                      </Col>
                    </Row>
                    {newAddedRow.map((items, index, arr) => (
                      <Row lg={12}>
                        <Col lg={4}>
                          <FormControl
                            sx={{ minWidth: 100, width: 120 }}
                            size='large'
                          >
                            <Label
                              style={{
                                margin: '10px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              Weight (lbs)
                            </Label>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: 10
                              }}
                            >
                              {index + 1 === arr.length ? (
                                <RemoveCircleIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={e =>
                                    handleSubtractNewRow('dimensions', index)
                                  }
                                />
                              ) : (
                                <AddCircleSharpIcon
                                  style={{ cursor: 'pointer' }}
                                  // onClick={e => handleAddNewRow('class')}
                                />
                              )}
                              <TextField
                                size='small'
                                sx={{ width: 100 }}
                                id='outlined-password-input'
                                // label='Ship From'
                                type='Text'
                                autoComplete='current-password'
                                value={'3,600'}
                                InputLabelProps={{ style: { fontSize: 12 } }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    resize: {
                                      fontSize: 1
                                    }
                                  }
                                }}
                              />
                            </div>
                          </FormControl>
                          <FormControl sx={{ minWidth: 100 }} size='large'>
                            <Label
                              style={{
                                margin: '10px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              Unit Type
                            </Label>
                            <Autocomplete
                              // sx={{ m: 1 }}
                              size='small'
                              options={UnitTypeArr}
                              value={unitTypeValue}
                              onChange={(event, newValue) => {
                                setUnitTypeValue(newValue);
                              }}
                              // sx={{ marginLeft: 1 }}
                              renderInput={params => (
                                <TextField
                                  sx={{ width: 100 }}
                                  placeholder='Ship type'
                                  variant='outlined'
                                  {...params}
                                  InputProps={{
                                    style: { fontSize: 12 },
                                    ...params.InputProps
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Col>

                        <Col lg={4}>
                          <FormControl sx={{ minWidth: 100 }} size='large'>
                            <Label
                              style={{
                                margin: '10px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              # of Units
                            </Label>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: 10
                              }}
                            >
                              <TextField
                                size='small'
                                sx={{ width: 120 }}
                                id='outlined-password-input'
                                // label='Ship From'
                                type='Text'
                                autoComplete='current-password'
                                value={'2'}
                                InputLabelProps={{ style: { fontSize: 12 } }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    resize: {
                                      fontSize: 1
                                    }
                                  }
                                }}
                              />
                              <div
                                style={{ margin: '0 0 0 5' }}
                                className={classes.switchRoot}
                              >
                                <FormControlLabel
                                  control={
                                    <Switch
                                      onChange={() =>
                                        handlePickUpServicesChange('Stackable')
                                      }
                                      color='primary'
                                      name='hazmat'
                                    />
                                  }
                                  //   label='Stackable'
                                />
                                <Typography
                                  style={{
                                    margin: '0 0 0 -20',
                                    fontSize: 12,
                                    // whiteSpace: 'nowrap',
                                    fontWeight: 700
                                  }}
                                >
                                  Stackable
                                </Typography>
                              </div>
                            </div>
                          </FormControl>
                        </Col>
                        <Col lg={4}>
                          <FormControl sx={{ minWidth: 100 }} size='large'>
                            <Label
                              style={{
                                margin: '10px 0px 6px 0px',
                                fontWeight: 700,
                                fontSize: 12
                              }}
                            >
                              Dimensions
                            </Label>
                            <div style={{ display: 'flex' }}>
                              <TextField
                                value={dimensions.Lenght}
                                onChange={e =>
                                  setDimensions({
                                    ...dimensions,
                                    Lenght: e.target.value
                                  })
                                }
                                type='number'
                                className='inputRounded_Length'
                                size='small'
                                sx={{ width: 90 }}
                                id='outlined-password-input'
                                label='Length (cm)'
                                // autoComplete='current-password'
                                // value={'L'}
                                InputLabelProps={{ style: { fontSize: 11 } }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    textAlign: 'center',
                                    resize: {
                                      fontSize: 1
                                    }
                                  }
                                }}
                              />
                              <TextField
                                value={dimensions.Width}
                                onChange={e =>
                                  setDimensions({
                                    ...dimensions,
                                    Width: e.target.value
                                  })
                                }
                                type='number'
                                className='inputRounded_Width'
                                size='small'
                                sx={{ width: 80 }}
                                id='outlined-password-input'
                                label='Width (cm)'
                                autoComplete='current-password'
                                // value={'W'}
                                InputLabelProps={{ style: { fontSize: 11 } }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    textAlign: 'center',
                                    resize: {
                                      fontSize: 1
                                    }
                                  }
                                }}
                              />
                              <TextField
                                value={dimensions.Height}
                                onChange={e =>
                                  setDimensions({
                                    ...dimensions,
                                    Height: e.target.value
                                  })
                                }
                                type='number'
                                className='inputRounded_Height'
                                size='small'
                                sx={{ width: 80 }}
                                id='outlined-password-input'
                                label='Height (cm)'
                                autoComplete='current-password'
                                // value={'H'}
                                InputLabelProps={{ style: { fontSize: 11 } }}
                                inputProps={{
                                  style: {
                                    height: 15,
                                    textAlign: 'center',
                                    resize: {
                                      fontSize: 1
                                    }
                                  }
                                }}
                              />
                            </div>
                            <Typography
                              style={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: '#1976d2',
                                margin: '8 0 0 40'
                              }}
                            >
                              <u>Total Shipment Weight 24,000 (lbs)</u>
                            </Typography>
                          </FormControl>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
                <Row>
                  <Col lg={3}>
                    <div
                      style={{ margin: '10 90 0 0' }}
                      className={classes.switchRoot}
                    >
                      <Typography
                        onClick={e => handlePickupServices(e)}
                        style={{
                          margin: '0 10 0 4',
                          fontSize: 12,
                          whiteSpace: 'nowrap',
                          fontWeight: 700
                        }}
                      >
                        Pickup Services
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={e => handlePickupServices(e)}
                            checked={pickUpChecked}
                            color='primary'
                            name='Pickup Services'
                          />
                        }
                      />
                    </div>
                  </Col>

                  <Col lg={3}>
                    <div
                      style={{ margin: '10 90 0 0' }}
                      className={classes.switchRoot}
                    >
                      <Typography
                        onClick={() => handleDeliveryServices()}
                        style={{
                          margin: '0 10 0 4',
                          fontSize: 12,
                          whiteSpace: 'nowrap',
                          fontWeight: 700
                        }}
                      >
                        Delivery Services
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={() => handleDeliveryServices()}
                            checked={deliveryChecked}
                            color='primary'
                            name='Delivery Services'
                          />
                        }
                      />
                    </div>
                  </Col>
                </Row>
                {(pickUpChecked || deliveryChecked) && (
                  <>
                    <Row>
                      {
                        <>
                          {pickUpChecked && (
                            <Col lg={3}>
                              <FormControl
                                sx={{ m: 0, minWidth: 230 }}
                                size='large'
                              >
                                <div
                                  style={{ margin: '10 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handlePickUpServicesChange(
                                            'Inside Pickup'
                                          )
                                        }
                                        checked={insidePickupSwitch}
                                        color='primary'
                                        name='Pickup Services'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handlePickUpServicesChange(
                                        'Inside Pickup'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Inside Pickup
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handlePickUpServicesChange(
                                            'Inside Lift Gate Services'
                                          )
                                        }
                                        checked={liftgatePickupSwitch}
                                        color='primary'
                                        name='Inside Lift Gate Services'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handlePickUpServicesChange(
                                        'Inside Lift Gate Services'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Lift Gate Services
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handlePickUpServicesChange(
                                            'Residential Pickup'
                                          )
                                        }
                                        checked={residentialPickupSwitch}
                                        color='primary'
                                        name='Residential Pickup'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handlePickUpServicesChange(
                                        'Residential Pickup'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Residential Pickup
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handlePickUpServicesChange(
                                            'Secure Access'
                                          )
                                        }
                                        checked={secureAccesPickupSwitch}
                                        color='primary'
                                        name='Secure Access'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handlePickUpServicesChange(
                                        'Secure Access'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Secure Access
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handlePickUpServicesChange(
                                            'Limited Access'
                                          )
                                        }
                                        checked={limitedPickupAccessSwitch}
                                        color='primary'
                                        name='Limited Access'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handlePickUpServicesChange(
                                        'Limited Access'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Limited Access
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handlePickUpServicesChange(
                                            'Freeze Protection'
                                          )
                                        }
                                        checked={freezeProtectionPickupSwitch}
                                        color='primary'
                                        name='Freeze Protection'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handlePickUpServicesChange(
                                        'Freeze Protection'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Freeze Protection
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handlePickUpServicesChange(
                                            'Overlength'
                                          )
                                        }
                                        checked={overlengthPickupSwitch}
                                        color='primary'
                                        name='Overlength'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handlePickUpServicesChange('Overlength')
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Overlength
                                  </Typography>
                                </div>
                                {isOverLength && (
                                  <FormControl
                                    sx={{ minWidth: 120, mt: 1 }}
                                    size='large'
                                  >
                                    <TextField
                                      value={overLengthValue}
                                      onChange={e =>
                                        setOverLengthValue(e.target.value)
                                      }
                                      size='small'
                                      label='Overlength Value (Inch)'
                                      placeholder='Overlength Value (Inch)'
                                      type='number'
                                      sx={{ width: 170 }}
                                      InputLabelProps={{
                                        style: { fontSize: 14 }
                                      }}
                                      inputProps={
                                        ({ min: 0 },
                                        {
                                          style: {
                                            height: 15,
                                            fontSize: 12
                                          }
                                        })
                                      }
                                    />
                                  </FormControl>
                                )}
                              </FormControl>
                            </Col>
                          )}
                          {deliveryChecked && (
                            <Col lg={3}>
                              <FormControl
                                sx={{
                                  m: !pickUpChecked ? '0 202' : '0 0 0 0',
                                  minWidth: 230
                                }}
                                size='large'
                              >
                                <div
                                  style={{ margin: '10 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handleDeliveryServicesChange(
                                            'Inside Delivery'
                                          )
                                        }
                                        checked={insideDeliverySwitch}
                                        color='primary'
                                        name='Delivery Services'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handleDeliveryServicesChange(
                                        'Inside Delivery'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Inside Delivery
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handleDeliveryServicesChange(
                                            'Liftgate Delivery'
                                          )
                                        }
                                        checked={liftgateDeliverySwitch}
                                        color='primary'
                                        name='Liftgate Delivery'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handleDeliveryServicesChange(
                                        'Liftgate Delivery'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Lift Gate Services
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handleDeliveryServicesChange(
                                            'Residential Delivery'
                                          )
                                        }
                                        checked={residentialDeliverySwitch}
                                        color='primary'
                                        name='Residential Delivery'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handleDeliveryServicesChange(
                                        'Residential Delivery'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Residential Delivery
                                  </Typography>
                                </div>

                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handleDeliveryServicesChange(
                                            'Secure Access'
                                          )
                                        }
                                        checked={secureAccesDeliverySwitch}
                                        color='primary'
                                        name='Secure Access'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handleDeliveryServicesChange(
                                        'Secure Access'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Secure Access
                                  </Typography>
                                </div>
                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handleDeliveryServicesChange(
                                            'Limited Access'
                                          )
                                        }
                                        checked={limitedDeliverySwitch}
                                        color='primary'
                                        name='Limited Access'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handleDeliveryServicesChange(
                                        'Limited Access'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Limited Access
                                  </Typography>
                                </div>

                                <div
                                  style={{ margin: '1 90 0 0' }}
                                  className={classes.switchRoot}
                                >
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={() =>
                                          handleDeliveryServicesChange(
                                            'Call Before Delivery'
                                          )
                                        }
                                        checked={
                                          callBeforeDeliveryServicesSwitch
                                        }
                                        color='primary'
                                        name='Call Before Delivery'
                                      />
                                    }
                                  />
                                  <Typography
                                    onClick={() =>
                                      handleDeliveryServicesChange(
                                        'Call Before Delivery'
                                      )
                                    }
                                    style={{
                                      margin: '0 0 0 -10',
                                      fontSize: 12,
                                      whiteSpace: 'nowrap',
                                      fontWeight: 700
                                    }}
                                  >
                                    Call Before Delivery
                                  </Typography>
                                </div>
                              </FormControl>
                            </Col>
                          )}
                        </>
                      }
                    </Row>
                    <Row>
                      <Label
                        style={{
                          margin: '15px 0px 10px 0px',
                          fontWeight: 700,
                          fontSize: 12
                        }}
                      >
                        Additional Fee May Apply
                      </Label>

                      <Col lg={5}>
                        <FormControl sx={{ m: 0, minWidth: 200 }} size='small'>
                          <Label
                            style={{
                              margin: '10px 0px 0px 0px',
                              fontWeight: 700,
                              fontSize: 12
                            }}
                          >
                            C.O.D Amounts($)
                          </Label>
                          <TextField
                            value={CODAmount}
                            onChange={e => setCODAmount(e.target.value)}
                            type='number'
                            size='small'
                            sx={{ width: 270 }}
                            id='outlined-password-input'
                            // label='Ship From'
                            autoComplete='current-password'
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{
                              style: {
                                height: 15,
                                fontSize: 12
                              }
                            }}
                          />
                        </FormControl>
                      </Col>
                      <Col lg={5}>
                        <FormControl sx={{ m: 0, minWidth: 200 }} size='small'>
                          <Label
                            style={{
                              margin: '10px 0px 0px 0px',
                              fontWeight: 700,
                              fontSize: 12
                            }}
                          >
                            Declared Value($)
                          </Label>
                          <TextField
                            value={DeclaredValue}
                            onChange={e => setDeclaredValue(e.target.value)}
                            type='number'
                            size='small'
                            sx={{ width: 270 }}
                            id='outlined-password-input'
                            // label='Ship To'
                            autoComplete='current-password'
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            inputProps={{
                              style: {
                                height: 15,
                                fontSize: 12
                              }
                            }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    // alignContent: 'center',
                    marginTop: 20
                  }}
                >
                  <Button
                    onClick={e => handleGetRates(e)}
                    color='Blue'
                    size='small'
                  >
                    {getRateLoading ? 'Loading ...' : 'Get Rates'}
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Paper>
        </div>
      )}
    </div>
  );
}

export default GetQuotes;

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleGlobalFilterButton
} from 'material-react-table';
import { Avatar, AvatarGroup, Chip, Switch, Tooltip } from '@mui/material';
import {
  Box,
  Button,
  IconButton,
  Grid,
  TextField,
  Autocomplete,
  Paper,
  Typography,
  Checkbox
} from '@mui/material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import CloseIcon from '@mui/icons-material/Close';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { ToastContainer } from 'react-toastify';
import './CreateUser.css';
import {
  GetUsers,
  AgencyAdminGetProfile,
  GetUserRoles,
  CreateAgencyUser,
  GetAgencyLocations,
  CreateAgencyRole,
  GetRolePermissions,
  ResendPassword,
  UploadUserProfileImage
} from '../../apis/Agency';
import { successToast, ErrorToast } from '../../components/Toasts';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import UploadIcon from '@mui/icons-material/Upload';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MetaTags } from 'react-meta-tags';
import { base_logo_url, base_url } from '../../apis/constants';

function AllUsers() {
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const loggedInEmail = useSelector(state => state.userDetails.email);
  const UserIsAdmin = useSelector(state => state.userDetails.isAdmin);
  const userRolePermissions = useSelector(
    state => state.userDetails.userRolePermissionsArr
  );
  const [currentView, setCurrentView] = useState('list');
  const [phoneNumberValidError, setPhoneNumberValidError] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [createAgencyLoader, setCreateAgencyLoader] = useState(false);
  const options = ['Active', 'In-Active'];
  const [adminRoles, setAdminRoles] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userProfileImg, setUserProfileImg] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [adminActiveValue, setAdminActiveValue] = useState(options[0]);
  const [adminRoleValue, setAdminRoleValue] = useState('');
  const [adminStatus, setAdminStatus] = useState(true);
  const [adminBase64Image, setAdminBase64Image] = useState(null);
  const [adminSimpleImage, setAdminSimpleImage] = useState(null);
  const [agencyLocations, setAgencyLocations] = useState(null);
  const [userLocations, setUserLocations] = useState([]);

  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [shipmentsLoader, setShipmentsLoader] = useState(true);
  const [rolesAndPermissions, setRolesandPermissions] = useState(false);
  const [createRole, setCreateRole] = useState(false);
  const [userRoleName, setUserRoleName] = useState('');
  const [editedRolePermissionID, setEditedRolePermissionID] = useState('');
  const [selectAllSwitch, setSelectAllSwitch] = useState(false);
  const [userPermissionsArr, setUserPermissionsArr] = useState(null);
  const [disableCreateUserBtn, setDisableCreateUserBtn] = useState(false);
  const [editedData, setEditedData] = useState(null);

  function createData(permissionName, status, level1, level2, level3, level4) {
    return { permissionName, status, level1, level2, level3, level4 };
  }

  const initialRows = [
    createData('Carriers', false, false, false, false, false),
    createData('Users', false, false, false, false, false),
    createData('Shipments', false, false, false, false, false),
    createData('Locations', false, false, false, false, false),
    createData('Reports', false, false, false, false, false),
    createData('Settings', false, false, false, false, false),
    createData('App-Registration', false, false, false, false, false),
    createData('Quick Quote', false)
  ];
  useEffect(() => {
    const filteredCarrierPermissions = userRolePermissions?.filter(
      items => items.permissionName === 'Users'
    );
    setUserPermissionsArr(filteredCarrierPermissions);
  }, [userRolePermissions]);

  const [rows, setRows] = useState(initialRows);
  var allChecked = rows.every(row => row.status);

  const fetchUsers = () => {
    GetUsers(AgencyID)
      .then(response => {
        if (response.status === 200) {
          setShipmentsLoader(false);
          const filteredUsers = response?.data?.data?.filter(
            users => !users.isAdmin
          );
          setAllUsers(filteredUsers);
          // const emailFound = response.data.data.filter(
          //   items => items.loginId === loggedInEmail
          // );
          // if (emailFound) {
          //   GetAagencyUserDetails(loggedInEmail)
          //     .then(
          //       res =>
          //         res.data.statusCode === 200 && GetSuccessUserDetails(res.data)
          //     )
          //     .catch(err => console.log(err, 'err'));
          //   console.log('rrrrrrrrrrrrr', response.data.data);
          // }
        }
      })
      .catch(err => {
        setShipmentsLoader(false);
        setAllUsers([]);
        console.log('eeeeeeeee', err.message);
      });
  };
  const fetchUserRoles = () => {
    GetUserRoles(AgencyID)
      .then(response => {
        if (response.status === 200) {
          setAdminRoles(response?.data?.data);
          // setAdminRoleValue(response?.data?.data[0]);
        }
      })
      .catch(err => console.log('eeeeeeeee', err.message));
  };
  const fetchAgencyLocations = () => {
    GetAgencyLocations(AgencyID, 0)
      .then(response => {
        if (response.status === 200) {
          const filteredData = response?.data?.data.map(
            ({ locationCarriers, ...rest }) => rest
          );

          setAgencyLocations(filteredData);
        }
      })
      .catch(err => console.log('eeeeeeeee', err.message));
  };

  useEffect(() => {
    fetchUsers();
    fetchUserRoles();
    fetchAgencyLocations();
  }, []);

  const handdleExistedUserValidation = () => {
    const errors = {};
    errors['emailError'] = 'User exist with this email try another one';
    setValidationErrors(errors);
    setDisableCreateUserBtn(false);
  };

  const handleSuccessCreateAgency = (ApiRes, id) => {
    setCreateAgencyLoader(false);
    successToast(
      id ? 'User updated successfully!' : 'User created successfully!'
    );
    setCurrentView('list');
    fetchUsers();
    setAdminSimpleImage('');
    setAdminBase64Image('');
    setAdminStatus('');
    setDisableCreateUserBtn(false);
    setSelectedUser('');
    setUserProfileImg('');
  };

  const handleCreateUserReq = () => {
    setCreateAgencyLoader(true);

    let createObj = {
      id: currentView === 'edit' ? selectedUser.id : 0,
      role: adminRoleValue.id,
      firstName: firstName,
      lastName: lastName,
      loginId: email,
      password: '',
      email: email,
      notes: '',
      agencyId: AgencyID,
      phoneNumber: phoneNumber,
      status: adminStatus,
      assignedLocations: userLocations.map(obj => obj.id),
      avatar: userProfileImg ? userProfileImg : selectedUser.avatar
    };
    CreateAgencyUser(createObj)
      .then(
        res =>
          res.status === 200 && handleSuccessCreateAgency(res, selectedUser.id)
      )
      .catch(err => {
        ErrorToast(err?.message);
        console.log('eeeeeeeeeeeee', err);
        setCreateAgencyLoader(false);
        setDisableCreateUserBtn(false);
        setSelectedUser('');
        setUserProfileImg('');
      });
  };

  const ValidatUserEmail = () => {
    setDisableCreateUserBtn(true);
    AgencyAdminGetProfile(email)
      .then(res => res.status === 200 && handdleExistedUserValidation())
      .catch(err => handleCreateUserReq());
  };

  const handleCreateRoleRequest = () => {
    if (!userRoleName) {
      let errors = {};
      errors['userRoleNameError'] = 'Enter role name';
      setValidationErrors(errors);
    }
    if (!/^[a-zA-Z0-9 ]*$/.test(userRoleName)) {
      let errors = {};
      errors['userRoleNameError'] =
        'Role name should contain only alphanumeric characters';
      setValidationErrors(errors);
    } else {
      const roleObj = {
        id: editedRolePermissionID ? editedRolePermissionID : 0,
        name: userRoleName,
        status: true,
        agencyId: AgencyID,
        permissions: rows
      };

      if (userRoleName) {
        setDisableCreateUserBtn(true);
        CreateAgencyRole(roleObj)
          .then(res => {
            res.status === 200 && fetchUserRoles();
            setCurrentView('list');

            setDisableCreateUserBtn(false);

            successToast(
              editedRolePermissionID
                ? 'Role updated successfully!'
                : 'Role created successfully!'
            );
          })
          .catch(err => {
            setDisableCreateUserBtn(false);
            ErrorToast(err?.message);
          });
      }
    }
  };
  const handlePhoneNumberPaste = event => {
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the pasted text from clipboard
    const pastedText = event.clipboardData.getData('text/plain');

    // Remove non-numeric characters from pasted text
    const cleanedText = pastedText.replace(/\D/g, '');

    // Apply formatting logic
    const formattedValue = cleanedText.replace(
      /^(\d{3})(\d{3})(\d{4})$/,
      '($1) $2-$3'
    );
    setPhoneNumber(formattedValue);

    // Perform validation on the formatted value
    const errors = {};
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const isValidPhoneNumber = phoneRegex.test(formattedValue);

    if (isValidPhoneNumber) {
      setPhoneNumberValidError(false);
      setValidationErrors({ ...validationErrors, phoneNumberError: null });
    } else {
      setPhoneNumberValidError(true);
      errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
      setValidationErrors(errors);
    }
  };
  const handleSignUp = () => {
    let errors = {};
    if (!firstName) {
      errors['firstNameError'] = 'Enter First Name';
    } else if (!lastName) {
      errors['lastNameError'] = 'Enter Last Name';
    } else if (!email) {
      errors['emailError'] = 'Enter Email';
    } else if (!phoneNumber) {
      errors['phoneNumberError'] = 'Enter Phone Number';
    } else if (phoneNumberValidError) {
      errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
    } else if (!isValidEmail) {
      errors['emailError'] = 'Enter Valid Email';
    } else if (adminRoleValue.roleName === '') {
      errors['roleName'] = 'Select user role';
    } else if (userLocations.length === 0) {
      errors['locationsError'] = 'Select Atleast One Location';
    } else {
      currentView === 'edit' ? handleCreateUserReq() : ValidatUserEmail();
      // ValidatUserEmail();
    }

    // const arrayOfIds = userLocations.map(obj => obj.id);
    setValidationErrors(errors);
  };

  const ViewimageStyle = {
    margin: 10,
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333'
  };
  const handleResendEmailRequest = (event, rowData) => {
    event.stopPropagation();
    ResendPassword(rowData.loginId)
      .then(
        res => res.status === 200 && successToast('Email sent successfully!')
      )
      .catch(err => ErrorToast('Network Error'));
  };
  const userColumns = [
    {
      // accessorKey: 'name',
      accessorFn: row => `${row.firstName} ${row.lastName}`,
      id: 'name',
      header: 'User Name',
      size: 70,
      Cell: ({ cell, row }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
            <AvatarGroup spacing='small'>
              <Avatar
                sx={{
                  bgcolor: 'ButtonFace',
                  objectFit: 'contain'
                  // mr: 1
                }}
                imgProps={{
                  style: {
                    height: 45
                  }
                }}
                src={base_logo_url + row.original.avatar}
              />
            </AvatarGroup>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
          {
            <div
              className='table-row-buttons-users py-0'
              style={{ marginLeft: '-4.5vw' }}
            >
              {((userPermissionsArr &&
                userPermissionsArr.filter(permissions => permissions.level4)
                  .length) ||
                UserIsAdmin) && (
                <button
                  onClick={event =>
                    handleResendEmailRequest(event, row.original)
                  }
                  class=' bg-white border-0 p-2 fw-bold'
                  // style={{ color: '#3361FF', borderRadius: '4px' }}
                >
                  <Tooltip title='Resend Password'>
                    <svg
                      fill='rgb(51, 97, 255)'
                      width='24px'
                      height='16px'
                      viewBox='0 0 64.00 64.00'
                      data-name='Layer 1'
                      id='Layer_1'
                      xmlns='http://www.w3.org/2000/svg'
                      stroke='rgb(51, 97, 255)'
                      stroke-width='2.944'
                    >
                      <g id='SVGRepo_bgCarrier' stroke-width='0' />

                      <g
                        id='SVGRepo_tracerCarrier'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke='#CCCCCC'
                        stroke-width='0.256'
                      />

                      <g id='SVGRepo_iconCarrier'>
                        <title />

                        <path d='M55.43,55.68H8.57a5.51,5.51,0,0,1-5.5-5.5V13.82a5.51,5.51,0,0,1,5.5-5.5H55.43a5.51,5.51,0,0,1,5.5,5.5V50.18A5.51,5.51,0,0,1,55.43,55.68ZM8.57,11.32a2.5,2.5,0,0,0-2.5,2.5V50.18a2.5,2.5,0,0,0,2.5,2.5H55.43a2.5,2.5,0,0,0,2.5-2.5V13.82a2.5,2.5,0,0,0-2.5-2.5Z' />

                        <path d='M32,27.21a2,2,0,0,1-.42-.05l-17.25-5a1.5,1.5,0,0,1,.84-2.89L32,24.15l16.83-4.88a1.5,1.5,0,0,1,.84,2.89l-17.25,5A2,2,0,0,1,32,27.21Z' />

                        <path d='M39.14,49a1.49,1.49,0,0,1-1.06-.44L34.9,45.34A1.51,1.51,0,0,1,36,42.77H51.82a1.5,1.5,0,0,1,0,3H39.59l.61.62a1.51,1.51,0,0,1,0,2.12A1.53,1.53,0,0,1,39.14,49Z' />

                        <path d='M36,45.77a1.5,1.5,0,0,1-1.06-2.56L38.08,40a1.5,1.5,0,1,1,2.12,2.12L37,45.34A1.48,1.48,0,0,1,36,45.77Z' />
                      </g>
                    </svg>
                  </Tooltip>
                </button>
              )}
              {((userPermissionsArr &&
                userPermissionsArr.filter(permissions => permissions.level4)
                  .length) ||
                userPermissionsArr.filter(permissions => permissions.level2)
                  .length ||
                UserIsAdmin) &&
                row.original.roleName !== 'Default' && (
                  <button
                    onClick={
                      event =>
                        // (UserIsAdmin ||
                        //   (userPermissionsArr &&
                        //     userPermissionsArr.filter(
                        //       permissions => permissions.level4
                        //     ).length)) &&
                        handleEditUser(event, row.original)
                      // : handleViewUser(event, row.original)
                    }
                    class='mx-2 bg-white border-0 p-2 fw-bold'
                    style={{ color: '#3361FF', borderRadius: '4px' }}
                  >
                    <Tooltip title='View / Update'>
                      <svg
                        className='MX-1'
                        width='17'
                        height='17'
                        viewBox='0 0 13 13'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1.33333 11.1693H2.26667L8.01667 5.41927L7.08333 4.48594L1.33333 10.2359V11.1693ZM10.8667 4.4526L8.03333 1.6526L8.96667 0.719271C9.22222 0.463715 9.53611 0.335938 9.90833 0.335938C10.2806 0.335938 10.5944 0.463715 10.85 0.719271L11.7833 1.6526C12.0389 1.90816 12.1722 2.21649 12.1833 2.5776C12.1944 2.93872 12.0722 3.24705 11.8167 3.5026L10.8667 4.4526ZM9.9 5.43594L2.83333 12.5026H0V9.66927L7.06667 2.6026L9.9 5.43594Z'
                          fill='#3361FF'
                        />
                      </svg>
                    </Tooltip>
                  </button>
                )}
            </div>
          }
        </>
      )
    },
    {
      accessorKey: 'phone',
      id: 'phone',
      header: 'Phone Number',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'loginId',
      id: 'email',
      header: 'Email',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'roleName',
      id: 'role',
      header: 'Role',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'active',
      id: 'status',
      header: 'Status',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {cell.getValue() ? (
              <span class='mx-1 text-success'>
                ACTIVE{' '}
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.99719 4.3725L11.3747 6.75L12.4322 5.6925L8.99719 2.25L5.55469 5.6925L6.61969 6.75L8.99719 4.3725ZM8.99719 13.6275L6.61969 11.25L5.56219 12.3075L8.99719 15.75L12.4397 12.3075L11.3747 11.25L8.99719 13.6275Z'
                    fill='#2E7D32'
                  />
                </svg>
              </span>
            ) : (
              <span class='mx-1 text-muted'>
                INACTIVE{' '}
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.99719 4.3725L11.3747 6.75L12.4322 5.6925L8.99719 2.25L5.55469 5.6925L6.61969 6.75L8.99719 4.3725ZM8.99719 13.6275L6.61969 11.25L5.56219 12.3075L8.99719 15.75L12.4397 12.3075L11.3747 11.25L8.99719 13.6275Z'
                    fill='black'
                    fill-opacity='0.6'
                  />
                </svg>
              </span>
            )}
          </Box>
        </>
      )
    }
  ];

  const permissionsColumns = [
    {
      accessorKey: 'roleName',
      id: 'rolename',
      header: 'Role Name',
      size: 770,
      Cell: ({ cell, row }) => (
        <>
          <Box
            onClick={e => e.stopPropagation()}
            sx={{ display: 'flex', alignItems: 'center', height: '2vw' }}
          >
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
          <div
            className='table-row-buttons-users py-2'
            style={{ marginLeft: '-4vw' }}
          >
            {/* <button
              onClick={event =>
                handleEditRolesAndPermissions(event, row.original)
              }
              class=' bg-white border-0 p-2 fw-bold'
              style={{ color: '#3361FF', borderRadius: '4px' }}
            >
              <Tooltip title='VIEW'>
                <svg
                  class='mx-1'
                  width='20'
                  height='16'
                  viewBox='0 0 16 11'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.00521 8.30469C8.83854 8.30469 9.54688 8.01302 10.1302 7.42969C10.7135 6.84635 11.0052 6.13802 11.0052 5.30469C11.0052 4.47135 10.7135 3.76302 10.1302 3.17969C9.54688 2.59635 8.83854 2.30469 8.00521 2.30469C7.17188 2.30469 6.46354 2.59635 5.88021 3.17969C5.29688 3.76302 5.00521 4.47135 5.00521 5.30469C5.00521 6.13802 5.29688 6.84635 5.88021 7.42969C6.46354 8.01302 7.17188 8.30469 8.00521 8.30469ZM8.00521 7.10469C7.50521 7.10469 7.08021 6.92969 6.73021 6.57969C6.38021 6.22969 6.20521 5.80469 6.20521 5.30469C6.20521 4.80469 6.38021 4.37969 6.73021 4.02969C7.08021 3.67969 7.50521 3.50469 8.00521 3.50469C8.50521 3.50469 8.93021 3.67969 9.28021 4.02969C9.63021 4.37969 9.80521 4.80469 9.80521 5.30469C9.80521 5.80469 9.63021 6.22969 9.28021 6.57969C8.93021 6.92969 8.50521 7.10469 8.00521 7.10469ZM8.00521 10.3047C6.38299 10.3047 4.90521 9.85191 3.57188 8.94635C2.23854 8.0408 1.27188 6.82691 0.671875 5.30469C1.27188 3.78247 2.23854 2.56858 3.57188 1.66302C4.90521 0.757465 6.38299 0.304688 8.00521 0.304688C9.62743 0.304688 11.1052 0.757465 12.4385 1.66302C13.7719 2.56858 14.7385 3.78247 15.3385 5.30469C14.7385 6.82691 13.7719 8.0408 12.4385 8.94635C11.1052 9.85191 9.62743 10.3047 8.00521 10.3047ZM8.00521 8.97135C9.26076 8.97135 10.4135 8.6408 11.4635 7.97969C12.5135 7.31858 13.3163 6.42691 13.8719 5.30469C13.3163 4.18247 12.5135 3.2908 11.4635 2.62969C10.4135 1.96858 9.26076 1.63802 8.00521 1.63802C6.74965 1.63802 5.59688 1.96858 4.54688 2.62969C3.49688 3.2908 2.6941 4.18247 2.13854 5.30469C2.6941 6.42691 3.49688 7.31858 4.54688 7.97969C5.59688 8.6408 6.74965 8.97135 8.00521 8.97135Z'
                    fill='#3361FF'
                  />
                </svg>
              </Tooltip>
            </button> */}
            {((userPermissionsArr &&
              userPermissionsArr.filter(permissions => permissions.level4)
                .length) ||
              userPermissionsArr.filter(permissions => permissions.level2)
                .length ||
              UserIsAdmin) &&
              row.original.roleName !== 'Default' && (
                <button
                  onClick={event =>
                    handleEditRolesAndPermissions(event, row.original)
                  }
                  class='mx-2 bg-white border-0 p-2 fw-bold'
                  style={{ color: '#3361FF', borderRadius: '4px' }}
                >
                  <Tooltip title='View / Update'>
                    <svg
                      className='MX-2 '
                      width='17'
                      height='17'
                      viewBox='0 0 13 13'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1.33333 11.1693H2.26667L8.01667 5.41927L7.08333 4.48594L1.33333 10.2359V11.1693ZM10.8667 4.4526L8.03333 1.6526L8.96667 0.719271C9.22222 0.463715 9.53611 0.335938 9.90833 0.335938C10.2806 0.335938 10.5944 0.463715 10.85 0.719271L11.7833 1.6526C12.0389 1.90816 12.1722 2.21649 12.1833 2.5776C12.1944 2.93872 12.0722 3.24705 11.8167 3.5026L10.8667 4.4526ZM9.9 5.43594L2.83333 12.5026H0V9.66927L7.06667 2.6026L9.9 5.43594Z'
                        fill='#3361FF'
                      />
                    </svg>
                  </Tooltip>
                </button>
              )}
          </div>
        </>
      )
    }
  ];

  const handlePrint = () => {};

  const handleAdminImageUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    const imageType = /image.*/;
    if (!file?.type?.match(imageType)) {
      ErrorToast('Only image files are allowed');
    }
    if (file?.type?.match(imageType)) {
      reader.onloadend = () => {
        setAdminSimpleImage(reader.result);
      };

      reader.readAsDataURL(file);
      let formData = new FormData();
      formData.append('file', file);
      var formdata = new FormData();
      formdata.append('file', file);
      setDisableCreateUserBtn(true);
      UploadUserProfileImage(email ? email : loggedInEmail, formdata)
        .then(res => {
          res.status === 200 && setUserProfileImg(res.data.data);
          setDisableCreateUserBtn(false);
        })
        .catch(err => ErrorToast(err.message));
    }
  };

  const handleAdminStatusChange = (event, newValue) => {
    setAdminActiveValue(newValue);
    if (newValue === 'Active') {
      setAdminStatus(true);
    } else {
      setAdminStatus(false);
    }
  };
  const handleAdminRoleChange = (event, newValue) => {
    setAdminRoleValue(newValue);
    setValidationErrors({
      ...validationErrors,
      roleName: null
    });
  };
  const handleAdminLocationChange = (event, newValue) => {
    setUserLocations(newValue);
    setValidationErrors({
      ...validationErrors,
      locationsError: null
    });
  };
  const handlePhoneNumberChange = event => {
    const inputValue = event.target.value;
    // Remove non-numeric characters and retain formatting
    const formattedValue = inputValue
      .replace(/\D/g, '')
      .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
    setPhoneNumber(formattedValue);

    // Perform validation on the formatted value
    const errors = {};
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const isValidPhoneNumber = phoneRegex.test(formattedValue);

    if (isValidPhoneNumber) {
      setPhoneNumberValidError(false);
      setValidationErrors({ ...validationErrors, phoneNumberError: null });
    } else {
      setPhoneNumberValidError(true);
      errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
      setValidationErrors(errors);
    }
  };

  const handleEmailValidation = email => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const errors = {};
    setEmail(email);

    if (emailRegex.test(email)) {
      setIsValidEmail(true);
      setValidationErrors({
        ...validationErrors,
        emailError: null
      });
    } else {
      setIsValidEmail(false);
      errors['emailError'] = 'Enter Valid Email';
    }
    setValidationErrors(errors);
  };

  const handleAddUser = () => {
    setRolesandPermissions(false);
    setCreateRole(false);
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setAdminActiveValue(options[0]);
    setAdminStatus(true);
    setUserLocations([]);
    setAdminRoleValue({ id: '', roleName: '' });
    // setAdminRoleValue(adminRoles[0]);
    setCurrentView('create');
  };
  const handleCreateRole = () => {
    setCreateRole(true);
    setCurrentView('create');
    setRows(initialRows);
    setUserRoleName('');
    setEditedRolePermissionID('');
  };
  const handleRolePermissinosButton = () => {
    setRolesandPermissions(true);
  };

  const handleUserButton = () => {
    setRolesandPermissions(false);
    setCreateRole(false);
  };
  const handleUserLocations = userLocations => {
    // setAgencyLocations(filteredData);
    let tempArr = [];
    for (let i = 0; i < userLocations.length; i++) {
      const locationId = userLocations[i].id;
      const filterdAssignedLocation = agencyLocations.find(
        locations => locationId === locations.id
      );

      tempArr.push(filterdAssignedLocation);
    }
    setUserLocations(tempArr);
  };
  const handleEditUser = (event, rowData) => {
    setEditedData(rowData);
    setSelectedUser(rowData);
    setFirstName(rowData.firstName);
    setLastName(rowData.lastName);
    setEmail(rowData.loginId);
    rowData.avatar && setAdminBase64Image(rowData.avatar);
    setPhoneNumber(rowData.phone);
    setAdminActiveValue(rowData.active ? options[0] : options[1]);
    setAdminStatus(rowData.active ? true : false);

    setAdminRoleValue('');
    setUserLocations([]);

    AgencyAdminGetProfile(rowData.loginId)
      .then(res => {
        if (res.status === 200) {
          setAdminRoleValue(
            adminRoles.filter(
              role => role.id === res.data.data.userInfo.roleId
            )[0]
          );
          handleUserLocations(res.data.data.assignedLocations);
          // setUserLocations(
          //   res.data.data.assignedLocations.length > 0
          //     ? res.data.data.assignedLocations
          //     : []
          // );
        }
      })
      .catch(err => console.log('eeerrrooorrr', err.message));

    setCurrentView('edit');
    event.stopPropagation();
  };

  const RolesPermissionsSuccessResponse = (apiRes, rowData) => {
    let selectAllCheck = [];
    const updatedPermissions = apiRes.map(permission => {
      const allLevelsTrue =
        permission.level1 &&
        permission.level2 &&
        permission.level3 &&
        permission.level4;
      // permission.level5;
      if (allLevelsTrue) {
        selectAllCheck.push(allLevelsTrue);
        if (selectAllCheck.length === 7) {
          setSelectAllSwitch(true);
        }
      } else {
        setSelectAllSwitch(false);
      }
      return {
        ...permission,
        status: allLevelsTrue ? true : false
      };
    });

    setRows(updatedPermissions);
    for (let index = 0; index < apiRes.length; index++) {
      handleCheckboxChange(index, apiRes[index]);
    }

    setUserRoleName(rowData.roleName);
    setEditedRolePermissionID(rowData.id);
    setCreateRole(true);
    setCurrentView('create');
  };

  const handleGetUserPermissionsRequest = rowData => {
    GetRolePermissions(rowData.id)
      .then(res => {
        res.status === 200 &&
          RolesPermissionsSuccessResponse(res.data.data, rowData);
      })
      .catch(err => console.log(err, 'user permissions Err----->>>>'));
  };

  const handleEditRolesAndPermissions = (event, rowData) => {
    event.stopPropagation();
    handleGetUserPermissionsRequest(rowData);
  };

  useEffect(() => {
    // This effect will be triggered whenever `allChecked` changes
    setSelectAllSwitch(allChecked);
  }, [allChecked]);

  const handleCheckboxChange = (index, field, status) => {
    setRows(prevRows => {
      const newRows = [...prevRows];
      newRows[index][field] = status;

      if (field === 'status') {
        newRows[index]['level1'] = status;
        newRows[index]['level2'] = status;
        newRows[index]['level3'] = status;
        newRows[index]['level4'] = status;
      }

      if (field === 'level1' && !status) {
        newRows[index]['level1'] = status;
        newRows[index]['level2'] = status;
        newRows[index]['level3'] = status;
        newRows[index]['level4'] = status;
      }
      if (
        (field === 'level1' ||
          field === 'level2' ||
          field === 'level3' ||
          field === 'level4') &&
        !status
      ) {
        newRows[index]['status'] = false;
      }
      if (
        newRows[index]['level1'] &&
        newRows[index]['level2'] &&
        newRows[index]['level3'] &&
        newRows[index]['level4'] &&
        status
      ) {
        newRows[index]['status'] = true;
      }
      if (field === 'level4' && status) {
        newRows[index]['level2'] = true;
      }
      setSelectAllSwitch(allChecked);
      return newRows;
    });
  };

  const handleSwitchChange = event => {
    const status = event.target.checked;
    setSelectAllSwitch(status);
    setRows(prevRows => {
      const allChecked = !prevRows.every(row => row.status);
      return prevRows.map(row => {
        return {
          ...row,
          status: allChecked,
          level1: allChecked,
          level2: allChecked,
          level3: allChecked,
          level4: allChecked
          // level5: allChecked
        };
      });
    });
  };

  return (
    <>
      <MetaTags>
        <title>
          {' '}
          {base_url === 'https://beta.api.shiptechpro.com/'
            ? 'Users | Shiptechpro'
            : 'Users | TMS Shipping'}
        </title>
      </MetaTags>
      <ToastContainer
        style={{ width: 'auto' }}
        position='top-center'
        autoClose={3800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {currentView === 'list' ? (
        <div class='mt-2 mx-3' style={{ border: '' }}>
          {adminRoles && (
            <MaterialReactTable
              state={{ isLoading: shipmentsLoader }}
              columns={rolesAndPermissions ? permissionsColumns : userColumns}
              data={rolesAndPermissions ? adminRoles : allUsers}
              enableRowSelection
              muiTableBodyRowProps={({ row }) => ({
                onClick: row.getToggleSelectedHandler()
              })}
              // enableMultiSort

              muiTableHeadCellFilterTextFieldProps={{ placeholder: '' }}
              // enableStickyHeader
              // enableStickyFooter

              muiTableContainerProps={{
                sx: { maxHeight: '70vh', minHeight: '70vh' }
              }}
              initialState={{
                // showColumnFilters: true,
                density: 'compact'
              }}
              positionToolbarAlertBanner='bottom'
              renderToolbarInternalActions={({ table }) => (
                <>
                  <MRT_ToggleGlobalFilterButton table={table} title='Search' />

                  <IconButton
                    title='Print'
                    onClick={() => {
                      handlePrint();
                    }}
                  >
                    <LocalPrintshopOutlinedIcon />
                  </IconButton>

                  <MRT_ToggleFiltersButton table={table} title='Filters' />

                  <MRT_ToggleDensePaddingButton table={table} title='Spacing' />
                  <MRT_ShowHideColumnsButton
                    table={table}
                    title='Show/Hide Columns'
                  />
                  <MRT_FullScreenToggleButton
                    table={table}
                    title='Fullscreen'
                  />
                  <Tooltip title='Clear All Filters' arrow>
                    <IconButton
                      onClick={() => {
                        table.resetColumnFilters(true);
                        table.setShowColumnFilters(false);
                      }}
                    >
                      <FilterAltOffIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              renderTopToolbarCustomActions={({ table }) => {
                return (
                  <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <Button
                      size='small'
                      onClick={() =>
                        rolesAndPermissions
                          ? handleUserButton()
                          : handleRolePermissinosButton()
                      }
                      className='m-2'
                      style={{ color: '#3361FF', backgroundColor: '#E4F3FD' }}
                      variant='contained'
                    >
                      {rolesAndPermissions ? 'Users' : 'Roles & Permissions'}
                    </Button>
                    {((userPermissionsArr &&
                      userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length) ||
                      UserIsAdmin) && (
                      <Button
                        size='small'
                        onClick={() =>
                          rolesAndPermissions
                            ? handleCreateRole()
                            : handleAddUser()
                        }
                        className='m-2'
                        style={{ color: '#3361FF', backgroundColor: '#E4F3FD' }}
                        variant='contained'
                      >
                        {rolesAndPermissions ? 'Create Role' : 'Add User'}
                        <svg
                          className='ms-2'
                          width='11'
                          height='10'
                          viewBox='0 0 11 10'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M4.94531 9.72396V5.72396H0.945312V4.39062H4.94531V0.390625H6.27865V4.39062H10.2786V5.72396H6.27865V9.72396H4.94531Z'
                            fill='#3361FF'
                          />
                        </svg>
                      </Button>
                    )}
                  </div>
                );
              }}
            />
          )}
        </div>
      ) : currentView === 'create' || currentView === 'edit' ? (
        <Paper
          sx={{ width: '50vw', justifyContent: 'center' }}
          className='Grid_Item_User'
        >
          <Box sx={{}}>
            <Grid
              className=''
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                position: 'relative'
              }}
            >
              <IconButton
                aria-label='delete'
                style={{ position: 'absolute', top: 10 }}
                onClick={() => {
                  setCurrentView('list');
                  setSelectAllSwitch(false);
                  setAdminSimpleImage('');
                  setAdminBase64Image('');
                  setEditedData(null);
                  setValidationErrors({});
                }}
              >
                <CloseIcon color='error' />
              </IconButton>
            </Grid>

            <Grid sx={createRole ? { height: '62vh' } : { height: '70vh' }}>
              {!createRole && (
                <Grid
                  sx={{ backgroundColor: '#e6d4c2', borderRadius: 2, mt: 1 }}
                >
                  <Grid sx={{ height: 120 }}>
                    <div>
                      <input
                        disabled={
                          (userPermissionsArr &&
                            (userPermissionsArr.filter(
                              permissions => permissions.level4
                            ).length ||
                              userPermissionsArr.filter(
                                permissions => permissions.level2
                              ).length)) ||
                          UserIsAdmin
                            ? false
                            : true
                        }
                        type='file'
                        accept='image/*'
                        onChange={handleAdminImageUpload}
                        style={{ display: 'none' }}
                        id='image-upload'
                      />
                      <label htmlFor='image-upload'>
                        {adminBase64Image || adminSimpleImage ? (
                          <img
                            className='Admin_Img'
                            src={
                              adminSimpleImage
                                ? adminSimpleImage
                                : base_logo_url + adminBase64Image
                            }
                            alt='adminBase64Image'
                            width='200'
                            style={{
                              borderRadius: '50%',
                              objectFit: 'cover',
                              cursor: 'pointer'
                            }}
                          />
                        ) : (
                          <span
                            role='img'
                            aria-label='upload icon'
                            className='Admin_Img'
                            style={{
                              display: 'inline-block',
                              borderRadius: '50%',
                              backgroundColor: '#e5e5e5',
                              textAlign: 'center',
                              lineHeight: '120px',
                              cursor: 'pointer'
                            }}
                          >
                            <UploadIcon />
                            Upload
                          </span>
                        )}
                      </label>
                    </div>
                  </Grid>
                </Grid>
              )}

              {!createRole ? (
                <Grid sx={{ mt: 7 }}>
                  <Grid
                    sx={{
                      mt: 3,
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    {console.log(editedData, 'editedData')}
                    <TextField
                      disabled={
                        (userPermissionsArr &&
                          ((userPermissionsArr.filter(
                            permissions => permissions.level3
                          ).length &&
                            !editedData) ||
                            (userPermissionsArr.filter(
                              permissions => permissions.level4
                            ).length &&
                              editedData))) ||
                        UserIsAdmin
                          ? false
                          : true
                      }
                      variant='standard'
                      FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                      error={Boolean(validationErrors['firstNameError'])}
                      helperText={validationErrors['firstNameError']}
                      onChange={e => {
                        const isValid = /^[A-Za-z\s\-]+$/.test(e.target.value);
                        (isValid || e.target.value === '') &&
                          setFirstName(e.target.value);
                        setValidationErrors({
                          ...validationErrors,
                          firstNameError: null
                        });
                      }}
                      value={firstName}
                      required
                      // InputLabelProps={{ style: { fontSize: 13 } }}
                      inputProps={{ style: { fontSize: 13 } }}
                      sx={{ width: '20vw' }}
                      size='small'
                      label='First Name'
                      type='text'
                    />
                    <TextField
                      disabled={
                        (userPermissionsArr &&
                          ((userPermissionsArr.filter(
                            permissions => permissions.level3
                          ).length &&
                            !editedData) ||
                            (userPermissionsArr.filter(
                              permissions => permissions.level4
                            ).length &&
                              editedData))) ||
                        UserIsAdmin
                          ? false
                          : true
                      }
                      variant='standard'
                      FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                      error={Boolean(validationErrors['lastNameError'])}
                      helperText={validationErrors['lastNameError']}
                      onChange={e => {
                        const isValid = /^[A-Za-z\s\-]+$/.test(e.target.value);
                        (isValid || e.target.value === '') &&
                          setLastName(e.target.value);
                        setValidationErrors({
                          ...validationErrors,
                          lastNameError: null
                        });
                      }}
                      value={lastName}
                      required
                      // InputLabelProps={{ style: { fontSize: 13 } }}
                      inputProps={{ style: { fontSize: 13 } }}
                      sx={{ width: '20vw' }}
                      size='small'
                      label='Last Name'
                      type='text'
                    />
                  </Grid>
                  <Grid
                    sx={{
                      mt: 3,
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <TextField
                      variant='standard'
                      FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                      error={Boolean(validationErrors['emailError'])}
                      helperText={validationErrors['emailError']}
                      onChange={e => handleEmailValidation(e.target.value)}
                      value={email}
                      disabled={currentView === 'edit'}
                      required
                      // InputLabelProps={{ style: { fontSize: 13 } }}
                      inputProps={{ style: { fontSize: 13 } }}
                      sx={{ width: '20vw', mr: 0 }}
                      size='small'
                      label='Email Address'
                      type='email'
                    />
                    <TextField
                      disabled={
                        (userPermissionsArr &&
                          ((userPermissionsArr.filter(
                            permissions => permissions.level3
                          ).length &&
                            !editedData) ||
                            (userPermissionsArr.filter(
                              permissions => permissions.level4
                            ).length &&
                              editedData))) ||
                        UserIsAdmin
                          ? false
                          : true
                      }
                      variant='standard'
                      FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                      error={Boolean(validationErrors['phoneNumberError'])}
                      helperText={validationErrors['phoneNumberError']}
                      onChange={handlePhoneNumberChange}
                      onPaste={handlePhoneNumberPaste}
                      value={phoneNumber}
                      required
                      // InputLabelProps={{ style: { fontSize: 13 } }}
                      sx={{ width: '20vw' }}
                      size='small'
                      label='Phone Number'
                      type='text'
                      inputProps={{
                        maxLength: 10,
                        style: { fontSize: 13 } // Set the maximum length to 10 characters
                      }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      mt: 3,
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Autocomplete
                      disabled={
                        (userPermissionsArr &&
                          ((userPermissionsArr.filter(
                            permissions => permissions.level3
                          ).length &&
                            !editedData) ||
                            (userPermissionsArr.filter(
                              permissions => permissions.level4
                            ).length &&
                              editedData))) ||
                        UserIsAdmin
                          ? false
                          : true
                      }
                      sx={{ width: '20vw' }}
                      size='small'
                      disableClearable
                      value={adminRoleValue}
                      onChange={(event, newValue) =>
                        handleAdminRoleChange(event, newValue)
                      }
                      renderOption={(PreviousProps, option) => (
                        <Box style={{ fontSize: 13 }} {...PreviousProps}>
                          {option.roleName}
                        </Box>
                      )}
                      getOptionLabel={option => option.roleName}
                      options={adminRoles}
                      renderInput={params => (
                        <TextField
                          FormHelperTextProps={{
                            style: { margin: ' 5 30 0 0' }
                          }}
                          error={Boolean(validationErrors['roleName'])}
                          helperText={validationErrors['roleName']}
                          label='Role'
                          variant='standard'
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            style: { fontSize: 13 }
                          }}
                        />
                      )}
                    />

                    <Autocomplete
                      disabled={
                        (userPermissionsArr &&
                          ((userPermissionsArr.filter(
                            permissions => permissions.level3
                          ).length &&
                            !editedData) ||
                            (userPermissionsArr.filter(
                              permissions => permissions.level4
                            ).length &&
                              editedData))) ||
                        UserIsAdmin
                          ? false
                          : true
                      }
                      sx={{ width: '20vw' }}
                      size='small'
                      disableClearable
                      value={adminActiveValue}
                      onChange={(event, newValue) =>
                        handleAdminStatusChange(event, newValue)
                      }
                      renderOption={(PreviousProps, option) => (
                        <Box style={{ fontSize: 13 }} {...PreviousProps}>
                          {option}
                        </Box>
                      )}
                      options={options}
                      renderInput={params => (
                        <TextField
                          label='Status'
                          variant='standard'
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            style: { fontSize: 13 }
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      mt: 3,
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Autocomplete
                      disabled={
                        (userPermissionsArr &&
                          ((userPermissionsArr.filter(
                            permissions => permissions.level3
                          ).length &&
                            !editedData) ||
                            (userPermissionsArr.filter(
                              permissions => permissions.level4
                            ).length &&
                              editedData))) ||
                        UserIsAdmin
                          ? false
                          : true
                      }
                      multiple
                      sx={{}}
                      className='w-100'
                      size='small'
                      disableClearable
                      value={userLocations}
                      onChange={(event, newValue) =>
                        handleAdminLocationChange(event, newValue)
                      }
                      renderOption={(PreviousProps, option, { selected }) => (
                        // <Box style={{ fontSize: 13 }} {...PreviousProps}>
                        //     {`${option.city}  ${option.state}  ${option.postalCode}`}
                        // </Box>
                        <li {...PreviousProps}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                            checkedIcon={<CheckBoxIcon fontSize='small' />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />

                          {`${option?.name}: ${option?.city}  ${
                            option?.state
                              ? option?.state
                              : option?.stateOrProvinceCode
                          }  ${option?.postalCode} ${option?.countryCode}`}
                        </li>
                      )}
                      getOptionLabel={option =>
                        `${option?.name}: ${option?.city}  ${
                          option?.state
                            ? option?.state
                            : option?.stateOrProvinceCode
                        }  ${option?.postalCode} ${option?.countryCode}`
                      }
                      options={agencyLocations ? agencyLocations : []}
                      renderInput={params => (
                        <TextField
                          sx={
                            userLocations.length > 4
                              ? {
                                  height: 60,
                                  padding: 1,
                                  overflowY: 'scroll',
                                  overflowX: 'hidden'
                                }
                              : {}
                          }
                          multiline
                          required
                          label='Locations'
                          variant='standard'
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            style: { fontSize: 13 }
                          }}
                          error={Boolean(validationErrors['locationsError'])}
                          helperText={validationErrors['locationsError']}
                        />
                      )}
                    />
                    {console.log(agencyLocations, 'agencyLocations')}
                  </Grid>
                </Grid>
              ) : (
                <Grid sx={{ mt: 6 }}>
                  <TextField
                    disabled={
                      (userPermissionsArr &&
                        ((userPermissionsArr.filter(
                          permissions => permissions.level3
                        ).length &&
                          !userRoleName) ||
                          (userPermissionsArr.filter(
                            permissions => permissions.level4
                          ).length &&
                            userRoleName))) ||
                      UserIsAdmin
                        ? false
                        : true
                    }
                    FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                    error={Boolean(validationErrors['userRoleNameError'])}
                    helperText={validationErrors['userRoleNameError']}
                    fullWidth
                    // variant='standard'
                    size='small'
                    label='Role Name'
                    value={userRoleName}
                    onChange={e => {
                      setUserRoleName(e.target.value);
                      setValidationErrors({
                        ...validationErrors,
                        userRoleNameError: null
                      });
                    }}
                  />
                  <Grid
                    sx={{
                      mt: 2,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <Grid
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          mb: 1
                        }}
                      >
                        {/* <Typography variant='button'>Select All</Typography> */}
                        <Chip
                          size='small'
                          variant='outlined'
                          label={
                            <div>
                              <Typography variant='button' fontSize={12}>
                                Select All
                              </Typography>{' '}
                              <Switch
                                disabled={
                                  (userPermissionsArr &&
                                    ((userPermissionsArr.filter(
                                      permissions => permissions.level3
                                    ).length &&
                                      !userRoleName) ||
                                      (userPermissionsArr.filter(
                                        permissions => permissions.level4
                                      ).length &&
                                        userRoleName))) ||
                                  UserIsAdmin
                                    ? false
                                    : true
                                }
                                checked={selectAllSwitch}
                                size='small'
                                onChange={handleSwitchChange}
                                // onClick={e => handleToggleAll('select all')}
                              />
                            </div>
                          }
                        />
                      </Grid>

                      <TableContainer
                        sx={{
                          minWidth: '46vw',
                          height: '50vh'
                        }}
                        component={Paper}
                      >
                        <Table
                          // sx={{ minWidth: '44vw' }}
                          size='small'
                          // dense
                          aria-label='simple table'
                        >
                          {' '}
                          <TableHead>
                            <TableRow>
                              <TableCell>Module</TableCell>
                              <TableCell align='center'>All</TableCell>
                              <TableCell align='right'>Listings</TableCell>
                              <TableCell align='right'>View</TableCell>
                              <TableCell align='right'>Create</TableCell>
                              <TableCell align='right'>Update</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row, index) => (
                              <TableRow
                                key={row.permissionName}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0
                                  }
                                }}
                              >
                                <TableCell component='th' scope='row'>
                                  {row.permissionName}
                                </TableCell>
                                <TableCell align='center'>
                                  <Checkbox
                                    disabled={
                                      (userPermissionsArr &&
                                        ((userPermissionsArr.filter(
                                          permissions => permissions.level3
                                        ).length &&
                                          !userRoleName) ||
                                          (userPermissionsArr.filter(
                                            permissions => permissions.level4
                                          ).length &&
                                            userRoleName))) ||
                                      UserIsAdmin
                                        ? false
                                        : true
                                    }
                                    size='small'
                                    checked={row.status}
                                    onChange={e =>
                                      handleCheckboxChange(
                                        index,
                                        'status',
                                        e.target.checked
                                      )
                                    }
                                  />
                                </TableCell>
                                {row.permissionName !== 'Quick Quote' ? (
                                  <>
                                    <TableCell align='right'>
                                      <Checkbox
                                        disabled={
                                          (userPermissionsArr &&
                                            ((userPermissionsArr.filter(
                                              permissions => permissions.level3
                                            ).length &&
                                              !userRoleName) ||
                                              (userPermissionsArr.filter(
                                                permissions =>
                                                  permissions.level4
                                              ).length &&
                                                userRoleName))) ||
                                          UserIsAdmin
                                            ? false
                                            : true
                                        }
                                        size='small'
                                        checked={row.level1}
                                        // disabled={!row.status}
                                        onChange={e =>
                                          handleCheckboxChange(
                                            index,
                                            'level1',
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </TableCell>
                                    <TableCell align='right'>
                                      <Checkbox
                                        disabled={
                                          ((userPermissionsArr &&
                                            ((userPermissionsArr.filter(
                                              permissions => permissions.level3
                                            ).length &&
                                              !userRoleName) ||
                                              (userPermissionsArr.filter(
                                                permissions =>
                                                  permissions.level4
                                              ).length &&
                                                userRoleName))) ||
                                            UserIsAdmin) &&
                                          row.level1
                                            ? false
                                            : true
                                        }
                                        size='small'
                                        checked={row.level2}
                                        // disabled={!row.level1}
                                        onChange={e =>
                                          handleCheckboxChange(
                                            index,
                                            'level2',
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </TableCell>
                                    {row.permissionName === 'Shipments' ? (
                                      <>
                                        <TableCell align='center'></TableCell>
                                        <TableCell align='center'></TableCell>
                                      </>
                                    ) : (
                                      <>
                                        <TableCell align='right'>
                                          <Checkbox
                                            size='small'
                                            checked={row.level3}
                                            disabled={
                                              ((userPermissionsArr &&
                                                ((userPermissionsArr.filter(
                                                  permissions =>
                                                    permissions.level3
                                                ).length &&
                                                  !userRoleName) ||
                                                  (userPermissionsArr.filter(
                                                    permissions =>
                                                      permissions.level4
                                                  ).length &&
                                                    userRoleName))) ||
                                                UserIsAdmin) &&
                                              row.level1
                                                ? false
                                                : true
                                            }
                                            onChange={e =>
                                              handleCheckboxChange(
                                                index,
                                                'level3',
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </TableCell>
                                        <TableCell align='right'>
                                          <Checkbox
                                            size='small'
                                            checked={row.level4}
                                            disabled={
                                              ((userPermissionsArr &&
                                                ((userPermissionsArr.filter(
                                                  permissions =>
                                                    permissions.level3
                                                ).length &&
                                                  !userRoleName) ||
                                                  (userPermissionsArr.filter(
                                                    permissions =>
                                                      permissions.level4
                                                  ).length &&
                                                    userRoleName))) ||
                                                UserIsAdmin) &&
                                              row.level1
                                                ? false
                                                : true
                                            }
                                            onChange={e =>
                                              handleCheckboxChange(
                                                index,
                                                'level4',
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </TableCell>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <TableCell align='right'></TableCell>
                                    <TableCell align='right'></TableCell>
                                    <TableCell align='right'></TableCell>
                                    <TableCell align='right'></TableCell>
                                  </>
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <div
              style={{
                marginTop: 10,
                display: 'flex',
                flexDirection: 'row-reverse'
              }}
            >
              <Button
                disabled={
                  (userPermissionsArr &&
                    ((userPermissionsArr.filter(
                      permissions => permissions.level3
                    ).length &&
                      !editedData) ||
                      (userPermissionsArr.filter(
                        permissions => permissions.level4
                      ).length &&
                        !userRoleName)) &&
                    !disableCreateUserBtn) ||
                  (UserIsAdmin && !disableCreateUserBtn)
                    ? // &&
                      // disableCreateUserBtn
                      false
                    : true
                }
                onClick={() =>
                  createRole ? handleCreateRoleRequest() : handleSignUp()
                }
                style={{ backgroundColor: '#3361FF' }}
                className='BtnColor'
                variant='contained'
              >
                {createRole
                  ? editedRolePermissionID
                    ? 'Update'
                    : 'Create'
                  : 'SAVE'}{' '}
                {createAgencyLoader && '...'}
              </Button>
            </div>
          </Box>
        </Paper>
      ) : null}
    </>
  );
}

export default AllUsers;

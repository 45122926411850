
import { Box } from "@material-ui/core";
function Settings () {
    return(
        <Box>
            Settings
        </Box>
    )
}

export default Settings;
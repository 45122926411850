import React from 'react';

function Agencies(props) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <h1>Agencies</h1>
      </div>
    </div>
  );
}

export default Agencies;

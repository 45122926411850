import React, { Suspense, useEffect } from 'react';
import NavBar from '../CommonLayout/NavBar';
import SideBar from './SideBar';
import SignIn from '../../pages/ExtraPages/SignIn';
import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  setEmailRedux,
  setLoginAgencyID,
  setPasswordRedux,
  setUserDetailsObj,
  setUserToken
} from '../../Redux/UserSlice';
import { Grid } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setRateValues } from '../../Redux/GetRatesSlice';
import { setQuickQuoteRateValues } from '../../Redux/QuickQuoteRateSlice';
import SkeletonLoader from '../../components/TmsLoader';
import { useIsMobile } from '../../components/IsMobileHook/IsMobileHook';

const Layout = props => {
  const location = useLocation();
  const CurrentRoute = location.pathname;
  var layoutMode = document.body.getAttribute('data-layout-mode');

  const history = useHistory();
  const [state, setState] = useState('Active');
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const rememberMe = useSelector(state => state.userDetails.rememberMe);

  // const UserId = useSelector(state => state.userDetails.token);
  const UserId = localStorage.getItem('UserAuthToken');
  const tokenExpiry = localStorage.getItem('tokenExpiryTime');

  const tokenExpiryTime = new Date(tokenExpiry);
  const currentTime = new Date();

  useEffect(() => {
    // Function to get the value of a specific cookie by name
    const getCookieValue = cookieName => {
      const cookies = document.cookie.split('; ');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('=');
        if (cookie[0] === cookieName) {
          return cookie[1];
        }
      }
      return null;
    };

    // Usage: Get the value of "yourCookieName"
    const yourCookieValue = getCookieValue('userInfo');
    if (yourCookieValue === null) {
      handleResetLocalStorage();
    }
  }, []);

  setTimeout(() => {
    if (tokenExpiryTime - currentTime <= 0) {
      window.location.href = '/agency-signin';
      localStorage.clear();
      localStorage.setItem('CurrentRoute', CurrentRoute);
      !rememberMe && dispatch(setPasswordRedux(''));
      !rememberMe && dispatch(setEmailRedux(''));
      dispatch(setUserToken(''));
      dispatch(setLoginAgencyID(''));
      dispatch(setRateValues(''));
      dispatch(setQuickQuoteRateValues(''));
    }
  }, tokenExpiryTime - currentTime);

  const onIdle = () => {
    setState('Idle');
    if (UserId) {
      // alert('locked');
      history.push('/agency-locked-screen', CurrentRoute);
      // dispatch(setUserToken(''));
      // localStorage.setItem('UserAuthToken', null);
    }
  };

  const onActive = () => {
    setState('Active');
  };

  const onAction = () => {
    setCount(count + 1);
  };
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    // timeout: 10000,
    timeout: 3600000,
    throttle: 500
  });

  const { getRemainingTime2 } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    // timeout: 5000,
    timeout: 3600000,
    throttle: 500
  });
  const toggleMode = () => {
    if (layoutMode === 'dark') {
      document.body.setAttribute('data-layout-mode', 'light');
    } else {
      document.body.setAttribute('data-layout-mode', 'dark');
    }
  };
  const queryClient = new QueryClient();
  // useEffect(() => {
  //   if (!UserIsAdmin) {
  //     assignedRoutesArray.includes(CurrentRoute)
  //       ? history.push(CurrentRoute)
  //       : history.push('/dashboard');
  //   }
  // }, []);

  const handleResetLocalStorage = () => {
    !rememberMe && dispatch(setPasswordRedux(''));
    !rememberMe && dispatch(setEmailRedux(''));
    !rememberMe && dispatch(setUserDetailsObj({}));
    localStorage.clear();
    history.push('/agency-signin', 'sessionExpired');
  };

  // useEffect(() => {
  //   if (Object.keys(cookies).length === 0) {
  //     handleResetLocalStorage();
  //   }
  // }, []);
  const isMobile = useIsMobile();

  return (
    <React.Fragment>
      {/* <QueryClientProvider client={queryClient}> */}
      {/* <Suspense> */}
      <div
        style={
          layoutMode === 'dark'
            ? { backgroundColor: '#000' }
            : {
                backgroundColor: '#F5F5F5',
                overflowX: 'hidden',
                height: 'auto',
                minHeight: '100vh'
              }
        }
      >
        {UserId !== '' ? (
          <Grid
            container
            width={'100vw'}
            sx={{ maxHeight: '100vh', position: 'relative' }}
          >
            <Grid item width={'6vw'} height={'100%'} style={{}}>
              <SideBar toggleMode={toggleMode} />
            </Grid>
            <Grid item width={isMobile ? '100vw' : '94vw'}>
              <NavBar toggleMode={toggleMode} />
              {props.children}
            </Grid>
          </Grid>
        ) : (
          <SignIn />
        )}
      </div>
      {/* </Suspense> */}
      {/* </QueryClientProvider> */}
    </React.Fragment>
  );
};

export default Layout;

import { useSelector } from 'react-redux';
import { base_url } from './constants';
// const axios = require('axios');
import axios from 'axios';
// const Id = localStorage.getItem('UserId');

const GetAuthToken = () => {
  return localStorage.getItem('UserAuthToken');
};

const AgencyLoginHeader = axios.create({
  baseURL: base_url,
  responseType: 'json'
});

// Add an interceptor to update the Authorization header before each request
AgencyLoginHeader.interceptors.request.use(
  async config => {
    const authToken = GetAuthToken();
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export const AagencyLogin = async (username, pass) => {
  return await axios.post(`${base_url}Agency/SignIn`, {
    loginId: username,
    password: pass
  });
};

export const AagencyLogout = async () => {
  return await axios.post(`${base_url}Agency/SignOut`);
};

export const AagencyForgot = async email => {
  return await axios.post(`${base_url}Agency/Forgot`, {
    loginId: email
  });
};

export const GetAagencyUserDetails = async loginId => {
  try {
    const response = await AgencyLoginHeader.get(
      `/Agency/GetProfile?loginId=${loginId}`
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const UpdateAgencyProfile = async UpdatedProfileObj => {
  try {
    const response = await AgencyLoginHeader.post(
      `${base_url}Agency/UpdateProfile`,
      UpdatedProfileObj
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const GetUsers = async agencyId => {
  try {
    const response = await axios.get(
      `${base_url}Agency/GetUsers?agencyId=${agencyId}`
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const GetUserRoles = async agencyId => {
  try {
    const response = await axios.get(
      `${base_url}Agency/GetUserRoles?agencyId=${agencyId}`
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const CreateAgencyUser = async userObj => {
  try {
    const response = await axios.post(`${base_url}Agency/SignUp`, userObj);
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

// key optinal
// current password necessary
// Without Logged In
export const UpdateAgencyPassword = async (
  newPass,
  currentPass,
  UserLoggedInEmail
) => {
  try {
    const response = await axios.post(`${base_url}Agency/UpadatePassword`, {
      loginId: UserLoggedInEmail,
      passwordKey: '',
      password: newPass,
      currentPassword: currentPass
    });
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

// logged In User Pass Change
export const UpadateForgotPassword = async obj => {
  try {
    const response = await axios.post(
      `${base_url}Agency/UpadateForgotPassword`,
      obj
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

//Shipments Details Api's

export const GetShipments = async (agencyId, loggedInEmail) => {
  try {
    const response = await AgencyLoginHeader.get(
      `${base_url}Shipment/GetShipments?agencyId=${agencyId}&loginId=${loggedInEmail}`
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const ImportOrder = async (agencyId, formdata) => {
  try {
    const response = await axios.post(
      `${base_url}Shipment/ImportOrder?agencyId=${agencyId}`,
      formdata
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const GetLocations = async agencyId => {
  // return await axios.get(`${base_url}Agency/GetLocations?agencyId=${agencyId}`);
  try {
    const response = await axios.get(
      `${base_url}Agency/GetLocations?agencyId=${agencyId}`
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const AgencyAdminGetProfile = async AgencyLoginID => {
  // return await axios.get(
  //   `${base_url}Agency/GetProfile?loginId=${AgencyLoginID}`
  // );
  try {
    const response = await axios.get(
      `${base_url}Agency/GetProfile?loginId=${AgencyLoginID}`
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

// Carriers Rate Api's

// export const AllApisCall = async (finalCarrierApis, obj) => {
//   const requests = finalCarrierApis.map(url => axios.post(url, obj));
//   return await Promise.all(requests);
// };

// shipments results

export const GetBillOfLading = async obj => {
  // return await axios.post(`${base_url}Carriers/BillOfLading`, obj);
  try {
    const response = await axios.post(`${base_url}Carriers/BillOfLading`, obj);
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const GetProofOfDelivery = async obj => {
  // return await axios.post(`${base_url}Carriers/ProofOfDelivery`, obj);
  try {
    const response = await axios.post(
      `${base_url}Carriers/ProofOfDelivery`,
      obj
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

export const TrackShipmentRequest = async obj => {
  // return await axios.post(`${base_url}Carriers/TrackShipment`, obj);
  try {
    const response = await axios.post(`${base_url}Carriers/TrackShipment`, obj);
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

// carrier numbers to call for api

export const GetCarriers = async agencyId => {
  return await axios.get(
    `${base_url}Agency/GetAllCarriers?agencyId=${agencyId}`
  );
};

export const GetActiveCarriers = async (agencyId, locationId) => {
  return await axios.get(
    `${base_url}Agency/GetActiveCarriers?agencyId=${agencyId}&locationId=${locationId}`
  );
};

//pickup shipment
export const PickupShipment = async obj => {
  return await axios.post(`${base_url}Carriers/PickupShipment`, obj);
};

export const DispatchShipment = async obj => {
  return await axios.post(`${base_url}Shipment/DispatchShipment`, obj);
};

export const HoldShipment = async obj => {
  return await axios.post(`${base_url}Shipment/HoldShipment`, obj);
};

export const UnbookShipment = async obj => {
  return await axios.post(`${base_url}Shipment/UnbookShipment`, obj);
};

export const UnHoldShipment = async obj => {
  return await axios.post(`${base_url}Shipment/UnHoldShipment`, obj);
};

export const GetCarrierDetails = async (agencyId, carrierId, locationID) => {
  return await axios.get(
    `${base_url}Agency/GetCarrierDetails?agencyId=${agencyId}&carrierId=${carrierId}&locationId=${locationID}`
  );
};

export const UpdateCarrierDetails = async carriersArray => {
  return await axios.post(
    `${base_url}Agency/UpdateCarrierDetails`,
    carriersArray
  );
};

export const AddUpdateLocation = async locationObj => {
  return await axios.post(`${base_url}Agency/AddUpdateLocation`, locationObj);
};

export const AddUpdateLocationCarrier = async locationCarrierObj => {
  return await axios.post(
    `${base_url}Agency/AddUpdateLocationCarrier`,
    locationCarrierObj
  );
};

export const GetAgencyLocations = async agencyID => {
  return await axios.get(
    `${base_url}Agency/GetAgencyLocations?agencyId=${agencyID}`
  );
};

export const GetAllCarriers = async type => {
  return await axios.get(`${base_url}Admin/GetCarriers?carrierType=${type}`);
};

export const GetImportFieldNames = async UpdatedProfileObj => {
  return await axios.get(`${base_url}Shipment/GetImportFieldNames`, {
    UpdatedProfileObj
  });
};

export const ImportCSVMapping = async (
  CsvArray,
  AgencyID,
  UserLoggedInEmail
) => {
  return await axios.post(
    `${base_url}Shipment/ImportCSVMapping?agencyId=${AgencyID}&loginId=${UserLoggedInEmail}`,
    CsvArray
  );
};

export const UpdateCarrier = async obj => {
  return await axios.post(`${base_url}Agency/UpdateCarrier`, obj);
};

export const CreateOrUpdateCarrier = async obj => {
  return await axios.post(`${base_url}Agency/CreateOrUpdateCarrier`, obj);
};

export const StartTLQuote = async obj => {
  return await AgencyLoginHeader.post(`${base_url}Shipment/StartTLQuote`, obj);
};

export const GetQuote = async quoteId => {
  return await AgencyLoginHeader.get(
    `${base_url}Shipment/GetQuote?quoteId=${quoteId}`
  );
};

export const GetQuoteDetail = async sessionId => {
  return await AgencyLoginHeader.get(
    `${base_url}Shipment/GetQuoteDetail?sessionId=${sessionId}`
  );
};

export const LogTLCarrierResponse = async chatObj => {
  return await AgencyLoginHeader.post(
    `${base_url}Shipment/LogTLCarrierResponse`,
    chatObj
  );
};

export const LogTLShipperResponse = async chatObj => {
  return await AgencyLoginHeader.post(
    `${base_url}Shipment/LogTLShipperResponse`,
    chatObj
  );
};

export const BookTLShipment = async bookTLShipmentObj => {
  return await AgencyLoginHeader.post(
    `${base_url}Shipment/BookTLShipment`,
    bookTLShipmentObj
  );
};

export const ResendQuote = async sessionId => {
  try {
    const response = await AgencyLoginHeader.get(
      `${base_url}Shipment/ResendQuote?sessionId=${sessionId}`,
      sessionId
    );
    return response;
  } catch (error) {
    // Handle any errors here
    throw error;
  }
};

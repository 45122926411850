import React from 'react';
import Chart from 'react-apexcharts';

const SparkLinesChart = ({ data, options }) => {
  return (
    <Chart
      options={options}
      series={[{ data: data.map(item => item.sales) }]}
      //   type='line'
      width='120%'
      height='80%'
    />
  );
};

export default SparkLinesChart;

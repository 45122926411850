import React from 'react';
import ReactApexChart from 'react-apexcharts';

class PieChartComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [44, 55, 13, 43],
      options: {
        chart: {
          width: 380,
          type: 'pie'
        },
        labels: ['Polymer', 'PUR', 'HM', 'WB']
      }
    };
  }

  render() {
    return (
      <div id='chart'>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type='pie'
          height={'90%'}
          width={'220%'}
        />
      </div>
    );
  }
}

export default PieChartComponent;

import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Box, Typography } from '@mui/material';
import './TrackShipment.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function TrackShipmentDetails(props) {
  const trackShipmentData = props?.trackShipmentData;

  return (
    <Box>
      <Timeline sx={{ whiteSpace: 'nowrap' }}>
        {(trackShipmentData?.shipper?.contact?.companyName ||
          trackShipmentData?.shipper?.address?.streetLines ||
          trackShipmentData?.shipper?.address?.city ||
          trackShipmentData?.shipper?.address?.stateOrProvinceCode ||
          trackShipmentData?.shipper?.address?.postalCode) && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                color={
                  trackShipmentData?.shipmentStatus === 0
                    ? 'primary'
                    : trackShipmentData?.shipmentStatus === 1
                    ? 'secondary'
                    : trackShipmentData?.shipmentStatus === 2
                    ? 'info'
                    : trackShipmentData?.shipmentStatus === 3
                    ? 'warning'
                    : 'success'
                }
              />
              <TimelineConnector
                sx={{
                  backgroundColor:
                    trackShipmentData?.shipmentStatus === 0
                      ? '#2196f3'
                      : trackShipmentData?.shipmentStatus === 1
                      ? '#rgb(156, 39, 176)'
                      : trackShipmentData?.shipmentStatus === 2
                      ? 'rgb(1, 87, 155)'
                      : trackShipmentData?.shipmentStatus === 3
                      ? 'rgb(237, 108, 2)'
                      : 'rgb(46, 125, 50)',
                  width: 8,
                  borderRadius: 10
                }}
              />
            </TimelineSeparator>
            <TimelineContent>
              <div className='View_Details_Headings'>Pickup Location</div>
              <Typography sx={{ fontSize: 12, mt: 2 }}>
                {trackShipmentData?.shipper?.contact?.companyName} <br />
                {trackShipmentData?.shipper?.address?.streetLines} <br />
                {trackShipmentData?.shipper?.address?.city}
                {trackShipmentData?.shipper?.address?.stateOrProvinceCode}{' '}
                {trackShipmentData?.shipper?.address?.postalCode}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        )}
        {(trackShipmentData?.consignee?.contact?.companyName ||
          trackShipmentData?.consignee?.address?.streetLines ||
          trackShipmentData?.consignee?.address?.city ||
          trackShipmentData?.consignee?.address?.stateOrProvinceCode ||
          trackShipmentData?.consignee?.address?.postalCode) && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                color={
                  trackShipmentData?.shipmentStatus === 0
                    ? 'primary'
                    : trackShipmentData?.shipmentStatus === 1
                    ? 'secondary'
                    : trackShipmentData?.shipmentStatus === 2
                    ? 'info'
                    : trackShipmentData?.shipmentStatus === 3
                    ? 'warning'
                    : 'success'
                }
              />
              <TimelineConnector
                sx={{
                  backgroundColor:
                    trackShipmentData?.shipmentStatus === 0
                      ? '#2196f3'
                      : trackShipmentData?.shipmentStatus === 1
                      ? '#rgb(156, 39, 176)'
                      : trackShipmentData?.shipmentStatus === 2
                      ? 'rgb(1, 87, 155)'
                      : trackShipmentData?.shipmentStatus === 3
                      ? 'rgb(237, 108, 2)'
                      : 'rgb(46, 125, 50)',
                  width: 8,
                  borderRadius: 10
                }}
              />
            </TimelineSeparator>
            <TimelineContent>
              <div className='View_Details_Headings'>Delivery Location</div>
              <Typography sx={{ fontSize: 12, mt: 2 }}>
                {trackShipmentData?.consignee?.contact?.companyName} <br />
                {trackShipmentData?.consignee?.address?.streetLines} <br />
                {trackShipmentData?.consignee?.address?.city}
                {
                  trackShipmentData?.consignee?.address?.stateOrProvinceCode
                }{' '}
                {trackShipmentData?.consignee?.address?.postalCode}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        )}
        {(trackShipmentData?.billTo?.contact?.companyName ||
          trackShipmentData?.billTo?.address?.streetLines ||
          trackShipmentData?.billTo?.address?.city ||
          trackShipmentData?.billTo?.address?.stateOrProvinceCode ||
          trackShipmentData?.billTo?.address?.postalCode) && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                color={
                  trackShipmentData?.shipmentStatus === 0
                    ? 'primary'
                    : trackShipmentData?.shipmentStatus === 1
                    ? 'secondary'
                    : trackShipmentData?.shipmentStatus === 2
                    ? 'info'
                    : trackShipmentData?.shipmentStatus === 3
                    ? 'warning'
                    : 'success'
                }
              />
              <TimelineConnector
                sx={{
                  backgroundColor:
                    trackShipmentData?.shipmentStatus === 0
                      ? '#2196f3'
                      : trackShipmentData?.shipmentStatus === 1
                      ? '#rgb(156, 39, 176)'
                      : trackShipmentData?.shipmentStatus === 2
                      ? 'rgb(1, 87, 155)'
                      : trackShipmentData?.shipmentStatus === 3
                      ? 'rgb(237, 108, 2)'
                      : 'rgb(46, 125, 50)',
                  width: 8,
                  borderRadius: 10
                }}
              />
            </TimelineSeparator>
            <TimelineContent>
              <div className='View_Details_Headings'>Bill To</div>
              <Typography sx={{ fontSize: 12, mt: 2 }}>
                {trackShipmentData?.billTo?.contact?.companyName} <br />
                {trackShipmentData?.billTo?.address?.streetLines} <br />
                {trackShipmentData?.billTo?.address?.city}
                {trackShipmentData?.billTo?.address?.stateOrProvinceCode}{' '}
                {trackShipmentData?.billTo?.address?.postalCode}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        )}
        {trackShipmentData?.pickupDate && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                color={
                  trackShipmentData?.shipmentStatus === 0
                    ? 'primary'
                    : trackShipmentData?.shipmentStatus === 1
                    ? 'secondary'
                    : trackShipmentData?.shipmentStatus === 2
                    ? 'info'
                    : trackShipmentData?.shipmentStatus === 3
                    ? 'warning'
                    : 'success'
                }
              />
              <TimelineConnector
                sx={{
                  backgroundColor:
                    trackShipmentData?.shipmentStatus === 0
                      ? '#2196f3'
                      : trackShipmentData?.shipmentStatus === 1
                      ? '#rgb(156, 39, 176)'
                      : trackShipmentData?.shipmentStatus === 2
                      ? 'rgb(1, 87, 155)'
                      : trackShipmentData?.shipmentStatus === 3
                      ? 'rgb(237, 108, 2)'
                      : 'rgb(46, 125, 50)',
                  width: 8,
                  borderRadius: 10
                }}
              />
            </TimelineSeparator>
            <TimelineContent>
              <div className='View_Details_Headings'>Pickup Date</div>
              <Typography sx={{ fontSize: 12, mt: 2 }}>
                {trackShipmentData?.pickupDate}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        )}
        {trackShipmentData?.consignee?.deliveryDate && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                color={
                  trackShipmentData?.shipmentStatus === 0
                    ? 'primary'
                    : trackShipmentData?.shipmentStatus === 1
                    ? 'secondary'
                    : trackShipmentData?.shipmentStatus === 2
                    ? 'info'
                    : trackShipmentData?.shipmentStatus === 3
                    ? 'warning'
                    : 'success'
                }
              />
              <TimelineConnector
                sx={{
                  backgroundColor:
                    trackShipmentData?.shipmentStatus === 0
                      ? '#2196f3'
                      : trackShipmentData?.shipmentStatus === 1
                      ? '#rgb(156, 39, 176)'
                      : trackShipmentData?.shipmentStatus === 2
                      ? 'rgb(1, 87, 155)'
                      : trackShipmentData?.shipmentStatus === 3
                      ? 'rgb(237, 108, 2)'
                      : 'rgb(46, 125, 50)',
                  width: 8,
                  borderRadius: 10
                }}
              />
            </TimelineSeparator>
            <TimelineContent>
              <div className='View_Details_Headings'>
                Estimated Delivery Date{' '}
              </div>
              <Typography sx={{ fontSize: 12, mt: 2 }}>
                {trackShipmentData?.consignee?.deliveryDate}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        )}
        {trackShipmentData?.consignee?.contact?.phoneNumber && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                color={
                  trackShipmentData?.shipmentStatus === 0
                    ? 'primary'
                    : trackShipmentData?.shipmentStatus === 1
                    ? 'secondary'
                    : trackShipmentData?.shipmentStatus === 2
                    ? 'info'
                    : trackShipmentData?.shipmentStatus === 3
                    ? 'warning'
                    : 'success'
                }
              />
              <TimelineConnector
                sx={{
                  backgroundColor:
                    trackShipmentData?.shipmentStatus === 0
                      ? '#2196f3'
                      : trackShipmentData?.shipmentStatus === 1
                      ? '#rgb(156, 39, 176)'
                      : trackShipmentData?.shipmentStatus === 2
                      ? 'rgb(1, 87, 155)'
                      : trackShipmentData?.shipmentStatus === 3
                      ? 'rgb(237, 108, 2)'
                      : 'rgb(46, 125, 50)',
                  width: 8,
                  borderRadius: 10
                }}
              />
            </TimelineSeparator>
            <TimelineContent>
              <div className='View_Details_Headings'>Contact Phone</div>
              <Typography sx={{ fontSize: 12, mt: 2 }}>
                {trackShipmentData?.consignee?.contact?.phoneNumber}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        )}
        {trackShipmentData?.shipper?.contact?.phoneNumber && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                color={
                  trackShipmentData?.shipmentStatus === 0
                    ? 'primary'
                    : trackShipmentData?.shipmentStatus === 1
                    ? 'secondary'
                    : trackShipmentData?.shipmentStatus === 2
                    ? 'info'
                    : trackShipmentData?.shipmentStatus === 3
                    ? 'warning'
                    : 'success'
                }
              />
            </TimelineSeparator>
            <TimelineContent>
              <div className='View_Details_Headings'>Pickup Contact</div>
              <Typography sx={{ fontSize: 12, mt: 2 }}>
                {trackShipmentData?.shipper?.contact?.phoneNumber}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        )}
        <TimelineItem>
          <TimelineSeparator sx={{ marginBottom: 2, marginLeft: -1 }}>
            <TimelineDot
              color={
                trackShipmentData?.shipmentStatus === 0
                  ? 'primary'
                  : trackShipmentData?.shipmentStatus === 1
                  ? 'secondary'
                  : trackShipmentData?.shipmentStatus === 2
                  ? 'info'
                  : trackShipmentData?.shipmentStatus === 3
                  ? 'warning'
                  : 'success'
              }
            >
              <CheckCircleIcon />
            </TimelineDot>
            <TimelineConnector
              sx={{
                backgroundColor:
                  trackShipmentData?.shipmentStatus === 0
                    ? '#2196f3'
                    : trackShipmentData?.shipmentStatus === 1
                    ? '#rgb(156, 39, 176)'
                    : trackShipmentData?.shipmentStatus === 2
                    ? 'rgb(1, 87, 155)'
                    : trackShipmentData?.shipmentStatus === 3
                    ? 'rgb(237, 108, 2)'
                    : 'rgb(46, 125, 50)',
                width: 8,
                borderRadius: 10
              }}
            />
          </TimelineSeparator>
          <TimelineContent>
            <Typography sx={{ fontSize: 14, mt: 2, ml: -1 }} component=''>
              <b>
                {trackShipmentData?.shipmentStatus === 0
                  ? 'Saved'
                  : trackShipmentData?.shipmentStatus === 1
                  ? 'Ready To Ship'
                  : trackShipmentData?.shipmentStatus === 2
                  ? 'Booked'
                  : trackShipmentData?.shipmentStatus === 3
                  ? 'InTransit'
                  : 'Delivered'}
              </b>
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  );
}

import React, { useState, useRef, useEffect } from 'react';

import './KanbanCss.css';

const options = ['name', 'days', 'price'];

const KanBan = () => {
  const demoRates = [
    { id: 1, name: 'FEDEX', days: 2, price: 933.01, is_guaranteed: true },
    { id: 2, name: 'SAIA', days: 3, price: 842.77, is_guaranteed: false },
    { id: 3, name: 'SEFL', days: 10, price: 1075.36, is_guaranteed: false },
    { id: 4, name: 'FEDEX', days: 8, price: 1964.99, is_guaranteed: true },
    { id: 5, name: 'SEFL', days: 8, price: 647.51, is_guaranteed: false },
    { id: 6, name: 'SAIA', days: 5, price: 585.02, is_guaranteed: false },
    { id: 7, name: 'XPO', days: 1, price: 1148.22, is_guaranteed: true },
    { id: 8, name: 'FEDEX', days: 8, price: 1657.44, is_guaranteed: false },
    { id: 9, name: 'YRC', days: 7, price: 1815.59, is_guaranteed: true },
    { id: 10, name: 'XPO', days: 6, price: 961.29, is_guaranteed: false },
    { id: 11, name: 'FEDEX', days: 3, price: 269.4, is_guaranteed: false },
    { id: 12, name: 'SEFL', days: 10, price: 1168.98, is_guaranteed: true },
    { id: 13, name: 'SAIA', days: 5, price: 602.56, is_guaranteed: true },
    { id: 14, name: 'FEDEX', days: 4, price: 562.41, is_guaranteed: false },
    { id: 15, name: 'YRC', days: 8, price: 1043.09, is_guaranteed: false },
    { id: 16, name: 'YRC', days: 8, price: 414.06, is_guaranteed: true },
    { id: 17, name: 'SAIA', days: 10, price: 1953.59, is_guaranteed: true },
    { id: 18, name: 'YRC', days: 10, price: 330.37, is_guaranteed: true },
    { id: 19, name: 'SAIA', days: 7, price: 773.94, is_guaranteed: false },
    { id: 20, name: 'YRC', days: 1, price: 888.82, is_guaranteed: false }
  ];

  const [showSelector, setShowSelector] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const optionSelectorRef = useRef(null);
  const [sortOrders, setSortOrders] = useState({});

  const [filters, setFilters] = useState({
    name: '',
    price: '',
    days: '',
    priceOperator: '=',
    daysOperator: '='
  });

  const [selectedValues, setSelectedValues] = useState([]);
  const handleOptionSelect = event => {
    var value = event.target.value;
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter(val => val !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };
  const handleRemoveSort = value => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter(val => val !== value));
    }
  };

  const handleButtonClick = () => {
    setShowSelector(true);
  };
  const handleFilterClick = () => {
    setShowFilter(true);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        optionSelectorRef.current &&
        !optionSelectorRef.current.contains(event.target)
      ) {
        setShowSelector(false);
        setShowFilter(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSortOrderChange = (event, column) => {
    const newSortOrder = event.target.value;
    setSortOrders(prevSortOrders => ({
      ...prevSortOrders,
      [column]: newSortOrder
    }));
  };

  const compareValues = (a, b) => {
    if (a === b) {
      return 0;
    }
    return a < b ? -1 : 1;
  };

  const generateSortFunction = () => {
    if (selectedValues.length === 0) {
      return (a, b) => 0; // Fallback: identity function
    }
    // const sortingFunctions = selectedValues.map((column) => thenBy(column));
    // const combinedSortFunction = sortingFunctions.reduce((sortFn1, sortFn2) => sortFn1.thenBy(sortFn2));
    // return combinedSortFunction;

    return (a, b) => {
      for (let i = 0; i < selectedValues.length; i++) {
        const column = selectedValues[i];
        const sortOrder = sortOrders[column] || 'asc';

        const comparison = compareValues(a[column], b[column]);
        if (comparison !== 0) {
          return sortOrder === 'asc' ? comparison : -comparison;
        }
      }
      return 0;
    };
  };

  const applyFilters = item => {
    const nameFilter = filters.name.toLowerCase();
    const priceFilterValue = filters.price ? parseFloat(filters.price) : null;
    const priceFilterOperator = filters.priceOperator;
    const daysFilterValue = filters.days ? parseFloat(filters.days) : null;
    const daysFilterOperator = filters.daysOperator;

    const matchesName = item.name.toLowerCase().includes(nameFilter);

    let matchesPrice = true;
    if (priceFilterValue !== null) {
      matchesPrice =
        (priceFilterOperator === '=' && item.price === priceFilterValue) ||
        (priceFilterOperator === '<' && item.price < priceFilterValue) ||
        (priceFilterOperator === '>' && item.price > priceFilterValue);
    }

    let matchesDays = true;
    if (daysFilterValue !== null) {
      // alert(daysFilterValue);
      matchesDays =
        (daysFilterOperator === '=' && item.days == daysFilterValue) ||
        (daysFilterOperator === '<' && item.days < daysFilterValue) ||
        (daysFilterOperator === '>' && item.days > daysFilterValue);
    }

    return matchesName && matchesPrice && matchesDays;
  };

  return (
    <div className='App'>
      <div
        class='m-5'
        style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}
      >
        <div className='kanban-column mx-1'>
          <div
            className='kanban-header kanban-all'
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            ALL
            <div>
              <button
                onClick={handleButtonClick}
                style={{
                  background: '#00000000',
                  border: '0px',
                  color: 'white'
                }}
              >
                Sort
              </button>
              <button
                onClick={handleFilterClick}
                style={{
                  background: '#00000000',
                  border: '0px',
                  color: 'white'
                }}
              >
                Filter
              </button>
            </div>
            {showSelector && (
              <div
                ref={optionSelectorRef}
                className='border'
                style={{
                  position: 'absolute',
                  zIndex: 1000,
                  top: '100%',
                  padding: '5px',
                  fontWeight: 'normal',
                  fontSize: 'small',
                  textAlign: 'start',
                  left: '50%',
                  width: '300px',
                  background: 'white',
                  color: 'black'
                }}
              >
                {selectedValues.length > 0 ? (
                  selectedValues.map((value, index) => (
                    <div className='my-2' style={{ color: 'black' }}>
                      <span
                        className='mx-2 text-danger'
                        onClick={() => handleRemoveSort(value)}
                        style={{ cursor: 'pointer' }}
                      >
                        x
                      </span>
                      {index === 0 ? (
                        <span>Sort By: </span>
                      ) : (
                        <span>Then by: </span>
                      )}{' '}
                      {value + ' '}
                      <label>
                        Sort Order :
                        <select
                          value={sortOrders[value] || 'asc'}
                          onChange={event =>
                            handleSortOrderChange(event, value)
                          }
                        >
                          <option value='asc'>A to Z</option>
                          <option value='desc'>Z to A</option>
                        </select>
                      </label>
                    </div>
                  ))
                ) : (
                  <div>No Field selected</div>
                )}

                <div className='mt-3 border-top'>
                  Add sort by field:
                  <select onChange={handleOptionSelect}>
                    <option value='name'>Name</option>
                    <option value='days'>Days</option>
                    <option value='price'>Price</option>
                  </select>
                </div>
              </div>
            )}
            {showFilter && (
              <div
                ref={optionSelectorRef}
                className='border'
                style={{
                  position: 'absolute',
                  zIndex: 1000,
                  top: '100%',
                  padding: '5px',
                  fontWeight: 'normal',
                  fontSize: 'small',
                  textAlign: 'start',
                  left: '50%',
                  width: '400px',
                  background: 'white',
                  color: 'black'
                }}
              >
                <div>
                  <label className='my-1'>
                    Filter by Name:
                    <input
                      type='text'
                      value={filters.name}
                      onChange={event =>
                        setFilters({ ...filters, name: event.target.value })
                      }
                    />
                  </label>
                </div>

                <div>
                  <label className='my-1'>
                    Filter by Price:
                    <select
                      value={filters.priceOperator}
                      onChange={event =>
                        setFilters({
                          ...filters,
                          priceOperator: event.target.value
                        })
                      }
                    >
                      <option value='='>Equal to</option>
                      <option value='<'>Less than</option>
                      <option value='>'>Greater than</option>
                    </select>
                    <input
                      type='text'
                      value={filters.price}
                      onChange={event =>
                        setFilters({ ...filters, price: event.target.value })
                      }
                    />
                  </label>
                </div>

                <div>
                  <label className='my-1'>
                    Filter by Days:
                    <select
                      value={filters.daysOperator}
                      onChange={event =>
                        setFilters({
                          ...filters,
                          daysOperator: event.target.value
                        })
                      }
                    >
                      <option value='='>Equal to</option>
                      <option value='<'>Less than</option>
                      <option value='>'>Greater than</option>
                    </select>
                    <input
                      type='text'
                      value={filters.days}
                      onChange={event =>
                        setFilters({ ...filters, days: event.target.value })
                      }
                    />
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className='kanban-column-body kanban-all-light'>
            {demoRates
              .filter(applyFilters)
              .sort(generateSortFunction())
              .map(({ id, name, days, price }) => (
                <div key={id} className='kanban-card'>
                  <h4>{name}</h4>
                  <h5>$ {price}</h5>
                  <p>{days} days</p>
                </div>
              ))}
          </div>
        </div>

        <div className='kanban-column mx-1'>
          <div
            className='kanban-header kanban-standard'
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            STANDARD
            <button
              onClick={handleButtonClick}
              style={{ background: '#00000000', border: '0px', color: 'white' }}
            >
              Sort<span className='ms-3'>Filter</span>
            </button>
            {false && (
              <div
                ref={optionSelectorRef}
                style={{
                  position: 'absolute',
                  zIndex: 1000,
                  top: '100%',
                  left: '50%'
                }}
              >
                <p>Multi sort funcionality is implementing here</p>
              </div>
            )}
          </div>
          <div className='kanban-column-body kanban-standard-light'>
            {demoRates
              .filter(datum => datum.is_guaranteed == false)
              .map(({ id, name, days, price }) => (
                <div key={id} className='kanban-card'>
                  <h4>{name}</h4>
                  <h5>$ {price}</h5>
                  <p>{days} days</p>
                </div>
              ))}
          </div>
        </div>

        <div className='kanban-column'>
          <div
            className='kanban-header kanban-guaranteed'
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            GUARANTEED
            <button
              onClick={handleButtonClick}
              style={{ background: '#00000000', border: '0px', color: 'white' }}
            >
              Sort<span className='ms-3'>Filter</span>
            </button>
            {false && (
              <div
                ref={optionSelectorRef}
                style={{
                  position: 'absolute',
                  zIndex: 1000,
                  top: '100%',
                  left: '50%'
                }}
              >
                <select>
                  <option value='option1'>Option 1</option>
                  <option value='option2'>Option 2</option>
                  <option value='option3'>Option 3</option>
                </select>
              </div>
            )}
          </div>
          <div className='kanban-column-body kanban-guaranteed-light'>
            {demoRates
              .filter(datum => datum.is_guaranteed == true)
              .map(({ id, name, days, price }) => (
                <div key={id} className='kanban-card'>
                  <h4>{name}</h4>
                  <h5>$ {price}</h5>
                  <p>{days} days</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KanBan;

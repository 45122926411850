import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Autocomplete,
  Button,
  TextField,
  createFilterOptions
} from '@mui/material';
import { CarrierApiNames } from '../../Redux/CarrierApiNameSlice';
export default function DispatchShipment(props) {
  const CarrierNames = props.location.state;
  const [open, setOpen] = useState(true);
  const [scroll, setScroll] = useState('paper');
  const [value, setValue] = React.useState(null);
  const handleClickOpen = () => {
    // alert('handleClickOpen');
    setOpen(true);
    setScroll('paper');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const renderButton = () => (
    <li>
      <Button variant='contained' color='primary'>
        My Button
      </Button>
    </li>
  );
  const onAddNew = () => {};
  const filter = createFilterOptions();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>Dispatch Shipment</DialogTitle>
        <DialogContent
          style={{ width: 520, height: 350 }}
          dividers={scroll === 'paper'}
        >
          <DialogContentText
            id='scroll-dialog-description'
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Autocomplete
              size='small'
              value={value}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setValue({
                    title: newValue
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setValue({
                    title: newValue.inputValue
                  });
                } else {
                  setValue(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  option => inputValue === option.title
                );
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Add New Carrier "${inputValue}"`
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id='free-solo-with-text-demo'
              options={CarrierNames}
              getOptionLabel={option => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.title}</li>
              )}
              sx={{ width: '100%' }}
              freeSolo
              renderInput={params => (
                <TextField {...params} label='Carrier Name' />
              )}
            />
            <TextField
              size='small'
              sx={{ width: '100%', mt: 4 }}
              variant='outlined'
              label='Pickup Number'
              type='number'
            />
            <TextField
              size='small'
              sx={{ width: '100%', mt: 4 }}
              variant='outlined'
              label='Pro Number'
              type='number'
            />
            {/* <Button
              sx={{ width: '100%', mt: 11 }}
              variant='contained'
              onClick={handleClose}
            >
              Save
            </Button> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ width: '100%', mt: 0 }}
            variant='contained'
            onClick={handleClose}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Box, Typography } from '@mui/material';
import './TrackShipment.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function TrackHistory(props) {
  console.log(props, 'propsprops');
  const trackShipmentData = props?.trackShipmentData;
  const viewType = props?.view;
  //   console.log(viewType, 'viewType');
  return (
    <Timeline sx={{ marginLeft: 0 }}>
      {trackShipmentData?.tracingLines?.map(items => (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot></TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '22px', px: 2 }}>
            <Typography sx={{ fontSize: 14 }} component='span'>
              <b>{items.startDateTime}</b>
            </Typography>
            <Typography sx={{ fontSize: 12, mt: 2 }}>
              {items.description}{' '}
              <a href={items?.location}>{items?.location}</a>
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}

import { configureStore } from '@reduxjs/toolkit';
import { userSlice, userLoginDetailsSlice } from '../Redux/UserSlice';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { getCarrierRatesSlice } from '../Redux/GetRatesSlice';
import { getShipmentDetails } from '../Redux/ViewShipmentsSlice';
import { GetServicesSwitchSlice } from './GetServicesSwitchSlice';
import { quickQuoteRate, quickQuoteSelectLoc } from './QuickQuoteRateSlice';
import { quickQuoteServicesSlice } from './QuickQuoteSwevicesSlice';
import {
  // newAddedPersistedDimensions,
  newAddedPersistedRows
} from './QuickQuoteAddedRows';
import { classDimensionsBtn } from './ClassDimensionsBtnSlice';
import { newAddedPersistedDimensionsRows } from './QuickQuoteDimenionsAddedRows';
import { CarrierApiNames } from './CarrierApiNameSlice';
import { totalPersistedWeightLBS } from './QuickQuoteTotalWeightSlice';
// import { QuickQuoteServicesSwitchSlice } from './QuickQuoteServicesSwitches';

const reducers = combineReducers({
  user: userSlice.reducer,
  userDetails: userLoginDetailsSlice.reducer,
  getCarrierRates: getCarrierRatesSlice.reducer,
  getServicesSwitch: GetServicesSwitchSlice.reducer,
  getShipments: getShipmentDetails.reducer,
  quickQuoteRate: quickQuoteRate.reducer,
  quickQuoteLoc: quickQuoteSelectLoc.reducer,
  quickQuoteServicesSlice: quickQuoteServicesSlice.reducer,
  classDimensionsBtn: classDimensionsBtn.reducer,
  totalPersistedWeightLBS: totalPersistedWeightLBS.reducer,
  newAddedPersistedRows: newAddedPersistedRows.reducer,
  newAddedPersistedDimensionsRows: newAddedPersistedDimensionsRows.reducer,
  CarrierApiNames: CarrierApiNames.reducer
});

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Tab,
  Tabs,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import './ViewShipment.css';
import {
  GetBillOfLading,
  GetProofOfDelivery,
  GetQuoteDetail,
  TrackShipmentRequest
} from '../../apis/Agency';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { ErrorToast } from '../../components/Toasts';
import { Col, Row } from 'reactstrap';
import CachedIcon from '@mui/icons-material/Cached';
import TrackShipmentDetails from '../TrackShipment/TrackShipmentDetails';
import TrackHistory from '../TrackShipment/TrackHistory';
import DownloadIcon from '@mui/icons-material/Download';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { base_logo_url } from '../../apis/constants';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Barcode from 'react-barcode';
import html2pdf from 'html2pdf.js';
import NoLogo from '../../assets/carrier logos/No Logo.png';
import Checkbox from '@mui/material/Checkbox';
import './bol_table.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const CustomTable = ({ rowData }) => {
  const rows = rowData?.details.map((data, index) => (
    <tr key={index}>
      <td>{data?.pieces}</td>
      <td>{data?.pieces}</td>
      <td>{data?.packagingType}</td>
      <td>{data?.netWeight} lbs</td>
      <td>{/* {data?.} */}</td>
      <td>{data?.nmfc}</td>
      <td>{data?.itemDescription}</td>
      <td>{data?.freightClass}</td>
    </tr>
  ));
  const totalNetWeight = rowData?.details.reduce(
    (sum, item) => sum + item.netWeight,
    0
  );
  const totalPieces = rowData?.details.reduce(
    (sum, item) => sum + item.pieces,
    0
  );
  return (
    <>
      <table className='custom-table'>
        <thead style={{ backgroundColor: 'rgb(222 222 222)' }}>
          <tr>
            <th className='custom-col-100'>H/U</th>
            <th className='custom-col-100'>Pcs</th>
            <th className='custom-col-100'>Type</th>
            <th className='custom-col-100'>Weight</th>
            <th className='custom-col-100'>HM (X)</th>
            <th className='custom-col-100'>NMFC</th>
            <th className='custom-col-500'>Item Description</th>
            <th className='custom-col-100'>LTL Class</th>
          </tr>
        </thead>
        {/* <tbody>{rows}</tbody>/ */}
        <tbody>{rows}</tbody>
      </table>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          border: '0.5px solid black',
          borderTop: '0'
        }}
      >
        <div style={{ marginLeft: -105 }}>Totals:</div>
        <div>{totalPieces} H/Us</div>
        <div>{totalNetWeight} Pounds</div>

        <div>
          {totalPieces} Pieces On {totalPieces} Handling Units
        </div>
      </div>
      <div
        style={{
          border: '0.5px solid black',
          borderTop: '0'
        }}
      >
        <div style={{ marginLeft: 5, marginRight: 5, fontSize: 16 }}>
          Where the rate is dependent on value, shippers are required to state
          specifically in writing the agreed or declared value of the property
          as follows: “The agreed or declared value of the property is
          specifically stated by the shipper to be not exceeding ______________
          per ______________.
        </div>
      </div>
      <div
        style={{
          border: '0.5px solid black',
          borderTop: '0',
          fontSize: 16,
          fontWeight: 'bold'
        }}
      >
        <div style={{ marginLeft: 5, marginRight: 5 }}>Remit COD to:</div>
      </div>
      <div
        style={{
          border: '0.5px solid black',
          borderTop: '0',
          fontSize: 16,
          fontWeight: 'bold'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: 5,
            marginRight: 5
          }}
        >
          <div>
            Collect <Checkbox {...label} />
          </div>
          <div>
            Prepaid <Checkbox {...label} />
          </div>
          <div>
            Customer check acceptable <Checkbox {...label} />
          </div>
          <div>
            COD Amount: $ <u>{rowData?.shipmentCharges}</u>{' '}
            <Checkbox {...label} />
          </div>
        </div>
      </div>
      <div
        style={{
          border: '0.5px solid black',
          borderTop: '0',
          fontSize: 16
          // fontWeight: 'bold'
        }}
      >
        <div style={{ marginLeft: 5, marginRight: 5 }}>
          NOTE: Liability Limitation for loss or damage in this shipment may be
          applicable. See 49 U.S.C. 14706(c)(1)(A) and (B).
        </div>
      </div>
      <div
        style={{
          border: '0.5px solid black',
          borderTop: '0'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: 5,
            marginRight: 5
          }}
        >
          <div style={{ borderRight: '0.5px solid black', fontSize: 15 }}>
            Received, subject to individually determined rates or contracts that
            have been agreed upon in writing between the carrier and shipper, if
            applicable, otherwise to the rates, classifications and rules that
            have been established by the carrier and are available to the
            shipper, on request, and to all applicable state and federal
            regulations.
          </div>
          <div
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              borderRight: '0.5px solid black',
              width: 400,
              padding: 10
            }}
          >
            Trailer Loaded:
          </div>
          <div
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              borderRight: '0.5px solid black',
              width: 400,
              padding: 10
            }}
          >
            Freight Counted:
          </div>
          <div style={{ fontSize: 15, fontWeight: 'bold', marginLeft: 5 }}>
            The carrier shall not make delivery of this shipment without payment
            of freight and all other lawful charges.
            <br />
            Shipper: _____________
          </div>
        </div>
      </div>
      <div
        style={{
          border: '0.5px solid black',
          borderTop: '0'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: 5,
            marginRight: 5
          }}
        >
          <div style={{ borderRight: '0.5px solid black', fontSize: 15 }}>
            <b>Shipper Signature/Date:</b>
            <br />
            This is to certify that the here-in named materials are properly
            classified, described, packaged, marked and labeled, and are in
            proper condition for transportation according to the applicable
            regulations of the Department of Transportation.
            <br />
            <b style={{ fontSize: 18 }}>
              Shipper:____________________________________
            </b>
          </div>

          <div
            style={{
              fontSize: 15,
              margin: 5
            }}
          >
            <b>Carrier Signature/Pickup Date:</b>
            <br />
            Carrier acknowledges receipt of packages and required placards.
            Carrier certifies emergency response information was made available
            and/or carrier has the Department of Transportation emergency
            response guidebook.
            <br />
            <b style={{ fontSize: 18 }}>
              Carrier:____________________________________
            </b>
          </div>
        </div>
      </div>
    </>
  );
};
// VERTICAL

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 1, width: '115%' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

// HORIZONTAL

function TabPanelX(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 3, py: 1, maxWidth: '100%' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelX.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function ViewShipment(props) {
  const handleDateTimeShow = (timeStamp, type) => {
    const dateTimeString = timeStamp + 'Z';
    const dateTime = new Date(dateTimeString);
    const date = new Date(dateTimeString);
    const bolDate = new Date(timeStamp);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const timeOptions = new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: userTimeZone
    }).format(dateTime);

    const dateOptions = {
      // weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    };

    const bolDateOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    };

    const formattedDate = date.toLocaleDateString(undefined, dateOptions);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const formattedBolDate = bolDate.toLocaleDateString('en-US', options);
    if (type === 'time') {
      return timeOptions;
    }
    if (type === 'date') {
      return formattedDate;
    }
    if (type === 'bol') {
      return formattedBolDate;
    }
  };
  const shipmentStatus = props?.rowData?.shipmentStatus;
  const [trackShipmentData, setTrackShipmentData] = useState();

  const handleSuccessTrackShip = apiRes => {
    setLoader(false);
    setTrackShipmentData(apiRes);
    // history.push({
    //   pathname: '/track-shipment',
    //   state: { trackShipmentData: apiRes }
    // });
  };
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const [blobUrlBOL, setBlobUrlBOL] = useState('');
  const [blobUrlPOD, setBlobUrlPOD] = useState('');
  const [loader, setLoader] = useState(false);
  const [docLoader, setDocLoader] = useState(false);
  const [historyError, setHistoryError] = useState(false);
  const [PodDocLoader, setPodDocLoader] = useState(false);
  const [viewType, setViewType] = useState('Track');
  const handleTrackShipmentRequest = (selectedRowData, event, type) => {
    if (type === 'history') {
      setViewType(type);
      event.stopPropagation();
    } else {
      setLoader(true);
      event.stopPropagation();
      console.log(selectedRowData, 'selectedRowData');
      const obj = {
        carrierInfo: {
          id: selectedRowData?.carrierId ? selectedRowData.carrierId : 0,
          name: selectedRowData?.carrierName ? selectedRowData.carrierName : '',
          logo: ''
        },
        proNumber: selectedRowData?.proNumber,
        shipmentId: selectedRowData?.shipmentId,
        locationId: selectedRowData?.locationId,
        shipmentType: selectedRowData?.shipmentType,
        agencyId: AgencyID ? AgencyID : 0
      };

      TrackShipmentRequest(obj)
        .then(res => res.status === 200 && handleSuccessTrackShip(res.data))
        .catch(err => {
          setLoader(false);
          ErrorToast(JSON.stringify(err?.response?.data));
        });
    }
  };

  const viewBOL = (fileType, base64Data, urls, fileName) => {
    // console.log(urls[0], 'BOL');
    let mimeType;
    if (base64Data !== null) {
      switch (fileType.toLowerCase()) {
        case 'pdf':
          mimeType = 'application/pdf';
          break;
        case 'tif':
        case 'tiff':
        case 'tifv':
          mimeType = 'image/tiff';
          break;
        case '.gif':
        case 'gif':
          mimeType = 'image/gif';
          break;
        case 'jpg':
        case '.jpg':
          mimeType = 'image/jpg';
          break;
        default:
          console.error('Unsupported file type');
          return;
      }

      const linkSource = `data:${mimeType};base64,${base64Data}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;

      // Set the download attribute with the correct file extension
      downloadLink.download = fileName.endsWith(`.${fileType}`)
        ? fileName
        : `${fileName}.${fileType}`;

      // Trigger the download
      downloadLink.click();
    }
    if (urls !== null) {
      window.open(urls[0]);
    }

    // if (pdf.includes('tif') || pdf.includes('TIF')) {
    //   var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    //   if (base64regex.test(base64Data)) {
    //     setBlobUrlBOL(null);
    //     const linkSource = `data:image/tiff;base64,${base64Data}`;
    //     const downloadLink = document.createElement('a');
    //     downloadLink.href = linkSource;
    //     downloadLink.download = fileName;
    //     downloadLink.click();
    //   }
    // }
    // if ((pdf.includes('pdf') || pdf.includes('PDF')) && base64Data) {
    //   const byteArray = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
    //   const blob = new Blob([byteArray], { type: 'application/pdf' }); // Change the MIME type according to your file type
    //   const url = URL.createObjectURL(blob);
    //   setBlobUrlBOL(url);
    // }
  };
  const viewPOD = (fileType, base64Data, urls, fileName) => {
    let mimeType;
    if (base64Data !== null) {
      switch (fileType.toLowerCase()) {
        case 'pdf':
          mimeType = 'application/pdf';
          break;
        case 'tif':
        case 'tiff':
        case 'tifv':
          mimeType = 'image/tiff';
          break;
        case '.gif':
        case 'gif':
          mimeType = 'image/gif';
          break;
        case 'jpg':
        case '.jpg':
          mimeType = 'image/jpg';
          break;
        default:
          console.error('Unsupported file type');
          return;
      }

      const linkSource = `data:${mimeType};base64,${base64Data}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;

      // Set the download attribute with the correct file extension
      downloadLink.download = fileName.endsWith(`.${fileType}`)
        ? fileName
        : `${fileName}.${fileType}`;

      // Trigger the download
      downloadLink.click();
    }
    if (urls !== null) {
      window.open(urls[0]);
    }
    // if (pdf.includes('tif') || pdf.includes('TIF')) {
    //   var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    //   if (base64regex.test(base64Data)) {
    //     setBlobUrlPOD(null);
    //     const linkSource = `data:image/tiff;base64,${base64Data}`;
    //     const downloadLink = document.createElement('a');
    //     downloadLink.href = linkSource;
    //     downloadLink.download = fileName;
    //     downloadLink.click();
    //   }
    // }
    // if ((pdf.includes('pdf') || pdf.includes('PDF')) && base64Data) {
    //   const byteArray = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
    //   const blob = new Blob([byteArray], { type: 'application/pdf' }); // Change the MIME type according to your file type
    //   const url = URL.createObjectURL(blob);
    //   setBlobUrlPOD(url);
    // }
  };

  function handleSuccessBOL(pdf, base64Data, fileName) {
    if (pdf.includes('pdf') || pdf.includes('PDF')) {
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const fileBlob = new Blob([byteArray], { type: 'application/pdf' });
      saveAs(fileBlob, fileName);
    }
    if (pdf.includes('tiff') || pdf.includes('TIFF')) {
      var base64regex =
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      if (base64regex.test(base64Data)) {
        const linkSource = `data:${pdf};base64,${base64Data}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    }
  }
  const handleProofOfDeliveryRequest = status => {
    setPodDocLoader(true);
    const obj = {
      carrier: {
        id: rowData?.carrierId ? rowData.carrierId : 0,
        name: rowData?.carrierName ? rowData.carrierName : 'string',
        logo: 'string'
      },
      proNumber: rowData?.proNumber.trim(),
      shipmentId: rowData?.shipmentId,
      locationId: rowData?.locationId,
      agencyId: AgencyID ? AgencyID : 0
    };
    GetProofOfDelivery(obj)
      .then(res => {
        res.status === 200 && setPodDocLoader(false);
        viewPOD(
          res.data.fileExtension,
          res.data.fileBase64,
          res.data.urLs,
          `Proof of Delivery (${obj.carrier.name}  ${obj.proNumber})`
        );
      })
      .catch(err => {
        setPodDocLoader(false);
        setBlobUrlPOD(null);
        ErrorToast(JSON.stringify(err?.response?.data));
      });
  };

  const handleBillOfLadingRequest = status => {
    setDocLoader(true);
    const obj = {
      carrier: {
        id: rowData?.carrierId ? rowData.carrierId : 0,
        name: rowData?.carrierName ? rowData.carrierName : 'string',
        logo: 'string'
      },
      proNumber: rowData?.proNumber.trim(),
      shipmentId: rowData?.shipmentId,
      locationId: rowData?.locationId,
      agencyId: AgencyID ? AgencyID : 0
    };

    GetBillOfLading(obj)
      .then(res => {
        res.status === 200 && setDocLoader(false);
        viewBOL(
          res.data.fileExtension,
          res.data.fileBase64,
          res.data.urLs,
          `Bill of Lading (${obj.carrier.name}  ${obj.proNumber})`
        );
      })
      .catch(err => {
        setBlobUrlBOL(null);
        setDocLoader(false);
        ErrorToast(JSON.stringify(err?.response?.data));
      });
  };

  const rowData = props?.rowData;

  // Vertical
  const [valueY, setValueY] = useState(0);
  const [shipperApiMsgs, setShipperApiMsgs] = useState('');
  const [carrierApiMsgs, setCarrierApiMsgs] = useState('');
  const [corresHistory, setCorresHistory] = useState('');
  const [openPrintPDfDialog, setOpenPrintPDfDialog] = useState(false);
  const [openPDfPackagingSlip, setOpenPDfPackagingSlip] = useState(false);

  const handleSuccessResponse = apiRes => {
    setHistoryError(false);
    const carrierData = apiRes.data.data.details.filter(
      items => items.actionByFlag.toLowerCase() === 'carrier'
    );

    setShipperApiMsgs(apiRes.data.data.details);
    setCarrierApiMsgs(carrierData);
    setDocLoader(false);
  };

  const handleGetCorrHistory = apiRes => {
    const shipperSessionId = apiRes?.quoteCarriers?.find(
      ratesArr => ratesArr.carrierResponseStatus === 'ACCEPTED'
    );
    if (rowData?.sessionId) {
      GetQuoteDetail(rowData?.sessionId)
        .then(res => res.status === 200 && handleSuccessResponse(res))
        .catch(err => {
          setDocLoader(false);
          ErrorToast(err?.message);
        });
    } else {
      // ErrorToast('No history found against this shipment!');
      setHistoryError(true);
      setDocLoader(false);
    }
  };

  const handleGetQuote = () => {
    handleGetCorrHistory();
    // GetQuote(rowData?.quoteId)
    //   .then(res => {
    //     res.status === 200 && handleGetCorrHistory(res.data.data);
    //     setDocLoader(false);
    //   })
    //   .catch(err => console.log(err, 'TL quote Err----->>>>'));
  };
  const handleChangeY = (event, newValue) => {
    setValueY(newValue);
    if (newValue === 3) {
      setDocLoader(true);
      handleGetQuote();
    }
  };

  // Horizontal
  const [valueX, setValueX] = useState(0);

  const handleChangeX = (event, newValue) => {
    setValueX(newValue);
  };

  const handleChangeIndex = index => {
    setValueX(index);
  };
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const handleFullScreenChange = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };

  const removeTableCss = finalRes => {
    const regexPattern = /<style[^>]*>([\s\S]*?)<\/style>/gi;

    // Remove table styles including borders
    const updatedHtml = finalRes.replace(regexPattern, (match, styles) => {
      const stylesWithoutTable = styles.replace(
        /(?:table|td|th)\s*\{[^}]*\}/gi,
        ''
      );
      return `<style>${stylesWithoutTable} table { font-size: 12px; } td { padding: 5px; }</style>`;
    });
    return updatedHtml;
  };

  const handleCorresHistory = apiRes => {
    // Regex pattern to capture and replace font-size in table styles
    const fontSizeToUpdate = removeTableCss(apiRes);

    return fontSizeToUpdate;

    // removeTableCss(updatedHtml);
  };

  const handlePDFPrintClose = () => {
    setOpenPrintPDfDialog(false);
    // setNumberOfPrintsColmnVise(1);
  };
  const generatePDF = async () => {
    const element = document.getElementById('pdf-content');

    const pageSize = {
      width: 900,
      height: 1150
    };

    const pdfOptions = {
      // margin: 1,
      filename: 'TMS Bill Of Lading.pdf',
      // filename: 'label.pdf',
      // image: { type: 'jpeg', quality: 1.0 },
      html2canvas: {
        scale: 4, // Adjust scale as needed
        logging: true // Enable logging if needed
      },
      jsPDF: {
        unit: 'pt',
        format: [pageSize.width, pageSize.height],
        // format: 'a4',
        orientation: 'portrait'
      }
    };

    await html2pdf(element, pdfOptions);
  };
  const generatePackagingSlipPdf = async () => {
    const element = document.getElementById('pdf-content');

    const pageSize = {
      width: 800,
      height: 1050
    };

    const pdfOptions = {
      // margin: 1,
      filename: 'TMS Packaging Slip.pdf',
      // filename: 'label.pdf',
      // image: { type: 'jpeg', quality: 1.0 },
      html2canvas: {
        scale: 6, // Adjust scale as needed
        logging: true // Enable logging if needed
      },
      jsPDF: {
        unit: 'pt',
        format: [pageSize.width, pageSize.height],
        // format: 'a4',
        orientation: 'portrait'
      }
    };

    await html2pdf(element, pdfOptions);
  };
  const formatTimeTo12Hour = time24 => {
    if (time24) {
      const [hour, minute] = time24?.split(':');
      const hourInt = parseInt(hour, 10);
      const ampm = hourInt >= 12 ? 'PM' : 'AM';
      const hour12 = hourInt % 12 || 12;
      return `${hour12}:${minute} ${ampm}`;
    } else return null;
  };

  const handlePackagingSlip = () => {
    setOpenPDfPackagingSlip(true);
  };

  const handlePdfPackagingClose = () => {
    setOpenPDfPackagingSlip(false);
  };
  return (
    <div className='d-flex ' style={{ justifyContent: 'center' }}>
      {/* <ToastContainer
        position='top-center'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Box
        sx={{
          // bgcolor: 'background.paper',
          // backgroundColor: 'red',
          display: 'flex',
          width: '100%',
          borderRadius: '5px',
          // marginTop: -20
          p: 3,
          height: 'auto'
        }}
      >
        {/* BOL Dialog Box */}
        <Dialog
          fullWidth
          maxWidth='md'
          minWidth='xs'
          sx={{ '& .MuiDialog-paper': { minWidth: '1050px' } }}
          open={openPrintPDfDialog}
          onClose={handlePDFPrintClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>BILL OF LADING </div>
              <div>
                <CloseIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={handlePDFPrintClose}
                />
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <div
                id='pdf-content'
                style={{
                  minWidth: '100%',
                  minHeight: '100%',
                  padding: 10
                }}
              >
                {/* 1st page  */}
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    border: '0.5px solid black',
                    marginTop: 10,
                    borderLeft: '0'
                  }}
                >
                  <div
                    style={{
                      border: '0.5px solid black',
                      flex: '0 0 60%'
                    }}
                  >
                    <div
                      style={{
                        border: '0.5px solid black',
                        borderTop: '0',
                        textAlign: 'center',
                        fontSize: 20,
                        fontWeight: 'bold'
                      }}
                    >
                      BILL OF LADING - {rowData?.bolNumber}
                    </div>
                    <div
                      style={{
                        margin: 5,
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div style={{ fontSize: 18 }}>
                        <div>
                          <span style={{ fontWeight: 'bold' }}>Carrier</span>:
                          {rowData?.carrierName}
                        </div>
                        <div>
                          {' '}
                          <span style={{ fontWeight: 'bold' }}>PRO</span>:
                          {rowData?.proNumber}
                        </div>
                      </div>
                      <div style={{ fontSize: 18 }}>
                        <span style={{ fontWeight: 'bold' }}>Pickup Date</span>:
                        {handleDateTimeShow(rowData.actualPickupDate, 'bol')}
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          border: '0.5px solid black',
                          textAlign: 'center',
                          fontSize: 18,
                          fontWeight: 'bold',
                          backgroundColor: 'rgb(222 222 222)'
                        }}
                      >
                        Origin
                      </div>
                      <div style={{ margin: 5, fontSize: 18 }}>
                        <div>{rowData?.originCompany}</div>
                        <div>{rowData?.originAddress}</div>
                        <div>
                          {rowData?.originCity}, {rowData?.originState},
                          {rowData?.originZipCode}
                        </div>
                        <div style={{ fontSize: 15 }}>
                          Shipping Department, {rowData?.originContactPhone},{' '}
                          {rowData?.originContactEmail}
                        </div>
                        <div style={{ fontSize: 15 }}>
                          Pickup Hours: {rowData?.pickupStartTime} -{' '}
                          {rowData?.pickupEndTime}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        border: '0.5px solid black',
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 'bold',
                        backgroundColor: 'rgb(222 222 222)'
                      }}
                    >
                      Destination
                    </div>
                    <div style={{ margin: 5, fontSize: 18 }}>
                      <div>{rowData?.destinationCompany}</div>
                      <div>{rowData?.destinationAddress}</div>
                      <div>
                        {rowData?.destinationCity}, {rowData?.destinationState},
                        {rowData?.destinationZipCode}
                      </div>
                      <div style={{ fontSize: 15 }}>
                        Shipping Department, {rowData?.destinationContactPhone},{' '}
                        {rowData?.destinationContactEmail}
                      </div>

                      <div style={{ fontSize: 15 }}>
                        Hours: {rowData?.deliverStartTime} -{' '}
                        {rowData?.deliverEndTime}
                      </div>
                    </div>
                    <div
                      style={{
                        border: '0.5px solid black',
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 'bold',
                        backgroundColor: 'rgb(222 222 222)'
                      }}
                    >
                      3rd Party Freight Charges Bill To
                    </div>
                    <div style={{ margin: 5 }}>
                      <div>{rowData?.billToCompany}</div>
                      <div>Bill Account #: </div>
                      <div>
                        {rowData?.billToZipCode +
                          ' ' +
                          rowData?.billToCity +
                          ' ' +
                          rowData?.billToState}
                      </div>
                      <div>{rowData?.billToAddress}</div>
                      <div>
                        {rowData?.billToCompany}, {rowData?.billToContactPhone},{' '}
                        {rowData?.billToContactEmail}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      // border: '0.5px solid black',
                      flex: '0 0 40%',
                      textAlign: 'center'
                    }}
                  >
                    <div
                      style={{
                        // border: '0.5px solid black',
                        flex: '0 0 40%',
                        textAlign: 'center'
                      }}
                    >
                      <Barcode
                        displayValue={true}
                        height={105}
                        fontSize={15}
                        width={1.5}
                        value={rowData?.proNumber}
                      />
                    </div>
                    <div>
                      <div
                        style={{
                          border: '0.5px solid black',
                          textAlign: 'center',
                          fontSize: 18,
                          fontWeight: 'bold',
                          backgroundColor: 'rgb(222 222 222)'
                        }}
                      >
                        Origin Terminal
                      </div>
                      <span style={{ fontWeight: 'bold', fontSize: 18 }}>
                        {rowData?.originContactPerson}
                      </span>
                      ,{rowData?.originContactPhone}
                    </div>
                    <div>
                      <div
                        style={{
                          border: '0.5px solid black',
                          textAlign: 'center',
                          marginTop: '16%',
                          fontSize: 18,
                          fontWeight: 'bold',
                          backgroundColor: 'rgb(222 222 222)'
                        }}
                      >
                        Destination Terminal
                      </div>
                      <div>
                        <span style={{ fontWeight: 'bold', fontSize: 18 }}>
                          {rowData?.originContactPerson}
                        </span>
                        ,{rowData?.originContactPhone}
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          border: '0.5px solid black',
                          textAlign: 'center',
                          marginTop: 20,
                          fontSize: 18,
                          fontWeight: 'bold',
                          backgroundColor: 'rgb(222 222 222)'
                        }}
                      >
                        References
                      </div>
                      <div style={{ alignContent: 'flex-start' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            fontSize: 12,
                            // textAlign: 'left',
                            marginTop: 10
                          }}
                        >
                          <div>BOL: {rowData?.bolNumber}</div>
                          <div>Order Number: {rowData?.soNumber}</div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            fontSize: 12,
                            marginTop: 50
                            // textAlign: 'left'
                          }}
                        >
                          <div>PRO: {rowData?.proNumber}</div>
                          <div>Shipment Number: {rowData?.pickupNumber}</div>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            // justifyContent: 'space-around',
                            fontSize: 17,
                            marginTop: 70,
                            marginLeft: 10,
                            textAlign: 'left'
                          }}
                        >
                          <div>Service Level: {rowData?.serviceType}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    border: '0.5px solid black',
                    borderTop: '0',
                    borderBottom: '0',
                    borderLeft: '0'
                  }}
                >
                  <div
                    style={{
                      border: '0.5px solid black',
                      flex: '0 0 80%',
                      borderTop: '0',
                      borderBottom: '0'
                    }}
                  >
                    <div style={{ fontWeight: 'bold', margin: 5 }}>
                      Special Instructions: <br />
                      {rowData?.shippingNotes}
                    </div>

                    <div
                      style={{
                        marginTop: 65,
                        borderTop: '0.5px solid black'
                      }}
                    >
                      <div style={{ fontWeight: 'bold', margin: 5 }}>
                        Accessorials:
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      flex: '0 0 20%'
                    }}
                  >
                    <div style={{ margin: 5 }}>
                      <div
                        style={{
                          fontWeight: 'bold',
                          marginBottom: 10,
                          marginLeft: 20
                        }}
                      >
                        Freight Terms:
                      </div>
                      <div style={{ textAlign: 'right', marginRight: 25 }}>
                        <div>
                          Prepaid:{' '}
                          <Checkbox
                            {...label}
                            // checked={
                            //   rowData?.freightTerms.toLowerCase() === 'p'
                            // }
                          />
                        </div>
                        <div style={{ marginTop: -15 }}>
                          Collect:{' '}
                          <Checkbox
                            {...label}
                            // checked={
                            //   rowData?.freightTerms.toLowerCase() === 'c'
                            // }
                          />
                        </div>
                        <div style={{ marginTop: -15 }}>
                          3rd Party: <Checkbox {...label} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <CustomTable rowData={rowData} />
                <div className='page-break'></div>
                {/* 2nd page  */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 100,
                    fontSize: 20
                  }}
                >
                  <div
                    style={{
                      // padding: 10,
                      border: '3px solid black',
                      width: 400
                    }}
                  >
                    <div
                      style={{ borderBottom: '2px solid black', padding: 20 }}
                    >
                      <b>From:</b>
                      <br />
                      <div>{rowData?.originCompany}</div>
                      <div>{rowData?.originAddress}</div>
                      <div>
                        {rowData?.originCity}, {rowData?.originState},
                        {rowData?.originZipCode}
                      </div>
                    </div>
                    <div
                      style={{ borderBottom: '2px solid black', padding: 20 }}
                    >
                      <b>To:</b>
                      <br />
                      <div>{rowData?.destinationCompany}</div>
                      <div>{rowData?.destinationAddress}</div>
                      <div>
                        {rowData?.destinationCity}, {rowData?.destinationState},
                        {rowData?.destinationZipCode}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        borderBottom: '2px solid black',
                        padding: 20
                      }}
                    >
                      <Barcode
                        displayValue={true}
                        height={105}
                        fontSize={20}
                        value={rowData?.proNumber}
                      />
                    </div>
                    <div
                      style={{
                        fontSize: 18,
                        borderBottom: '2px solid black',
                        padding: 20
                      }}
                    >
                      <span style={{ fontWeight: 'bold' }}>Pickup Date</span>:
                      {handleDateTimeShow(rowData.actualPickupDate, 'bol')}{' '}
                      {rowData?.pickupStartTime} - {rowData?.pickupEndTime}
                    </div>
                    <div
                      style={{
                        fontSize: 20,
                        borderBottom: '2px solid black',
                        padding: 20,
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      BOL: {rowData?.bolNumber} <br />
                      PRO: {rowData?.proNumber}
                    </div>
                    <div
                      style={{
                        fontSize: 25,
                        fontWeight: 'bold',
                        borderBottom: '2px solid black',
                        padding: 20
                      }}
                    >
                      Carrier: {rowData?.carrierName}
                    </div>
                    <div
                      style={{
                        fontSize: 20,
                        padding: 20
                      }}
                    >
                      1 of 1 Handling Units
                    </div>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button size='small' variant='contained' onClick={generatePDF}>
              Generate PDF
            </Button>
          </DialogActions>
        </Dialog>
        {/* Packaging Slip Box */}
        <Dialog
          fullWidth
          maxWidth='md'
          minWidth='xs'
          sx={{ '& .MuiDialog-paper': { minWidth: '850px' } }}
          open={openPDfPackagingSlip}
          onClose={handlePdfPackagingClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>Packaging Slip </div>
              <div>
                <CloseIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={handlePdfPackagingClose}
                />
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <div
                id='pdf-content'
                style={{
                  minWidth: '100%',
                  minHeight: '100%',
                  padding: 10
                }}
              >
                {/* 1st page  */}
                <div style={{ display: 'flex' }}>
                  {' '}
                  <div>
                    <img alt='' style={{ height: 120 }} src={NoLogo} />
                  </div>
                  <div style={{ marginLeft: 60 }}>
                    <div style={{ fontWeight: 'bold', fontSize: 18 }}>
                      {rowData?.billToCompany}
                    </div>
                    <div>
                      {rowData?.billToZipCode +
                        ' ' +
                        rowData?.billToCity +
                        ' ' +
                        rowData?.billToState}
                    </div>
                    <div>{rowData?.billToAddress}</div>
                    <div>
                      {rowData?.billToContactPhone} |{' '}
                      {rowData?.billToContactEmail}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    margin: 10
                    // display: 'flex',
                    // justifyContent: 'space-between'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <div>Salesperson : </div>
                    <div>
                      <Barcode
                        textPosition='top'
                        displayValue={true}
                        height={50}
                        fontSize={17}
                        width={1.5}
                        value={`Order # ${rowData?.pickupNumber}`}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: -5,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <div>Customer : </div>
                    <div>
                      {handleDateTimeShow(rowData.actualPickupDate, 'bol')}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    // justifyContent: 'center',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      padding: 10,
                      border: '0.5px solid black',
                      width: '100%'
                    }}
                  >
                    <div style={{ fontWeight: 'bold', fontSize: 18 }}>
                      Consignee/Ship To
                    </div>{' '}
                    <div style={{ marginLeft: 10 }}>
                      <div>{rowData?.originCompany}</div>
                      <div>{rowData?.originAddress}</div>
                      <div>
                        {rowData?.originContactPerson},{' '}
                        {rowData?.originZipCode +
                          ' ' +
                          rowData?.originCity +
                          ' ' +
                          rowData?.originState}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: 10,
                      border: '0.5px solid black',
                      width: '100%'
                    }}
                  >
                    <div style={{ fontWeight: 'bold', fontSize: 18 }}>
                      Sold To
                    </div>{' '}
                    <div style={{ marginLeft: 10 }}>
                      <div>{rowData?.destinationCompany}</div>

                      <div>{rowData?.destinationAddress}</div>
                      <div>
                        {rowData?.destinationContactPerson},
                        {rowData?.destinationZipCode +
                          ' ' +
                          rowData?.destinationCity +
                          ' ' +
                          rowData?.destinationState}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <table style={{ marginTop: 10 }} className='custom-table'>
                    <thead
                      style={{
                        backgroundColor: 'rgb(222 222 222)',
                        fontWeight: 'bold',
                        border: 'none'
                      }}
                    >
                      <tr>
                        <th className='custom-col-100'>PO Number</th>
                        <th className='custom-col-100'>Terms</th>
                        <th className='custom-col-100'>Loc</th>
                        <th className='custom-col-100'>Ship Date</th>
                        <th className='custom-col-100'>Freight Terms</th>
                        <th className='custom-col-100'>Carrier</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{rowData?.clientPONumber}</td>
                        <td> </td>
                        <td>{rowData?.locationName}</td>
                        <td>
                          {handleDateTimeShow(
                            rowData.actualDeliveryDate,
                            'bol'
                          )}
                        </td>
                        <td>{rowData?.paymentTerms}</td>
                        <td>{rowData?.carrierName}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <table style={{ marginTop: 10 }} className='custom-table'>
                    <thead
                      style={{
                        backgroundColor: 'rgb(222 222 222)',
                        fontWeight: 'bold',
                        border: 'none'
                      }}
                    >
                      <tr>
                        <th className='custom-col-100'>Customer Item</th>
                        <th className='custom-col-100'>Description</th>
                        <th className='custom-col-100'>Qty</th>
                        <th className='custom-col-100'>Class</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowData?.details.map(data => (
                        <tr>
                          <td>{data.customerId}</td>
                          <td>
                            {data.itemDescription} <br />
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                            >
                              <div>
                                <u>Batch</u> <br /> {data.itemNumber}
                              </div>
                              <div>
                                <u>Quantity</u> <br /> {data.netWeight}
                              </div>
                              <div>
                                <u>Nbr of Boxes</u> <br /> {data.pieces}
                              </div>
                              <div>
                                <u>Quantity Per</u> <br />
                              </div>
                            </div>
                          </td>
                          <td>{data.netWeight} LB</td>
                          <td>
                            {`FRT Class ${data.freightClass}` +
                              ' ' +
                              `NMFC ${data.nmfc}`}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className='page-break'></div>
                <div>
                  <div>
                    <b>
                      For Chemical Emergency, Leak, Fire, Exposure, or Accident,
                      Call CHEMTREC1-800-424-9300 Day or Night
                    </b>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'center',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        padding: 10,
                        border: '0.5px solid black',
                        width: '100%'
                      }}
                    >
                      <div>
                        This is to certify that the above named materials are
                        properly classified, described, packaged, marked and
                        labeled and are proper condition for transsportation
                        according to the applicable regulations of the
                        Department of Transportation.
                      </div>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        border: '0.5px solid black',
                        width: '100%'
                      }}
                    >
                      <div>
                        If the shipment moves between two port carriers by
                        water, the law requires that the lading shall state
                        whether it is a "carrier shipper's weight." Note: Where
                        the rate is dependent on weight shippers are required to
                        state specifications writing the agreed or declared
                        value of property. The agreed or declared value of the
                        property hereby specifically states by the shipped to be
                        not exceeding ______________.
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: 10,
                      border: '0.5px solid black',
                      width: '100%'
                    }}
                  >
                    <div>
                      SUBJECT TO SECTION 7 OF THE CONDITIONS OF APPLICABLE BILL
                      OF LADING, IF THE SHIPMENT IS TO BE DELIVERED TO THE
                      CONSIGNEE, WITHOUT RECOURSE ON THE CONSIGNOR, THE
                      CONSIGNOR SHALL SIGN THE FOLLOWING STATEMENT. THE CARRIER
                      SHALL NOT MAKE DELIVERY OF THIS SHIPMENT WITHOUT PAYMENT
                      OF FREIGHT AND ALL OTHER LAWFUL CHARGES. (Signature)
                      ____________________________.
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'center',
                      width: '100%'
                    }}
                  >
                    <div
                      style={{
                        padding: 10,
                        border: '0.5px solid black',
                        width: '100%'
                      }}
                    >
                      SHIPPER
                    </div>
                    <div
                      style={{
                        padding: 10,
                        border: '0.5px solid black',
                        width: '100%'
                      }}
                    >
                      AGENT
                    </div>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              size='small'
              variant='contained'
              onClick={generatePackagingSlipPdf}
            >
              Generate PDF
            </Button>
          </DialogActions>
        </Dialog>
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={valueY}
          onChange={handleChangeY}
          aria-label='Vertical tabs example'
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            mr: 1
          }}
        >
          <Tab
            sx={{ display: 'flex', alignItems: 'flex-start' }}
            label='Shipment Info'
            {...a11yProps(0)}
          />

          <Tab
            sx={{ display: 'flex', alignItems: 'flex-start' }}
            label='Line Items'
            {...a11yProps(1)}
          />

          <Tab
            sx={{ display: 'flex', alignItems: 'flex-start' }}
            label='Addresses'
            {...a11yProps(2)}
          />

          {(shipmentStatus === 'InTransit' || shipmentStatus === 'Delivered') &&
            rowData?.shipmentType !== 'TL' && (
              <Tab
                sx={{ display: 'flex', alignItems: 'flex-start' }}
                label={
                  loader ? (
                    <CircularProgress sx={{ marginLeft: 8 }} size={22} />
                  ) : (
                    'Delivery Status'
                  )
                }
                {...a11yProps(3)}
                onClick={e =>
                  handleTrackShipmentRequest(props?.rowData, e, 'track')
                }
              />
            )}
          {(shipmentStatus === 'InTransit' || shipmentStatus === 'Delivered') &&
            rowData?.shipmentType === 'TL' && (
              <Tab
                sx={{ textAlign: 'right' }}
                label='Corresponding History'
                {...a11yProps(4)}
              />
            )}
          {(shipmentStatus === 'InTransit' || shipmentStatus === 'Delivered') &&
            props?.rowData?.shipmentType === 'LTL' && (
              <>
                <Button
                  size='small'
                  variant='outlined'
                  sx={{ mr: 1, width: 170 }}
                  onClick={() => handleBillOfLadingRequest('')}
                >
                  {docLoader ? (
                    <CircularProgress size={22} />
                  ) : (
                    <>
                      Bill of Lading
                      <DownloadIcon sx={{ fontSize: 18 }} />
                    </>
                  )}
                </Button>
              </>
            )}
          {(shipmentStatus === 'InTransit' || shipmentStatus === 'Delivered') &&
            props?.rowData?.shipmentType === 'LTL' && (
              <Button
                sx={{ mt: 2, mr: 1, width: 170 }}
                size='small'
                variant='outlined'
                onClick={() => handleProofOfDeliveryRequest('')}
              >
                {PodDocLoader ? (
                  <CircularProgress size={22} />
                ) : (
                  <>
                    Proof of Delivery
                    <DownloadIcon sx={{ fontSize: 18 }} />
                  </>
                )}
              </Button>
            )}
          {/* {(shipmentStatus === 'InTransit' || shipmentStatus === 'Delivered') &&
            props?.rowData?.shipmentType === 'LTL' && (
              <Button
                sx={{ mt: 2, mr: 1, width: 170 }}
                size='small'
                variant='outlined'
                onClick={() => setOpenPrintPDfDialog(true)}
              >
                Bill of Lading
                <DownloadIcon sx={{ fontSize: 18 }} />
              </Button>
            )}
          {(shipmentStatus === 'InTransit' || shipmentStatus === 'Delivered') &&
            props?.rowData?.shipmentType === 'LTL' && (
              <Button
                sx={{ mt: 2, mr: 1, width: 170 }}
                size='small'
                variant='outlined'
                onClick={() => handlePackagingSlip()}
              >
                Packaging Slip
                <DownloadIcon sx={{ fontSize: 18 }} />
              </Button>
            )} */}
        </Tabs>
        <TabPanel value={valueY} index={0}>
          <Box sx={{ bgcolor: 'background.paper', width: '90vw' }}>
            <TabPanelX value={valueX} index={0}>
              <Row>
                {
                  <Col lg={3} xl={3}>
                    <div style={{ marginTop: 10 }}>
                      <div className='View_Details_Headings'>Sale Order#</div>
                      <span className='View_Details'>{rowData.soNumber}</span>
                    </div>

                    {(shipmentStatus === 'InTransit' ||
                      shipmentStatus === 'Delivered' ||
                      shipmentStatus === 'Booked') &&
                    rowData.shipmentType === 'TL' ? (
                      <div style={{ marginTop: 10 }}>
                        <div className='View_Details_Headings'>
                          Service Type
                        </div>
                        <span className='View_Details'>
                          {rowData.pickupNumber}
                        </span>
                      </div>
                    ) : (
                      (shipmentStatus === 'InTransit' ||
                        shipmentStatus === 'Delivered' ||
                        shipmentStatus === 'Delivered' ||
                        shipmentStatus === 'Booked') && (
                        <div style={{ marginTop: 10 }}>
                          <div className='View_Details_Headings'>Pickup#</div>

                          <span className='View_Details'>
                            {' '}
                            {rowData.pickupNumber}
                          </span>
                        </div>
                      )
                    )}
                  </Col>
                }

                {(shipmentStatus === 'InTransit' ||
                  shipmentStatus === 'Delivered' ||
                  shipmentStatus === 'Booked') &&
                rowData.shipmentType === 'Parcel' ? (
                  <Col lg={3} xl={3}>
                    <div style={{ marginTop: 10 }}>
                      <div className='View_Details_Headings'>Tracking#</div>
                      <span className='View_Details'> {rowData.proNumber}</span>
                    </div>
                  </Col>
                ) : (
                  (shipmentStatus === 'InTransit' ||
                    shipmentStatus === 'Delivered' ||
                    shipmentStatus === 'Booked') &&
                  rowData.shipmentType !== 'TL' &&
                  (rowData.proNumber || rowData.bolNumber) && (
                    <Col lg={3} xl={3}>
                      {rowData.proNumber && (
                        <div style={{ marginTop: 10 }}>
                          <div className='View_Details_Headings'>PRO#</div>
                          <span className='View_Details'>
                            {' '}
                            {rowData.proNumber}
                          </span>
                        </div>
                      )}

                      {rowData.bolNumber && (
                        <div style={{ marginTop: 10 }}>
                          <div className='View_Details_Headings'>BOL#</div>
                          <span className='View_Details'>
                            {rowData.bolNumber}
                          </span>
                        </div>
                      )}
                    </Col>
                  )
                )}

                <Col lg={3} xl={3}>
                  {rowData.quoteNumber && rowData.shipmentType !== 'Parcel' && (
                    <div style={{ marginTop: 10, whiteSpace: 'nowrap' }}>
                      <div className='View_Details_Headings'>
                        Reference/Quote
                      </div>

                      {rowData.quoteNumber}
                    </div>
                  )}
                  <div style={{ marginTop: 10 }}>
                    <div className='View_Details_Headings'>Purchase Order#</div>

                    <span className='View_Details'>
                      {' '}
                      {rowData.clientPONumber}
                    </span>
                  </div>
                </Col>

                <Col lg={3} xl={3}>
                  <div
                    style={
                      rowData.quoteNumber ? { marginTop: 10 } : { marginTop: 1 }
                    }
                  >
                    <div className='View_Details_Headings'> Location Name</div>

                    <div className='View_Details'>{rowData.locationName}</div>
                  </div>
                </Col>
              </Row>
            </TabPanelX>
          </Box>
        </TabPanel>

        <TabPanel value={valueY} index={1}>
          <Box>
            <TabPanelX value={valueX} index={0}>
              <Row>
                <Col lg={12} xl={12}>
                  <TableContainer sx={{ width: '70vw' }} component={Paper}>
                    <Table
                      sx={{ width: '70vw' }}
                      // size='small'
                      // aria-label='a dense table'
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold' }} align='left'>
                            Total # of Pallets
                          </TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }} align='left'>
                            Total # of Pieces
                          </TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }} align='left'>
                            NMFC
                          </TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }} align='left'>
                            Weight
                          </TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }} align='left'>
                            Freight Class
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowData?.details.map(row => (
                          <TableRow
                            key={row.name}
                            // sx={{
                            //   '&:last-child td, &:last-child th': { border: 0 }
                            // }}
                          >
                            <TableCell align='left'>{row.pallets}</TableCell>
                            <TableCell align='left'>{row.pieces}</TableCell>
                            <TableCell align='left'>{row.nmfc}</TableCell>
                            <TableCell align='left'>{row.netWeight}</TableCell>
                            <TableCell align='left'>
                              {row.freightClass}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
              </Row>
            </TabPanelX>
          </Box>
        </TabPanel>

        <TabPanel value={valueY} index={2}>
          <TabPanelX value={valueX} index={0}>
            <Row>
              <Col lg={12} xl={12}>
                <TableContainer sx={{ width: '70vw' }} component={Paper}>
                  <Table
                    sx={{ width: '70vw' }}
                    // size='small'
                    // aria-label='a dense table'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Lables
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Origin Information
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }} align='left'>
                          Destination Information
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }} align='left'>
                          Bill To Information
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          <b>Address</b>
                        </TableCell>
                        <TableCell align='left'>
                          {rowData.originZipCode +
                            ', ' +
                            rowData.originCity +
                            ', ' +
                            rowData.originState}
                        </TableCell>

                        <TableCell align='left'>
                          {rowData.destinationZipCode +
                            ', ' +
                            rowData.destinationCity +
                            ', ' +
                            rowData.destinationState}
                        </TableCell>
                        <TableCell align='left'>
                          {rowData.billToZipCode +
                            ', ' +
                            rowData.billToCity +
                            ', ' +
                            rowData.billToState}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          <b>Name</b>
                        </TableCell>
                        <TableCell align='left'>
                          {rowData.originContactPerson}
                        </TableCell>
                        <TableCell align='left'>
                          {rowData.destinationContactPerson}
                        </TableCell>
                        <TableCell align='left'>
                          {rowData.billToContactPerson}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          <b>Phone</b>
                        </TableCell>
                        <TableCell align='left'>
                          {rowData.originContactPhone}
                        </TableCell>
                        <TableCell align='left'>
                          {rowData.destinationContactPhone}
                        </TableCell>
                        <TableCell align='left'>
                          {rowData.billToContactPhone}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          <b>Email</b>
                        </TableCell>

                        <TableCell align='left'>
                          {rowData.originContactEmail}
                        </TableCell>
                        <TableCell align='left'>
                          {rowData.destinationContactEmail}
                        </TableCell>
                        <TableCell align='left'>
                          {rowData.billToContactPerson}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          <b>Date</b>
                        </TableCell>
                        <TableCell align='left'>
                          <b>Ship Date :</b>{' '}
                          {handleDateTimeShow(rowData.actualPickupDate, 'date')}
                        </TableCell>
                        <TableCell align='left'>
                          <b>Carrier's Delivery Date :</b>{' '}
                          {handleDateTimeShow(
                            rowData.actualDeliveryDate,
                            'date'
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>
            </Row>
          </TabPanelX>
        </TabPanel>
        <TabPanel value={valueY} index={3}>
          <TabPanelX value={valueX} index={0}>
            {rowData?.shipmentType === 'TL' ? (
              (shipmentStatus === 'InTransit' ||
                shipmentStatus === 'Delivered') && (
                <div>
                  {!docLoader ? (
                    historyError ? (
                      <Box
                        sx={{ textAlign: 'center' }}
                        className='Chat_Child_View_Shipment'
                      >
                        <br />
                        <br />
                        <br />
                        <br />
                        <WarningAmberIcon
                          style={{ fontSize: 150, color: '#D32F2F' }}
                        />{' '}
                        {/* <br /> */}
                        <br />
                        <Typography variant='h5'>
                          No history found for this shipment!
                        </Typography>
                        <br />
                        <br />
                        <br />
                      </Box>
                    ) : (
                      <div className='Chat_Child_View_Shipment'>
                        {shipperApiMsgs &&
                          shipperApiMsgs.map(carrierRes => (
                            <div
                              style={
                                carrierRes.actionByFlag.toLowerCase() ===
                                'carrier'
                                  ? {
                                      display: 'flex',
                                      justifyContent: 'start',
                                      flexDirection: 'column',
                                      marginLeft: 10,
                                      marginRight: 10,
                                      width: 400,
                                      position: 'relative'
                                    }
                                  : {
                                      display: 'flex',
                                      justifyContent: 'end',
                                      // flexDirection: 'column',
                                      marginLeft: 10,
                                      marginRight: 10,
                                      position: 'relative'
                                    }
                              }
                            >
                              {/* <div
                          className={
                            carrierRes.actionByFlag.toLowerCase() === 'carrier'
                              ? 'agency_manager_triangle'
                              : 'agency_carrier_triangle'
                          }
                        ></div> */}
                              {carrierRes.actionByFlag.toLowerCase() ===
                                'carrier' && (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'start'
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: '#e0f2f1',
                                      borderRadius: '50%',
                                      width: '45',
                                      height: '45',
                                      textAlign: 'center',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      marginRight: 10
                                    }}
                                  >
                                    <img
                                      style={{
                                        borderRadius: '50%',
                                        width: '45',
                                        height: '45',
                                        textAlign: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        objectFit: 'cover'
                                      }}
                                      alt=''
                                      src={
                                        base_logo_url +
                                        carrierApiMsgs[0]?.actionByImage
                                      }
                                    />
                                  </div>
                                  <div>
                                    <b style={{ fontSize: 18 }}>
                                      {carrierApiMsgs &&
                                        carrierApiMsgs[0]?.actionByName}
                                    </b>
                                    <br />
                                    <span style={{ fontWeight: 600 }}>
                                      {carrierApiMsgs[0]?.actionByDesignation}
                                    </span>
                                  </div>
                                </div>
                              )}
                              <div>
                                {carrierRes.actionByFlag.toLowerCase() ===
                                  'shipper' && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'end'
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginLeft: 0,
                                        marginRight: 1,
                                        textAlign: 'right'
                                      }}
                                    >
                                      <b style={{ fontSize: 18 }}>
                                        {shipperApiMsgs &&
                                          shipperApiMsgs[0]?.actionByName}
                                      </b>
                                      <br />
                                      <span style={{ fontWeight: 600 }}>
                                        {shipperApiMsgs[0]?.actionByDesignation}
                                      </span>
                                      <br />
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor: '#e0f2f1',
                                        borderRadius: '50%',
                                        width: '45',
                                        height: '45',
                                        textAlign: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginLeft: 10
                                      }}
                                    >
                                      <img
                                        style={{
                                          borderRadius: '50%',
                                          width: '45',
                                          height: '45',
                                          textAlign: 'center',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          objectFit: 'cover'
                                        }}
                                        alt=''
                                        src={
                                          base_logo_url +
                                          shipperApiMsgs[0]?.actionByImage
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                <div
                                  style={
                                    carrierRes.actionByFlag.toLowerCase() ===
                                    'shipper'
                                      ? {
                                          marginBottom: '10vh',
                                          backgroundColor: '#e0f2f1',
                                          borderRadius: '10px 0px 10px 10px',
                                          padding: '10px',
                                          transform: '(rotateX(180deg)'
                                        }
                                      : {
                                          marginBottom: '10vh',
                                          backgroundColor: '#eceff1',
                                          padding: '10px',
                                          borderRadius: '0px 10px 10px 10px'
                                        }
                                  }
                                >
                                  <div
                                    style={{ fontSize: 12 }}
                                    dangerouslySetInnerHTML={{
                                      __html: handleCorresHistory(
                                        carrierRes.actionData
                                      )
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      fontSize: 11
                                    }}
                                  >
                                    {handleDateTimeShow(
                                      carrierRes.date,
                                      'time'
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                        {/* <div ref={bottomRef} /> */}
                      </div>
                    )
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40vw',
                        height: '90%'
                      }}
                    >
                      <CircularProgress />
                    </div>
                  )}
                </div>
              )
            ) : (
              <Col xl={4} lg={4}>
                {trackShipmentData && (
                  <div>
                    <TrackShipmentDetails
                      trackShipmentData={trackShipmentData}
                      view={viewType}
                    />
                  </div>
                )}
                {viewType === 'history' && (
                  <div>
                    <TrackHistory
                      trackShipmentData={trackShipmentData}
                      view={viewType}
                    />
                  </div>
                )}
              </Col>
            )}
          </TabPanelX>
        </TabPanel>
      </Box>
    </div>
  );
}

export default ViewShipment;

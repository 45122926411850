import { createSlice } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';

export const quickQuoteRate = createSlice({
  name: 'getCarrierRates',
  initialState: {
    QuickQuoteRateValues: {}
  },
  reducers: {
    setQuickQuoteRateValues: (state, action) => {
      return action.payload;
    }
  },
  extraReducers: {
    // Extra reducer comes here
  }
});
export const quickQuoteSelectLoc = createSlice({
  name: 'quickQuoteLoc',
  initialState: {
    QuickQuoteRateLoc: ''
  },
  reducers: {
    setQuickQuoteRateLoc: (state, action) => {
      state.QuickQuoteRateLoc = action.payload;
    }
  },
  extraReducers: builder => {
    // Extra reducers can be added here if needed
  }
});
export const { setQuickQuoteRateLoc } = quickQuoteSelectLoc.actions;

export const { setQuickQuoteRateValues } = quickQuoteRate.actions;

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GetAppRegistrations, SaveAppRegistration } from '../../apis/Agency';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../Carriers/Carriers.css';
import { ErrorToast, successToast } from '../../components/Toasts';
import { ToastContainer } from 'react-toastify';
import {
  MaterialReactTable,
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton
} from 'material-react-table';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { RemoveRedEye } from '@material-ui/icons';
import { MetaTags } from 'react-meta-tags';
import { base_url } from '../../apis/constants';

function AppRegisteration(props) {
  const [clientReqURL, setClientReqURL] = useState('');
  const UserIsAdmin = useSelector(state => state.userDetails.isAdmin);
  const userRolePermissions = useSelector(
    state => state.userDetails.userRolePermissionsArr
  );
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const [carriersLoader, setCarriersLoader] = useState(true);
  const [carriers, setCarriers] = useState([]);

  const [userPermissionsArr, setUserPermissionsArr] = useState(null);

  const handleSuccesGetCarriers = ApiRes => {
    setClientConfigLoader(false);
    const CarrierArray = ApiRes?.data?.data.reverse();
    setCarriersLoader(false);
    setCarriers(CarrierArray);
  };
  useEffect(() => {
    GetAppRegistrations(AgencyID)
      .then(res => res.status === 200 && handleSuccesGetCarriers(res))
      .catch(err => console.log(err, 'err'));
  }, []);
  const [open, setOpen] = useState(false);
  const [clientConfigLoader, setClientConfigLoader] = useState(false);
  const [configErrors, setConfigErrors] = useState({});

  const ClientConfigRequest = (updatedData, status) => {
    setClientConfigLoader(true);
    let obj = {
      id: updatedData?.id ? updatedData?.id : 0,
      agencyId: AgencyID,
      clientId: updatedData?.clientId ? updatedData?.clientId : inputString,
      clientSecret: updatedData?.clientSecret
        ? updatedData?.clientSecret
        : generatedId,
      clientUri: updatedData?.clientURI ? updatedData?.clientURI : clientReqURL,
      active: updatedData?.id ? status : true
    };
    SaveAppRegistration(obj)
      .then(res => {
        res.status === 200 &&
          successToast(
            updatedData?.id
              ? `Configuration Status ${status ? 'Activated' : 'In-Activated'}`
              : 'Configurations Added'
          );
        handleClose();
        GetAppRegistrations(AgencyID)
          .then(res => res.status === 200 && handleSuccesGetCarriers(res))
          .catch(err => {
            setClientConfigLoader(false);
            console.log(err, 'err');
          });
      })
      .catch(err => {
        console.log(err, 'reasonPhrase');
        ErrorToast(err?.response?.data?.reasonPhrase);
        handleClose();
      });
  };

  const handleConfirmationCarrierSettins = () => {
    let validationErrors = {};

    if (!isValidUrl || !clientReqURL) {
      validationErrors['requestURL'] = 'Enter valid URL';
      setConfigErrors(validationErrors);
      //   setClientReqURL('');
    } else {
      //   setClientReqURL(value);
      setConfigErrors({ ...configErrors, requestURL: null });
      ClientConfigRequest();
    }
  };

  const handleClose = () => {
    setClientConfigLoader(false);
    setOpen(false);
    setClientReqURL('');
    setInputString('');
    setGeneratedId('');
    setConfigErrors({});
  };

  const handleUpdateCarrier = (e, items) => {
    // console.log(items.row.original, 'items');
    e.stopPropagation();
    // const items = data.row.original;
    setOpen(true);
  };

  const handleClientStatusChange = (e, rowData) => {
    ClientConfigRequest(rowData, e.target.checked);
  };
  const handleCopy = value => {
    // navigator.clipboard
    //   .writeText(value)
    //   .then(() => {
    //     successToast('Value copied to clipboard!');
    //   })
    //   .catch(error => {
    //     console.error('Unable to copy:', error);
    //     // alert('Failed to copy value to clipboard.');
    //   });
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          successToast('Text copied to clipboard');
          console.log('Text copied to clipboard');
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
    } else {
      console.warn('Clipboard API not available');
      // Fallback: Use an alternative method to copy text
      // For example, create a temporary textarea and copy its contents
      const textarea = document.createElement('textarea');
      textarea.value = value;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        console.log('Text copied to clipboard using fallback method');
        successToast('Text copied to clipboard');
      } catch (err) {
        console.error('Fallback: Failed to copy text: ', err);
      }
      document.body.removeChild(textarea);
    }
  };

  const mncolumns = [
    {
      accessorKey: 'active',
      id: 'active',

      header: 'Update Status',
      size: 1,
      enableColumnFilter: false,
      Cell: ({ cell, row }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Chip
              //   variant='outlined'
              size='small'
              label={
                <Switch
                  disabled={
                    UserIsAdmin ||
                    (userPermissionsArr &&
                      userPermissionsArr.filter(
                        permissions => permissions.level4
                      ).length)
                      ? false
                      : true
                  }
                  size='small'
                  onClick={e => e.stopPropagation()}
                  onChange={e => handleClientStatusChange(e, row.original)}
                  checked={cell.getValue()}
                />
              }
            />
          </Box>
        </>
      )
    },
    // {
    //   accessorKey: 'active',
    //   id: 'active',
    //   header: 'Status',
    //   enableColumnFilter: false,
    //   size: 100,
    //   Cell: ({ cell }) => (cell.getValue() === true ? 'Active' : 'In-active')
    // },

    {
      accessorKey: 'clientId',
      id: 'clientId',
      header: 'Client ID',
      Cell: ({ cell, row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          {UserIsAdmin ||
          (userPermissionsArr &&
            userPermissionsArr.filter(permissions => permissions.level2)
              .length) ? (
            <Tooltip arrow title='Copy'>
              <ContentCopyIcon
                onClick={event => {
                  event.stopPropagation();

                  const value = cell.getValue();
                  handleCopy(value);
                }}
                sx={{ mr: 1, fontSize: '18px', cursor: 'pointer' }}
              />
            </Tooltip>
          ) : null}
          {UserIsAdmin ||
          (userPermissionsArr &&
            userPermissionsArr.filter(permissions => permissions.level2)
              .length) ? (
            cell.getValue()
          ) : (
            <TextField
              InputLabelProps={{
                style: { color: 'black', fontSize: 28 }
              }}
              disabled
              InputProps={{
                disableUnderline: true
              }}
              variant='standard'
              size='small'
              type='password'
              value={cell.getValue()}
            />
          )}
        </Box>
      )
    },

    {
      accessorKey: 'clientSecret',
      id: 'clientSecret',
      header: 'Client Secret',
      Cell: ({ cell, row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          {UserIsAdmin ||
          (userPermissionsArr &&
            userPermissionsArr.filter(permissions => permissions.level2)
              .length) ? (
            <Tooltip arrow title='Copy'>
              <ContentCopyIcon
                onClick={event => {
                  event.stopPropagation();

                  const value = cell.getValue();
                  handleCopy(value);
                }}
                sx={{ mr: 1, fontSize: '18px', cursor: 'pointer' }}
              />
            </Tooltip>
          ) : null}
          {UserIsAdmin ||
          (userPermissionsArr &&
            userPermissionsArr.filter(permissions => permissions.level2)
              .length) ? (
            cell.getValue()
          ) : (
            <TextField
              InputLabelProps={{
                style: { color: 'black', fontSize: 28 }
              }}
              disabled
              InputProps={{
                disableUnderline: true
              }}
              variant='standard'
              size='small'
              type='password'
              value={cell.getValue()}
            />
          )}
        </Box>
      )
    },
    {
      accessorKey: 'clientURI',
      id: 'clientURI',
      header: 'Client URI',
      Cell: ({ cell, row }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          {UserIsAdmin ||
          (userPermissionsArr &&
            userPermissionsArr.filter(permissions => permissions.level2)
              .length) ? (
            <Tooltip arrow title='Copy'>
              <ContentCopyIcon
                onClick={event => {
                  event.stopPropagation();

                  const value = cell.getValue();
                  handleCopy(value);
                }}
                sx={{ mr: 1, fontSize: '18px', cursor: 'pointer' }}
              />
            </Tooltip>
          ) : null}
          {UserIsAdmin ||
          (userPermissionsArr &&
            userPermissionsArr.filter(permissions => permissions.level2)
              .length) ? (
            cell.getValue().length > 90 ? (
              cell.getValue().substring(0, 90) + ' .....'
            ) : (
              cell.getValue()
            )
          ) : (
            <TextField
              InputLabelProps={{
                style: { color: 'black', fontSize: 28 }
              }}
              disabled
              InputProps={{
                disableUnderline: true
              }}
              variant='standard'
              size='small'
              type='password'
              value={cell.getValue()}
            />
          )}
        </Box>
      )
    }
  ];

  useEffect(() => {
    const filteredCarrierPermissions = userRolePermissions?.filter(
      items => items.permissionName === 'App-Registration'
    );
    setUserPermissionsArr(filteredCarrierPermissions);
    setColumnsData(mncolumns);
  }, [userRolePermissions, carriers]);

  const [value, setValue] = useState('1');
  const [columnsData, setColumnsData] = useState(mncolumns);

  // useEffect(() => {
  //   if (props?.location?.state === 'Non-LTL') {
  //     setValue('2');
  //     handleChange('event', '2');
  //     // setTLCarriers(TLcarriers);
  //   }
  // }, []);

  const [inputString, setInputString] = useState('');
  const [generatedId, setGeneratedId] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(false);
  const generateRandomString = () => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 32; i++) {
      // Generating a string of length 32
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setGeneratedId(result);
    return result;
  };

  const handleGenerateId = () => {
    let validationErrors = {};
    if (inputString.trim() !== '') {
      const id = generateRandomString(inputString);
      setGeneratedId(id);
    } else {
      validationErrors['clientId'] = 'Enter Client ID';
      setConfigErrors(validationErrors);
    }
  };

  const handleInputChange = event => {
    const value = event.target.value;
    setIsValidUrl(validateUrl(value));
    setClientReqURL(value);
  };

  const validateUrl = url => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlRegex.test(url);
  };
  return (
    <Box
      // sx={{ overflowY: 'scroll' }}
      className={carriersLoader ? 'Box_Loader' : 'Box_Parent'}
    >
      {' '}
      <MetaTags>
        <title>
          {' '}
          {base_url === 'https://beta.api.shiptechpro.com/'
            ? 'App Registeration | Shiptechpro'
            : 'App Registeration | TMS Shipping'}
        </title>
      </MetaTags>
      <ToastContainer
        style={{ width: 'auto' }}
        position='top-center'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Grid sx={{ backgroundColor: 'red' }}>
        <Dialog
          PaperProps={{
            style: {
              minWidth: '30vw',
              height: '40vh'
            }
          }}
          className='InnerDialog'
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            Client Configuration Settings
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <Grid item xs={12}>
                  <TextField
                    required
                    error={Boolean(configErrors['clientId'])}
                    helperText={configErrors['clientId']}
                    FormHelperTextProps={{ style: { margin: ' 5 0 0 0' } }}
                    sx={{ mt: 3.2 }}
                    // type='number'
                    fullWidth
                    label='Client ID'
                    size='small'
                    type='text'
                    value={inputString}
                    onChange={e => {
                      setConfigErrors({ ...configErrors, clientId: null });
                      setInputString(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    sx={{ mt: 3.2 }}
                    // type='number'
                    fullWidth
                    label='Client Secret ID'
                    size='small'
                    type='text'
                    value={generatedId}
                  />
                  <Grid sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      disabled={inputString ? false : true}
                      // variant='outlined'
                      // sx={{ mt: 0, whiteSpace: 'nowrap' }}
                      size='small'
                      onClick={handleGenerateId}
                    >
                      Generate Secret Id
                    </Button>
                  </Grid>
                </Grid>
                {/* {generatedId && (
                  <div style={{ marginTop: 10 }}>
                    <p>Generated ID: {generatedId}</p>
                  </div>
                )} */}
                <Grid sx={{ mt: 0 }} container spacing={0}>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>
                    <TextField
                      required
                      error={Boolean(configErrors['requestURL'])}
                      helperText={configErrors['requestURL']}
                      FormHelperTextProps={{ style: { margin: ' 5 0 0 0' } }}
                      // type='number'
                      fullWidth
                      label='Request URL'
                      size='small'
                      value={clientReqURL}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button size='small' variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={inputString && generatedId ? false : true}
              size='small'
              variant='contained'
              color='success'
              onClick={handleConfirmationCarrierSettins}
              autoFocus
            >
              Configure
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid container sx={{ m: 0 }}>
        {carriersLoader ? (
          <CircularProgress />
        ) : (
          <Grid
            xs={12}
            md={12}
            sm={12}
            lg={12}
            xl={12}
            sx={{ backgroundColor: '#fff', p: 3 }}
          >
            <Grid xs={12} md={12} sm={12} xl={12} lg={12}>
              <MaterialReactTable
                state={{ isLoading: clientConfigLoader }}
                columns={columnsData}
                data={carriers}
                enableRowSelection
                muiTableBodyRowProps={({ row }) => ({
                  onClick: row.getToggleSelectedHandler()

                  // sx: { cursor: 'pointer' },
                })}
                // enableMultiSort

                muiTableHeadCellFilterTextFieldProps={{ placeholder: '' }}
                // enableStickyHeader
                // enableStickyFooter

                muiTableContainerProps={{
                  sx: { maxHeight: '60vh', minHeight: '60vh' }
                }}
                positionToolbarAlertBanner='bottom'
                initialState={{
                  density: 'compact'
                }}
                renderToolbarInternalActions={({ table }) => (
                  <div>
                    <MRT_ToggleGlobalFilterButton
                      table={table}
                      title='Search'
                    />

                    <MRT_ToggleFiltersButton table={table} title='Filters' />

                    <MRT_ToggleDensePaddingButton
                      table={table}
                      title='Spacing'
                    />
                    <MRT_ShowHideColumnsButton
                      table={table}
                      title='Show/Hide Columns'
                    />
                    <MRT_FullScreenToggleButton
                      table={table}
                      title='Fullscreen'
                    />
                    <Tooltip title='Clear All Filters' arrow>
                      <IconButton
                        onClick={() => {
                          table.resetColumnFilters(true);
                          table.setShowColumnFilters(false);
                        }}
                      >
                        <FilterAltOffIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                renderTopToolbarCustomActions={row => {
                  if (
                    UserIsAdmin ||
                    (userPermissionsArr &&
                      userPermissionsArr.filter(
                        permissions => permissions.level3
                      ).length)
                  ) {
                    return (
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <Button
                          // disabled={
                          //   userPermissionsArr &&
                          //   userPermissionsArr.filter(
                          //     permissions => permissions.level1
                          //   )
                          //     ? true
                          //     : false
                          // }
                          onClick={e => handleUpdateCarrier(e, row.original)}
                          className='m-2'
                          style={{
                            color: '#3361FF',
                            backgroundColor: '#E4F3FD'
                          }}
                          variant='contained'
                        >
                          Add Configuration
                          <svg
                            className='ms-2'
                            width='11'
                            height='10'
                            viewBox='0 0 11 10'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.94531 9.72396V5.72396H0.945312V4.39062H4.94531V0.390625H6.27865V4.39062H10.2786V5.72396H6.27865V9.72396H4.94531Z'
                              fill='#3361FF'
                            />
                          </svg>
                        </Button>
                      </div>
                    );
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default AppRegisteration;

/* eslint-disable no-restricted-globals */
import React, { memo, useEffect } from 'react';
import BasicTreeData from './ShipmentDetailsParentTable';
import './BoxShadow.css';
function ExpandAbleTable(props) {
  return (
    <div
      className='card'
      style={{
        margin: '5px 10px 0px 10px',
        backgroundColor: '#EEF3F9'
      }}
    >
      <BasicTreeData PreviouProps={props} />
    </div>
  );
}

export default memo(ExpandAbleTable);

import { useState, React, useRef, useEffect, memo } from 'react';
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  CircularProgress,
  Tooltip
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import {
  CheckLocation,
  DispatchShipment,
  GetAagencyUserDetails,
  GetAgencyLocations,
  GetCarriers,
  GetNextProNumber,
  GetQuote,
  GetShipmentActivity,
  GetShipments,
  HoldShipment,
  SaveUserSettings,
  TrackShipmentRequest,
  UnbookShipment,
  UnHoldShipment,
  UpdateShipmentLocation
} from '../../../apis/Agency';
import { ErrorToast, successToast } from '../../../components/Toasts';
import { ToastContainer } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PrintIcon from '@mui/icons-material/Print';
import './ShipmentsTable.css';
import { useReactToPrint } from 'react-to-print';
import Papa from 'papaparse';
import { useDispatch, useSelector } from 'react-redux';
import { base_logo_url, base_url } from '../../../apis/constants';
import {
  Badge,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import PanToolTwoToneIcon from '@mui/icons-material/PanToolTwoTone';
import DisabledByDefaultTwoToneIcon from '@mui/icons-material/DisabledByDefaultTwoTone';
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import { useMemo } from 'react';
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleGlobalFilterButton,
  MRT_ColumnOrderState
} from 'material-react-table';
import { Box, Button, IconButton } from '@mui/material';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ViewShipment from '../../ViewShipment/ViewShipment';
import NoLogo from '../../../assets/carrier logos/No Logo.png';
import html2pdf from 'html2pdf.js';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Barcode from 'react-barcode';

function BasicTreeData({ PreviouProps }) {
  const UserIsAdmin = useSelector(state => state.userDetails.isAdmin);

  const UserLoggedInEmail = useSelector(state => state.userDetails.email);
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const userRolePermissions = useSelector(
    state => state.userDetails.userRolePermissionsArr
  );
  const [actualDeliveryDate, setActualDeliveryDate] = useState(false);
  let TempApiNames = [];
  let TLTempApiNames = [];
  let ParcelTempApiNames = [];

  const [expandedDetail, setExpandedDetail] = useState(false);
  const [DateFilterModal, setDateFilterModal] = useState(false);
  const [shipmentsLoader, setShipmentsLoader] = useState(true);
  const [statusLoader, setStatusLoader] = useState(false);
  const [viewShipmentsData, setViewShipmentsData] = useState([]);
  const [savedShipments, setSavedShipments] = useState('');
  const [tenderedShipments, setTenderedShipments] = useState('');
  const [bookedShipments, setBookedShipments] = useState('');
  const [inTransitShipments, setInTransitShipments] = useState('');
  const [deliveredShipments, setDeliveredShipments] = useState('');
  const [carrierApiNames, setCarrierApiNames] = useState([]);
  const [TlCarrierApiNames, setTlCarrierApiNames] = useState([]);
  const [ParcelCarrierApiNames, setParcelCarrierApiNames] = useState([]);
  const [trackShipmentData, setTrackShipmentData] = useState();
  const [holdShipmentRemarks, setHoldShipmentRemarks] = useState('');
  const [markDeliverDialog, setMarkDeliverDialog] = useState(false);
  const [markAsDeliverRowData, setMarkAsDeliverRowData] = useState('');
  const [addUpdateLocationDialog, setAddUpdateLocationDialog] = useState(false);
  const [addUpdateLocationID, setAddUpdateLocationID] = useState('');
  const [addUpdateLocationShipID, setAddUpdateLocationShipID] = useState('');
  const [userPermissionsArr, setUserPermissionsArr] = useState(null);
  const [agencyLocationsLoader, setAgencyLocationsLoader] = useState(false);
  const [agencyLocations, setAgencyLocations] = useState([]);
  const [userLocations, setUserLocations] = useState([]);
  const [clickedRowLocation, setClickedRowLocation] = useState('');
  const [checkLocLoader, setCheckLocLoader] = useState(false);
  const [checkLocIndex, setCheckLocIndex] = useState(null);

  useEffect(() => {
    const filteredCarrierPermissions = userRolePermissions?.filter(
      items => items.permissionName === 'Shipments'
    );
    setUserPermissionsArr(filteredCarrierPermissions);
  }, [userRolePermissions]);

  const handleSuccessShipDetails = res => {
    setShipmentsLoader(false);
    const data = res.data;
    let temp = [];
    let DestinationData;
    let OriginData;
    for (let i = 0; i < data.length; i++) {
      DestinationData =
        data[i].destinationCompany +
        ' ' +
        data[i].destinationCity +
        ' ' +
        data[i].destinationState +
        ' ' +
        data[i].destinationZipCode;

      OriginData =
        data[i].originCompany +
        ' ' +
        data[i].originCity +
        ' ' +
        data[i].originState +
        ' ' +
        data[i].originZipCode;
      temp.push({
        ...data[i],
        OriginData: OriginData,
        destinationData: DestinationData
      });
      // setViewShipmentsData(data[i], DestinationData);
    }
    setViewShipmentsData(temp);
    const SavedShipments = res.data.filter(
      items => items.shipmentStatus === 'Saved'
    );

    const BookedShipments = res.data.filter(
      items => items.shipmentStatus === 'Booked'
    );
    const InTransitShipments = res.data.filter(
      items => items.shipmentStatus === 'InTransit'
    );
    const DeliveredShipments = res.data.filter(
      items => items.shipmentStatus === 'Delivered'
    );

    const TenderedShipments = res.data.filter(
      items => items.shipmentStatus === 'Tendered'
    );
    setTenderedShipments(TenderedShipments);
    setSavedShipments(SavedShipments);
    setBookedShipments(BookedShipments);
    setInTransitShipments(InTransitShipments);
    setDeliveredShipments(DeliveredShipments);
  };

  const handleGetCarrierNames = data => {
    const LtlCarrierArray = data.filter(
      items => items.active && items.cType === 'LTL'
    );
    const TlCarrierArray = data.filter(
      items => items.active && items.cType === 'TL'
    );
    const ParcelCarrierArray = data.filter(
      items => items.active && items.cType === 'Parcel'
    );
    let TempApisEndPoint = [];

    for (let i = 0; i < LtlCarrierArray.length; i++) {
      const CarrierName = LtlCarrierArray[i]?.getRateEndPoint?.replace('/', '');
      const element = base_url + CarrierName;
      TempApisEndPoint.push(element);
    }
    for (let i = 0; i < LtlCarrierArray.length; i++) {
      const CarrierName = LtlCarrierArray[i].name;
      const ID = LtlCarrierArray[i].carrierId;
      // const CarrierLogo = LogoArray.find(
      //   items => items.name === LtlCarrierArray[i].logo
      // );

      const CarrierLogo = base_logo_url + LtlCarrierArray[i].logo;
      TempApiNames.push({
        title: CarrierName,
        carrierID: ID,
        carrierLogo: CarrierLogo
      });
    }
    TempApiNames.push({ title: 'Others', carrierID: '0', carrierLogo: '' });
    setCarrierApiNames(TempApiNames);
    for (let i = 0; i < TlCarrierArray.length; i++) {
      const CarrierName = TlCarrierArray[i]?.getRateEndPoint?.replace('/', '');
      const element = base_url + CarrierName;
      TempApisEndPoint.push(element);
    }
    for (let i = 0; i < TlCarrierArray.length; i++) {
      const CarrierName = TlCarrierArray[i].name;
      const ID = TlCarrierArray[i].carrierId;
      // const CarrierLogo = LogoArray.find(
      //   items => items.name === LtlCarrierArray[i].logo
      // );

      const CarrierLogo = base_logo_url + TlCarrierArray[i].logo;
      TLTempApiNames.push({
        title: CarrierName,
        carrierID: ID,
        carrierLogo: CarrierLogo
      });
    }
    TLTempApiNames.push({ title: 'Others', carrierID: '0', carrierLogo: '' });
    setTlCarrierApiNames(TLTempApiNames);
    for (let i = 0; i < ParcelCarrierArray.length; i++) {
      const CarrierName = ParcelCarrierArray[i]?.getRateEndPoint?.replace(
        '/',
        ''
      );
      const element = base_url + CarrierName;
      TempApisEndPoint.push(element);
    }
    for (let i = 0; i < ParcelCarrierArray.length; i++) {
      const CarrierName = ParcelCarrierArray[i].name;
      const ID = ParcelCarrierArray[i].carrierId;
      // const CarrierLogo = LogoArray.find(
      //   items => items.name === ParcelCarrierArray[i].logo
      // );

      const CarrierLogo = base_logo_url + ParcelCarrierArray[i].logo;
      ParcelTempApiNames.push({
        title: CarrierName,
        carrierID: ID,
        carrierLogo: CarrierLogo
      });
    }
    ParcelTempApiNames.push({
      title: 'Others',
      carrierID: '0',
      carrierLogo: ''
    });
    setParcelCarrierApiNames(ParcelTempApiNames);
  };

  const handleGetSynchDataRecords = () => {
    GetShipments(AgencyID, UserLoggedInEmail)
      .then(res => res.status === 200 && handleSuccessShipDetails(res))
      .catch(err => setShipmentsLoader(false));
  };

  useEffect(() => {
    if (UserLoggedInEmail) {
      FetchCurrentUserDetails();
      GetShipments(AgencyID, UserLoggedInEmail)
        .then(res => res.status === 200 && handleSuccessShipDetails(res))
        .catch(err => setShipmentsLoader(false));

      GetCarriers(AgencyID)
        .then(res => res.status === 200 && handleGetCarrierNames(res.data.data))
        .catch(err => console.log('Error here...', err));
    }

    // GetAagencyUserDetails(UserLoggedInEmail)
    //   .then(
    //     res => res.data.statusCode === 200 && GetSuccessUserDetails(res.data)
    //   )
    //   .catch(err => console.log(err, 'err'));
  }, []);

  const history = useHistory();

  // const tableRef = useRef();

  const [onclickSelectedRow, setOnclickSelectedRow] = useState('');
  // const [csvData, setCsvData] = useState([]);
  const [csvData, setCSVData] = useState([]);

  !onclickSelectedRow === null &&
    setTimeout(() => {
      setOnclickSelectedRow(null);
    }, 5000);

  const shipmentStatusColor = shipmentStatus => {
    if (shipmentStatus === 'Saved') {
      return { color: '#448aff', backgroundColor: '#bbdefb' };
    }

    if (shipmentStatus.toUpperCase() === 'HOLD') {
      return { color: '#d50000', backgroundColor: '#ffebee' };
    }
    if (shipmentStatus === 'Booked') {
      return { color: '#9c27b0', backgroundColor: '#f3e5f5' };
    }
    if (shipmentStatus === 'Delivered') {
      return { color: '#1b5e20', backgroundColor: '#c8e6c9' };
    }
    if (shipmentStatus === 'InTransit') {
      return { color: '#e65100', backgroundColor: '#ffe0b2' };
    }
  };

  const rando = max => Math.floor(Math.random() * max);
  const words = ['Paper', 'Rock', 'Scissors'];
  const rawData = [];

  for (let i = 0; i < 100; i++)
    rawData.push({ id: rando(300), name: words[i % words.length] });
  // const [, setForceUpdate] = useState(0);

  const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () =>
  //     componentRef?.current?.childNodes[2]?.childNodes[0]?.children[1]
  //       ?.children[0]
  // });
  const handlePrint = useReactToPrint({
    content: () => {
      const tableToPrint =
        componentRef?.current?.childNodes[2]?.childNodes[0]?.children[1]?.children[0].cloneNode(
          true
        ); // Clone the element you want to print

      if (tableToPrint) {
        // Remove the first column (header and data cells) from the cloned table
        const headerRow = tableToPrint.querySelector('thead tr');
        if (headerRow) {
          headerRow.removeChild(headerRow.firstElementChild);
          headerRow.removeChild(headerRow.firstElementChild);
          headerRow.removeChild(headerRow.firstElementChild);
        }

        const dataRows = tableToPrint.querySelectorAll('tbody tr');
        dataRows.forEach(row => {
          if (row.children.length >= 2) {
            row.removeChild(row.firstElementChild);
            row.removeChild(row.firstElementChild);
            row.removeChild(row.firstElementChild);
          }
        });

        // You can now print the modified table
        return tableToPrint;
      } else {
        return null; // Handle the case where the element is not found
      }
    }
  });

  const handleCsvImport = (formattedData, AllCsvData) => {
    formattedData &&
      history.push('import-csv', {
        csvData: formattedData,
        AllCsvData: AllCsvData
      });
  };
  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file?.type.includes('csv') || file?.type.includes('ms-excel')) {
      parseCsvData(file);
    } else ErrorToast('Select CSV File To Upload');
  };

  const parseCsvData = file => {
    var formattedData;
    Papa.parse(file, {
      complete: result => {
        const AllCsvData = result.data;
        const columns = result.data[0];
        const firstRowData = result?.data[1];

        formattedData = columns.map((column, index) => ({
          columnName: column,
          firstValue: firstRowData && firstRowData[index]
        }));

        setCSVData(formattedData);
        handleCsvImport(formattedData, AllCsvData);
      }
    });
  };

  const fileInputRef = useRef(null);

  const handlePickupOpen = (rowData, row, dialogName) => {
    setCheckLocIndex(row.index);
    setShipmentsLoader(true);
    // event.stopPropagation();
    if (rowData.shipmentType === 'TL') {
      if (rowData.quoteId !== 0 && dialogName === 'Get Quote') {
        GetQuote(rowData.quoteId)
          .then(
            res =>
              res.status === 200 &&
              history.push({
                pathname: '/bookshipmentTL',
                state: {
                  pickupRowData: JSON.stringify(rowData),
                  disptachOpen: 'Get Quote',
                  carrierApiNames: TlCarrierApiNames,
                  apiData: res.data.data
                }
              })
          )
          .catch(err => console.log(err, 'GetQuoteErr------>'));
      }
      if (dialogName === 'Get Quote') {
        // history.push({
        //   pathname: '/bookshipmentTL',
        //   state: {
        //     pickupRowData: JSON.stringify(rowData),
        //     disptachOpen: 'Get Quote',
        //     carrierApiNames: TlCarrierApiNames
        //   }
        // });
        CheckLocation(rowData.shipmentId)
          .then(res => {
            if (res.status === 200 && res.data.data.valid) {
              setShipmentsLoader(false);

              history.push({
                pathname: '/bookshipmentTL',
                state: {
                  pickupRowData: JSON.stringify(rowData),
                  disptachOpen: 'Get Quote',
                  carrierApiNames: ParcelCarrierApiNames,
                  paymentType: res.data.data.paymentType,
                  locationId: res.data.data.locationId
                }
              });
            } else if (res.status === 200 && !res.data.data.valid) {
              setShipmentsLoader(false);

              ErrorToast(
                'No location Mapped against this shipment, please assign or create location!'
              );
              setTimeout(() => {
                history.push({
                  pathname: '/view-locations',
                  search: `?shipmentId=${rowData.shipmentId}`,
                  state: {
                    shipmentId: rowData.shipmentId
                  }
                });
              }, 3000);
            }
          })
          .catch(err => {
            setShipmentsLoader(false);

            ErrorToast(err?.message);
          });
      }

      if (dialogName === 'PickUp') {
        history.push({
          pathname: '/bookshipmentTL',
          state: {
            pickupRowData: JSON.stringify(rowData),
            disptachOpen: 'PickUp',
            carrierApiNames: TlCarrierApiNames
            // // : rowData
          }
        });
      }

      if (dialogName === 'Dispatch') {
        // event.stopPropagation();

        if (rowData?.shipmentStatus.toUpperCase() === 'HOLD') {
          GetShipmentActivity(rowData.shipmentId)
            .then(res => {
              res.status === 200 && res.data.data[0].acrionDetail
                ? ErrorToast(
                    `Shipment cannot be Disptached due to the reason, '${res.data.data[0].acrionDetail}'.`
                  )
                : ErrorToast(
                    'Shipment current status is HOLD, cannot be Disptached!.'
                  );
              setShipmentsLoader(false);
            })
            .catch(err =>
              ErrorToast(
                'Shipment current status is HOLD, cannot be Disptached!'
              )
            );
        } else {
          history.push({
            pathname: '/dispatchshipmentTL',
            state: {
              pickupRowData: JSON.stringify(rowData),
              disptachOpen: 'Dispatch',
              carrierApiNames: TlCarrierApiNames
            }
          });
        }
      }
    } else if (rowData.shipmentType === 'Parcel') {
      if (dialogName === 'Get Quote') {
        // history.push({
        //   pathname: '/bookshipmentParcel',
        //   state: {
        //     pickupRowData: JSON.stringify(rowData),
        //     disptachOpen: 'Get Quote',
        //     carrierApiNames: ParcelCarrierApiNames
        //     // // : rowData
        //   }
        // });
        CheckLocation(rowData.shipmentId)
          .then(res => {
            if (res.status === 200 && res.data.data.valid) {
              setShipmentsLoader(false);
              history.push({
                pathname: '/bookshipmentParcel',
                state: {
                  pickupRowData: JSON.stringify(rowData),
                  disptachOpen: 'Get Quote',
                  carrierApiNames: ParcelCarrierApiNames,
                  paymentType: res.data.data.paymentType,
                  locationId: res.data.data.locationId
                }
              });
            } else if (res.status === 200 && !res.data.data.valid) {
              setShipmentsLoader(false);

              ErrorToast(
                'No location Mapped against this shipment, please assign or create location!'
              );
              setShipmentsLoader(false);

              setTimeout(() => {
                history.push({
                  pathname: '/view-locations',
                  search: `?shipmentId=${rowData.shipmentId}`,
                  state: {
                    shipmentId: rowData.shipmentId
                  }
                });
              }, 3000);
            }
          })
          .catch(err => {
            setShipmentsLoader(false);

            ErrorToast(err?.message);
          });
      }

      if (dialogName === 'PickUp') {
        history.push({
          pathname: '/bookshipmentParcel',
          state: {
            pickupRowData: JSON.stringify(rowData),
            disptachOpen: 'PickUp',
            carrierApiNames: ParcelCarrierApiNames
          }
        });
      }

      if (dialogName === 'Dispatch') {
        // event.stopPropagation();
        if (rowData?.shipmentStatus.toUpperCase() === 'HOLD') {
          GetShipmentActivity(rowData.shipmentId)
            .then(res => {
              res.status === 200 && res.data.data[0].acrionDetail
                ? ErrorToast(
                    `Shipment cannot be Disptached due to the reason, '${res.data.data[0].acrionDetail}'.`
                  )
                : ErrorToast(
                    'Shipment current status is HOLD, cannot be Disptached!.'
                  );
              setShipmentsLoader(false);
            })
            .catch(err =>
              ErrorToast(
                'Shipment current status is HOLD, cannot be Disptached!'
              )
            );
        } else {
          history.push({
            pathname: '/dispatchshipmentParcel',
            state: {
              pickupRowData: JSON.stringify(rowData),
              disptachOpen: 'Dispatch',
              carrierApiNames: ParcelCarrierApiNames
              // assignedProNumber: res.data.data
            }
          });
          // GetNextProNumber(AgencyID, rowData.carrierId)
          //   .then(
          //     res =>
          //       res.status === 200 &&
          //       history.push({
          //         pathname: '/bookshipmentParcel',
          //         state: {
          //           pickupRowData: JSON.stringify(rowData),
          //           disptachOpen: 'Dispatch',
          //           carrierApiNames: ParcelCarrierApiNames,
          //           assignedProNumber: res.data.data
          //         }
          //       })
          //   )
          //   .catch(err =>
          //     history.push({
          //       pathname: '/bookshipmentParcel',
          //       state: {
          //         pickupRowData: JSON.stringify(rowData),
          //         disptachOpen: 'Dispatch',
          //         carrierApiNames: ParcelCarrierApiNames,
          //         assignedProNumber: ''
          //       }
          //     })
          //   );
        }
      }
    } else {
      if (dialogName === 'Get Quote') {
        // history.push({
        //   pathname: '/bookshipmentLTL',
        //   state: {
        //     pickupRowData: JSON.stringify(rowData),
        //     disptachOpen: 'Get Quote',
        //     carrierApiNames: carrierApiNames
        //     // // : rowData
        //   }
        // });
        CheckLocation(rowData.shipmentId)
          .then(res => {
            if (res.status === 200 && res.data.data.valid) {
              setShipmentsLoader(false);

              history.push({
                pathname: '/bookshipmentLTL',
                state: {
                  pickupRowData: JSON.stringify(rowData),
                  disptachOpen: 'Get Quote',
                  carrierApiNames: carrierApiNames,
                  paymentType: res.data.data.paymentType,
                  locationId: res.data.data.locationId
                }
              });
            } else if (res.status === 200 && !res.data.data.valid) {
              setShipmentsLoader(false);

              ErrorToast(
                'No location Mapped against this shipment, please assign or create location!'
              );
              setTimeout(() => {
                history.push({
                  pathname: '/view-locations',
                  search: `?shipmentId=${rowData.shipmentId}`,
                  state: {
                    shipmentId: rowData.shipmentId
                  }
                });
              }, 3000);
            }
          })
          .catch(err => {
            setShipmentsLoader(false);

            ErrorToast(err?.message);
          });
      }

      if (dialogName === 'PickUp') {
        history.push({
          pathname: '/bookshipmentLTL',
          state: {
            pickupRowData: JSON.stringify(rowData),
            disptachOpen: 'PickUp',
            carrierApiNames: carrierApiNames
          }
        });
      }

      if (dialogName === 'Dispatch') {
        // event.stopPropagation();
        if (rowData?.shipmentStatus.toUpperCase() === 'HOLD') {
          GetShipmentActivity(rowData.shipmentId)
            .then(res => {
              res.status === 200 && res.data.data[0].acrionDetail
                ? ErrorToast(
                    `Shipment cannot be Disptached due to the reason, '${res.data.data[0].acrionDetail}'.`
                  )
                : ErrorToast(
                    'Shipment current status is HOLD, cannot be Disptached!.'
                  );
              setShipmentsLoader(false);
            })
            .catch(err =>
              ErrorToast(
                'Shipment current status is HOLD, cannot be Disptached!'
              )
            );
        } else {
          // GetNextProNumber(AgencyID, rowData.carrierId)
          //   .then(
          //     res =>
          //       res.status === 200 &&
          history.push({
            pathname: '/dispatchshipmentLTL',
            state: {
              pickupRowData: JSON.stringify(rowData),
              disptachOpen: 'Dispatch',
              carrierApiNames: carrierApiNames,
              assignedProNumber: ''
            }
          });
          // )
          // .catch(err =>
          //   history.push({
          //     pathname: '/bookshipmentLTL',
          //     state: {
          //       pickupRowData: JSON.stringify(rowData),
          //       disptachOpen: 'Dispatch',
          //       carrierApiNames: carrierApiNames,
          //       assignedProNumber: ''
          //     }
          //   })
          // );
        }
      }
    }
  };

  const markAsDeliverRequest = () => {
    const DispatchObj = {
      shipmentId: markAsDeliverRowData.shipmentId
        ? markAsDeliverRowData.shipmentId
        : 0,
      agencyId: AgencyID ? AgencyID : 0,
      type: 1,
      proNumber: markAsDeliverRowData.proNumber
        ? markAsDeliverRowData.proNumber
        : '',
      bolNumber: markAsDeliverRowData.bolNumber
        ? markAsDeliverRowData.bolNumber
        : '',
      refNumber: '',
      pickupNumber: markAsDeliverRowData.pickupNumber
        ? markAsDeliverRowData.pickupNumber
        : '',
      carrierId: markAsDeliverRowData.carrierId
        ? markAsDeliverRowData.carrierId
        : 0,
      carrierName: markAsDeliverRowData.carrierName
        ? markAsDeliverRowData.carrierName
        : '',
      driverName: '',
      vehicleNumber: '',
      phoneNumber: ''
    };
    handleClose();
    DispatchShipment(DispatchObj)
      .then(res => {
        res.status === 200 && handleGetSynchDataRecords();
        successToast('Marked as Deliverd');
        setValue(4);
      })
      .catch(err => ErrorToast(err?.message));
  };
  const handleMarkDeliver = rowData => {
    // const CallDispatchShipmentApi = () => {
    // setPickUpLoader(true);
    setOpenHoldShipmentDialog(true);
    setMarkDeliverDialog(true);
    setMarkAsDeliverRowData(rowData);

    // };
  };
  const handleDate = (renderedCellValue, format) => {
    const date = new Date(renderedCellValue);
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const lablesPrintingOptions = {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric'
    };
    if (format === 'labels') {
      return date.toLocaleDateString(undefined, lablesPrintingOptions);
    } else return date.toLocaleDateString(undefined, options);
  };
  const mtransitTabColumns = useMemo(
    () => [
      {
        accessorKey: 'mrt-row-actions',
        id: 'mrt-row-actions',
        header: 'mrt-row-actions'
      },
      {
        accessorKey: 'mrt-row-expand',
        id: 'mrt-row-expand',
        header: 'mrt-row-expand'
      },
      {
        accessorKey: 'mrt-row-select',
        id: 'mrt-row-select',
        header: 'mrt-row-select'
      },
      {
        // accessorFn: row => `${row.carrierName} ${row.carrierLogo}`,
        accessorKey: 'carrierName',
        id: 'carrierName',
        header: 'Carrier Name',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.carrierName
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            {renderedCellValue && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AvatarGroup sx={{}} spacing='small'>
                  <Avatar
                    sx={
                      {
                        // bgcolor: 'ButtonFace',
                        // objectFit: 'contain',
                        // mr: 1
                      }
                    }
                    imgProps={{
                      style: {
                        // height: 25
                      }
                    }}
                    // src={base_logo_url + carrier_logos/${items.logo}`}
                    src={
                      row.original.carrierLogo
                        ? base_logo_url + row.original.carrierLogo
                        : NoLogo
                    }
                  />
                </AvatarGroup>
                <span>{renderedCellValue}</span>
                <br />
              </Box>
            )}
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.soNumber}|${row.clientPONumber}`,
        accessorKey: 'proNumber',
        id: 'proNumber',
        header: 'Pro Number',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.proNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.soNumber}|${row.clientPONumber}`,
        accessorKey: 'bolNumber',
        id: 'bolNumber',
        header: 'Bol Number',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.bolNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.soNumber}|${row.clientPONumber}`,
        accessorKey: 'soNumber',
        id: 'soNumber',
        header: 'SO#',
        size: 10,
        filterFn: (row, colID, filterValue) => {
          return row.original.soNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box>
              <span>{renderedCellValue}</span>
              {/* <br /> */}
            </Box>
          </>
        )
      },
      {
        //   // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'shipmentStatus',
        id: 'shipmentStatus',
        header: 'Shipment Status',
        size: 50,
        // filterFn: (row, colID, filterValue) => {
        //   return row?.original?.shipmentStatus
        //     .toLowerCase()
        //     .includes(filterValue?.toLowerCase());
        // },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box>
              <Chip
                sx={shipmentStatusColor(row?.original?.shipmentStatus)}
                label={renderedCellValue}
              />
            </Box>
          </>
        )
      },

      {
        // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'shipmentType',
        id: 'shipmentType',
        header: 'Shipment Type',
        size: 70,
        filterFn: (row, colID, filterValue) => {
          return row.original.shipmentType
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Chip
                sx={{ backgroundColor: '#E1F5FE', color: '#01579B' }}
                label={renderedCellValue}
              />
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'quoteNumber',
        id: 'quoteNumber',
        header: 'Quote Number',
        size: 70,
        filterFn: (row, colID, filterValue) => {
          return row.original.quoteNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {renderedCellValue}
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.soNumber}|${row.clientPONumber}`,
        accessorKey: 'deliveryDate',
        id: 'deliveryDate',
        header: 'Promised Date',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.deliveryDate
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        accessorKey: 'actualPickupDate',
        id: 'actualPickupDate',
        header: 'Ship Date',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.actualPickupDate
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },

        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{handleDate(renderedCellValue)}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        accessorKey: 'actualDeliveryDate',
        id: 'actualDeliveryDate',
        header: (
          <div style={{ flexWrap: 'nowrap' }}>Carrier’s Delivery Date</div>
        ),
        size: 10,
        Cell: ({ renderedCellValue, row }) => {
          if (new Date(renderedCellValue) < new Date()) {
            setActualDeliveryDate(true);
            return (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                    // borderRadius: '50%',
                    // backgroundColor: 'yellow'
                  }}
                >
                  <span>{handleDate(renderedCellValue)}</span>
                  <br />
                </Box>
              </>
            );
          } else {
            return (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <span>{handleDate(renderedCellValue)}</span>
                  <br />
                </Box>
              </>
            );
          }
        }
      },

      {
        accessorKey: 'originCompany',
        id: 'originCompany', //id is still required when using accessorFn instead of accessorKey
        header: 'Origin Company',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.originCompany
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'originZipCode',
        id: 'originZipCode', //id is still required when using accessorFn instead of accessorKey
        header: 'Origin Zip',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.originZipCode
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'originCity',
        id: 'originCity', //id is still required when using accessorFn instead of accessorKey
        header: 'Origin City',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.originCity
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },

      {
        accessorKey: 'originState',
        id: 'originState', //id is still required when using accessorFn instead of accessorKey
        header: 'Origin State',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.originState
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'destinationCompany',
        id: 'destinationCompany', //id is still required when using accessorFn instead of accessorKey
        header: 'Destination Company',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.destinationCompany
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'destinationZipCode',
        id: 'destinationZipCode', //id is still required when using accessorFn instead of accessorKey
        header: 'Destination Zip',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.destinationZipCode
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'destinationCity',
        id: 'destinationCity', //id is still required when using accessorFn instead of accessorKey
        header: 'Destination City',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.destinationCity
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'destinationState',
        id: 'destinationState', //id is still required when using accessorFn instead of accessorKey
        header: 'Destination State',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.destinationState
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'billToCompany',
        id: 'billToCompany', //id is still required when using accessorFn instead of accessorKey
        header: 'Bill To Company ',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.billToCompany
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },

      {
        // accessorFn: row => `${row.shipmentCharges}`,
        accessorKey: 'shipmentCharges',
        id: 'shipmentCharges',
        header: 'Carrier Total Rate',
        size: 50,
        // filterFn: (row, colID, filterValue) => {
        //   return row.original.shipmentCharges
        //     .toLowerCase()
        //     .includes(filterValue.toLowerCase());
        // },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>${parseFloat(renderedCellValue).toFixed(2)}</span>
              <br />
            </Box>
          </>
        )
      },

      {
        // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'freightTerms',
        id: 'freightTerms',
        header: 'Freight Terms',
        size: 70,
        filterFn: (row, colID, filterValue) => {
          return row.original.freightTerms
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {renderedCellValue === 'C' ? (
                <Chip
                  sx={{ backgroundColor: '#E8F5E9', color: '#1B5E20' }}
                  label={renderedCellValue}
                />
              ) : renderedCellValue === 'PP' ? (
                <Chip
                  sx={{ backgroundColor: '#E1F5FE', color: '#01579B' }}
                  label={renderedCellValue}
                />
              ) : renderedCellValue === 'P' ? (
                <Chip
                  sx={{ backgroundColor: '#FCE4EC', color: '#B71C1C' }}
                  label={renderedCellValue}
                />
              ) : (
                <Chip
                  sx={{ backgroundColor: '#00000014', color: '#00000099' }}
                  label={renderedCellValue}
                />
              )}
            </Box>
          </>
        )
      },

      {
        // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'paymentTerms',
        id: 'paymentTerms',
        header: 'Payment Terms',
        size: 70,
        filterFn: (row, colID, filterValue) => {
          return row.original.paymentTerms
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },

      {
        // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'shippingNotes',
        id: 'shippingNotes',
        header: 'Shipping Notes',
        size: 10,
        filterFn: (row, colID, filterValue) => {
          return row.original.shippingNotes
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'locationName',
        id: 'locationName',
        header: 'Location Name',
        size: 10,
        filterFn: (row, colID, filterValue) => {
          return row.original.locationName
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.soNumber}|${row.clientPONumber}`,
        accessorKey: 'clientPONumber',
        id: 'clientPONumber',
        header: 'PO#',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.clientPONumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.details[0].pallets}`,
        accessorKey: 'customerId',
        id: 'customerId',
        header: 'Customer ID',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.customerId
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        accessorFn: row => `${row.details[0]?.packagingType}`,
        accessorKey: 'details[0].packagingType',
        id: 'details[0].packagingType',
        header: 'Product Type',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.details[0]?.packagingType
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },

      {
        accessorFn: row => `${row.details[0].pallets}`,
        accessorKey: 'details[0].pallets',
        id: 'details[0].pallets',
        header: 'Pallets',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.details[0].pallets
            .toString()
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },

      {
        accessorFn: row => `${row.details[0].netWeight}`,
        accessorKey: 'details[0].netWeight',
        id: 'details[0].netWeight',
        header: 'Weight',
        size: 50,
        // filterFn: (row, colID, filterValue) => {
        //   return row.original.details[0].netWeight
        //     .toLowerCase()
        //     .includes(filterValue.toLowerCase());
        // },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue} lbs</span>
              <br />
            </Box>
          </>
        )
      },

      {
        accessorFn: row => `${row.details[0].freightClass}`,
        accessorKey: 'details[0].freightClass',
        id: 'details[0].freightClass',
        header: 'Freight Class',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.details[0].freightClass
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        accessorFn: row => `${row.details[0].nmfc}`,
        accessorKey: 'details[0].nmfc',
        id: 'details[0].nmfc',
        header: 'NMFC #',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.details[0].nmfc
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      }
    ],
    []
  );
  // tabs state
  const handleFilteredTableData = () => {
    if (value === 0) {
      const FilteredShipments = viewShipmentsData.filter(
        items =>
          items.shipmentStatus.toLowerCase() === 'saved' ||
          items.shipmentStatus.toLowerCase() === 'unbook'
      );
      return FilteredShipments;
    } else if (value === 1) {
      const FilteredShipments = viewShipmentsData.filter(
        items => items.shipmentStatus.toLowerCase() === 'tendered'
      );
      return FilteredShipments;
    } else if (value === 2) {
      const FilteredShipments = viewShipmentsData.filter(
        items =>
          items.shipmentStatus.toLowerCase() === 'booked' ||
          items.shipmentStatus.toLowerCase() === 'hold'
      );
      // if (getBookedLogosCall) {
      //   let tempArr = [];
      //   let duplicateArr = [...FilteredShipments];

      //   Promise.all(duplicateArr.map(item => GetLogoBase64(item.carrierLogo)))
      //     .then(responses => {
      //       responses.forEach((res, index) => {
      //         duplicateArr[index].carrierLogo = res.data;
      //       });
      //       tempArr = duplicateArr;
      //       console.log(tempArr, 'tempArr');
      //       setGetBookedLogosCall(false);
      //       // setBookedShipments(tempArr);
      //     })
      //     .catch(err => console.log(err, 'GetLogoBase64Error------>>>>'));
      // }

      return FilteredShipments;
    } else if (value === 3) {
      const FilteredShipments = viewShipmentsData.filter(
        items => items.shipmentStatus.toLowerCase() === 'intransit'
      );
      // if (getTransitLogosCall) {
      //   let tempArr = [];
      //   let duplicateArr = [...FilteredShipments];

      //   Promise.all(duplicateArr.map(item => GetLogoBase64(item.carrierLogo)))
      //     .then(responses => {
      //       responses.forEach((res, index) => {
      //         duplicateArr[index].carrierLogo = res.data;
      //       });
      //       tempArr = duplicateArr;
      //       console.log(tempArr, 'tempArr');
      //       setGetTransitGetLogosCall(false);
      //       // setBookedShipments(tempArr);
      //     })
      //     .catch(err => console.log(err, 'GetLogoBase64Error------>>>>'));
      // }
      return FilteredShipments;
    } else {
      const FilteredShipments = viewShipmentsData.filter(
        items => items.shipmentStatus.toLowerCase() === 'delivered'
      );
      // if (getDeliveredLogosCall) {
      //   let tempArr = [];
      //   let duplicateArr = [...FilteredShipments];

      //   Promise.all(duplicateArr.map(item => GetLogoBase64(item.carrierLogo)))
      //     .then(responses => {
      //       responses.forEach((res, index) => {
      //         duplicateArr[index].carrierLogo = res.data;
      //       });
      //       tempArr = duplicateArr;
      //       console.log(tempArr, 'tempArr');
      //       setGetDeliveredLogosCall(false);
      //       // setBookedShipments(tempArr);
      //     })
      //     .catch(err => console.log(err, 'GetLogoBase64Error------>>>>'));
      // }
      return FilteredShipments;
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }
  const [value, setValue] = useState(0);
  const [tableCol, setTableCol] = useState(mtransitTabColumns);

  useEffect(() => {
    handleFilteredTableData();
  }, [value]);

  const handleTabChange = (event, newValue) => {
    setExpandedDetail(true);
    setValue(newValue);
    const filteredCol = mtransitTabColumns.filter(colNames => {
      return !colNames.accessorKey.includes('mrt');
    });
    if (newValue === 0 || newValue === 1) {
      const col = filteredCol.filter(col => {
        return (
          col.accessorKey !== 'carrierName' &&
          col.accessorKey !== 'proNumber' &&
          col.accessorKey !== 'bolNumber' &&
          col.accessorKey !== 'shipmentCharges' &&
          col.accessorKey !== 'actualDeliveryDate' &&
          col.accessorKey !== 'actualPickupDate' &&
          col.accessorKey !== 'quoteNumber'
        );
      });
      setTableCol(col);
    }

    if (newValue === 2) {
      const col = filteredCol.filter(col => {
        return (
          col.accessorKey !== 'proNumber' && col.accessorKey !== 'bolNumber'
        );
      });

      setTableCol(col);
    }
    if (newValue === 3 || newValue === 4) {
      setTableCol(mtransitTabColumns);
    }
    // if(newValue === 0) {
    //   mtransitTabColumns.filter()
    // }
    // setToggleDetialPanel(false);
    // setShowAddiTopBar(false);
    // setOnclickSelectedRow('');
    // window.history.replaceState({}, document.title);
  };

  useEffect(() => {
    const filteredCol = mtransitTabColumns.filter(colNames => {
      return !colNames.accessorKey.includes('mrt');
    });
    if (value === 0 || value === 1) {
      const col = filteredCol.filter(col => {
        return (
          col.accessorKey !== 'carrierName' &&
          col.accessorKey !== 'proNumber' &&
          col.accessorKey !== 'bolNumber' &&
          col.accessorKey !== 'shipmentCharges' &&
          col.accessorKey !== 'actualDeliveryDate' &&
          col.accessorKey !== 'actualDeliveryDate' &&
          col.accessorKey !== 'quoteNumber'
        );
      });
      setTableCol(col);
    } else if (value === 2) {
      const col = filteredCol.filter(col => {
        return (
          col.accessorKey !== 'proNumber' && col.accessorKey !== 'bolNumber'
        );
      });
      setTableCol(col);
    } else {
      setTableCol(filteredCol);
    }
  }, [value]);
  // const TableTitle = () => {
  //   console.log(value, 'valuevalue');
  //   if (value === 0) {
  //     return msavedTabColumns;
  //   } else if (value === 1) {
  //     return mbookedTabColumns;
  //   } else {
  //     return mtransitTabColumns;
  //   }
  // };

  // useEffect(() => {
  //   TableTitle();
  // }, [value]);

  const [openHoldShipmentDialog, setOpenHoldShipmentDialog] = useState(false);
  const [openPrintPDfDialog, setOpenPrintPDfDialog] = useState(false);
  const [numberOfPrints, setNumberOfPrints] = useState(2);
  const [numberOfPrintsColmnVise, setNumberOfPrintsColmnVise] = useState(1);
  const [openPrintPDfDialogData, setOpenPrintPDfDialogData] = useState('');
  const [shipmentDialogName, setShipmentDialogName] = useState('');
  const [rowData, setrowData] = useState('');

  const handleClickOpen = (dialogName, row, rowData) => {
    // rowData.stopPropagation();
    setOpenHoldShipmentDialog(true);
    setrowData(row);
    if (dialogName === 'Hold') {
      setShipmentDialogName('Hold Shipment');
    } else setShipmentDialogName('Un-Book Shipment');
  };

  const handleClose = () => {
    setOpenHoldShipmentDialog(false);
    setMarkDeliverDialog(false);
    setHoldShipmentRemarks('');
  };

  const handleSuccessTrackShip = apiRes => {
    setTrackShipmentData(apiRes);
    history.push({
      pathname: '/track-shipment',
      state: { trackShipmentData: apiRes }
    });
  };

  const handleSuccessUnBookedShipment = (ApiRes, holdShipmentRemarks) => {
    handleClose();
    successToast(
      `Shipment has been UnBooked with remarks, "${holdShipmentRemarks}"`
    );
    handleGetSynchDataRecords();
    handleTabChange('UnBooked', 0);
    setHoldShipmentRemarks('');
  };

  const handleErrorUnBookedShipment = ApiError => {
    handleClose();
    ErrorToast('Something Went Wrong!');
  };

  const handleUnBookShipmentRequest = (event, selectedRowData) => {
    // event.stopPropagation();

    const UnBookShipmentObj = {
      shipmentId: rowData?.shipmentId ? rowData?.shipmentId : 0,
      remarks: holdShipmentRemarks,
      action: 0
    };
    UnbookShipment(UnBookShipmentObj)
      .then(
        res =>
          res.status === 200 &&
          handleSuccessUnBookedShipment(res, holdShipmentRemarks)
      )
      .catch(err => handleErrorUnBookedShipment(err));
  };

  // hold- shipment
  const handleSuccessHoldShipment = (ApiRes, holdShipmentRemarks) => {
    setStatusLoader(false);
    handleClose();
    handleGetSynchDataRecords();
    successToast(
      `Shipment has been Hold with remarks, "${holdShipmentRemarks}".`
    );
    setHoldShipmentRemarks('');
  };

  const handleErrorHoldShipment = ApiError => {
    setStatusLoader(false);
    handleClose();
    ErrorToast('Something Went Wrong!');
  };

  const handleHoldShipmentRequest = (event, selectedRowData) => {
    // setStatusLoader(true);
    // event.stopPropagation();
    const HoldShipmentObj = {
      shipmentId: rowData?.shipmentId ? rowData?.shipmentId : 0,
      remarks: holdShipmentRemarks,
      action: 0
    };
    HoldShipment(HoldShipmentObj)
      .then(
        res =>
          res.status === 200 &&
          handleSuccessHoldShipment(res, holdShipmentRemarks)
      )
      .catch(err => handleErrorHoldShipment(err));
  };

  // Un-Hold shipment
  const handleSuccessUnHoldShipment = ApiRes => {
    handleGetSynchDataRecords();
    successToast('Shipment has been UnHold!');
  };

  const handleUnHoldShipmentRequest = selectedRowData => {
    // event.stopPropagation();
    const UnHoldShipmentObj = {
      shipmentId: selectedRowData?.shipmentId ? selectedRowData?.shipmentId : 0,
      remarks: '',
      action: 0
    };
    UnHoldShipment(UnHoldShipmentObj)
      .then(res => res.status === 200 && handleSuccessUnHoldShipment(res))
      .catch(err => console.log(err, 'UnHoldShipmentErr'));
  };

  // track shipment
  const handleTrackShipmentRequest = (selectedRowData, event) => {
    // event.stopPropagation();
    const obj = {
      carrierInfo: {
        id: selectedRowData?.carrierId ? selectedRowData.carrierId : 0,
        name: selectedRowData?.carrierName ? selectedRowData.carrierName : '',
        logo: ''
      },
      proNumber: selectedRowData?.proNumber,
      shipmentId: selectedRowData?.shipmentId,
      locationId: selectedRowData?.locationId,
      shipmentType: selectedRowData?.shipmentType,
      agencyId: AgencyID ? AgencyID : 0
    };

    TrackShipmentRequest(obj)
      .then(res => res.status === 200 && handleSuccessTrackShip(res.data))
      .catch(err => ErrorToast(err?.message));
  };

  // Save the column filters to storage whenever it changes
  // useEffect(() => {
  //   localStorage.setItem('columnFilters', JSON.stringify(columnFilters));
  // }, [columnFilters]);

  useEffect(() => {
    if (PreviouProps?.props?.selectedTab === 'Booked') {
      setValue(2);
      setTableCol(mtransitTabColumns);
    }
    if (PreviouProps?.props?.selectedTab === 'InTransit') {
      setValue(3);
      setTableCol(mtransitTabColumns);
    }
    if (PreviouProps?.props?.selectedTab === 'Delivered') {
      setValue(4);
      setTableCol(mtransitTabColumns);
    }
  }, []);
  const GetSuccessUserDetails = apiRes => {
    setColumnOrder(JSON.parse(apiRes.data.userSettings.SHIPMENT_GRID_ORDER));

    setColumnVisibility(
      JSON.parse(apiRes.data.userSettings.SHIPMENT_GRID_COLUMNS)
    );
  };
  const FetchCurrentUserDetails = () => {
    GetAagencyUserDetails(UserLoggedInEmail)
      .then(
        res => res.data.statusCode === 200 && GetSuccessUserDetails(res.data)
      )
      .catch(err => console.log(err));
  };

  const handleViewShipments = row => {
    setExpandedDetail(false);
    return (
      <Box>
        <ViewShipment
          rowData={row.original}
          disptachOpen={'View'}
          handleTrackShipmentRequest={handleTrackShipmentRequest}
        />
      </Box>
    );
  };
  const isFirstRender = useRef(true);

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState(
    tableCol.map(c => c.accessorKey) //array of column ids
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // sessionStorage.setItem(
    //   'mrt_columnOrder_table_1',
    //   JSON.stringify(columnOrder)
    // );

    const userSettingsObj = {
      loginId: UserLoggedInEmail,
      type: 'SHIPMENT_GRID_ORDER',
      value: JSON.stringify(columnOrder)
    };
    SaveUserSettings(userSettingsObj)
      .then(res => res.status === 200 && console.log(res, 'resres'))
      .catch(err => console.log(err, 'err'));
  }, [columnOrder, UserLoggedInEmail, SaveUserSettings]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // sessionStorage.setItem(
    //   'mrt_columnVisibility_table_1',
    //   JSON.stringify(columnVisibility)
    // );
    const userSettingsObj2 = {
      loginId: UserLoggedInEmail,
      type: 'SHIPMENT_GRID_COLUMNS',
      value: JSON.stringify(columnVisibility)
    };
    SaveUserSettings(userSettingsObj2)
      .then(res => res.status === 200 && console.log(res, 'resres'))
      .catch(err => console.log(err, 'err'));
  }, [columnVisibility, UserLoggedInEmail, SaveUserSettings]);

  const generatePDF = async () => {
    const element = document.getElementById('pdf-content');

    const pageSize = {
      width: 1150,
      height: 1150
    };

    const pdfOptions = {
      // margin: 1,
      filename: `${openPrintPDfDialogData.carrierName}_label.pdf`,
      // filename: 'label.pdf',
      // image: { type: 'jpeg', quality: 1.0 },
      html2canvas: {
        scale: 2, // Adjust scale as needed
        logging: true // Enable logging if needed
      },
      jsPDF: {
        unit: 'pt',
        format: [pageSize.width, pageSize.height],
        // format: 'a4',
        orientation: 'portrait'
      }
    };

    await html2pdf(element, pdfOptions);
  };
  const printData = rowData => {
    if (rowData.shipmentType === 'Parcel') {
      window.open(rowData.dispatchInput1, '_blank');
    } else {
      setOpenPrintPDfDialog(true);
      setOpenPrintPDfDialogData(rowData);
    }
  };

  const handlePDFPrintClose = () => {
    setOpenPrintPDfDialog(false);
    setNumberOfPrintsColmnVise(1);
  };

  const handleDateFilterModalClose = () => {
    setDateFilterModal(false);
  };

  const handleCloseAddUpdateLocation = () => {
    setAddUpdateLocationDialog(false);
    setUserLocations([]);
    setAddUpdateLocationID('');
    setAddUpdateLocationShipID('');
  };
  const handleAdminLocationChange = (event, newValue) => {
    setUserLocations(newValue);
    setAddUpdateLocationID(newValue?.id);
    // setValidationErrors({
    //   ...validationErrors,
    //   locationsError: null
    // });
  };
  const handleOpenAddUpdateLocReq = () => {
    const locatioObj = {
      shipmentId: addUpdateLocationShipID,
      locationId: addUpdateLocationID
    };
    UpdateShipmentLocation(locatioObj)
      .then(res => {
        res.status === 200 && handleCloseAddUpdateLocation();
        handleGetSynchDataRecords();
        successToast('Location Updated Successfully!');
      })
      .catch(err => {
        handleCloseAddUpdateLocation();
        ErrorToast(err?.message);
      });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='md'
        minWidth='xs'
        sx={{ minWidth: 100, minHeight: '20vh' }}
        open={openPrintPDfDialog}
        onClose={handlePDFPrintClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{openPrintPDfDialogData.carrierName} Lables</div>
            <div>
              <CloseIcon
                sx={{ cursor: 'pointer' }}
                onClick={handlePDFPrintClose}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <div
              id='pdf-content'
              style={{
                minWidth: '100%',
                minHeight: '100%',
                padding: 10
              }}
            >
              {[...Array(numberOfPrintsColmnVise)].map((items, index) => (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      border: '0.5px solid rgb(225 225 225)',
                      borderRadius: 5,
                      padding: 10,
                      marginTop: 10
                    }}
                  >
                    {openPrintPDfDialogData &&
                      [...Array(numberOfPrints)].map((_, index) => (
                        <div
                          key={index}
                          // id={`pdf-content-${index}`}
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                            // justifyContent: 'space-between'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <div
                              className='mx-2'
                              style={{
                                fontSize: 15,
                                display: 'flex'
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    marginBottom: 5
                                  }}
                                >
                                  {openPrintPDfDialogData.originCompany}
                                </p>

                                <p
                                  style={{
                                    marginBottom: 5
                                  }}
                                >
                                  {' '}
                                  {openPrintPDfDialogData.originAddress}
                                </p>
                                <p
                                  style={{
                                    marginBottom: 5
                                  }}
                                >
                                  {' '}
                                  {openPrintPDfDialogData.originCity +
                                    ' ' +
                                    openPrintPDfDialogData.originState +
                                    ' ' +
                                    openPrintPDfDialogData.originZipCode}
                                </p>
                                <p style={{ marginBottom: 5 }}>
                                  {' '}
                                  {openPrintPDfDialogData.originContactPerson}
                                </p>
                                <p style={{ marginBottom: 5 }}>
                                  {' '}
                                  {openPrintPDfDialogData.originContactPhone}
                                </p>
                              </div>
                              <div className='mx-3'>
                                <div>
                                  <b>Pickup:</b>{' '}
                                  {handleDate(
                                    openPrintPDfDialogData.actualPickupDate,
                                    'labels'
                                  )}
                                </div>{' '}
                                <div>
                                  <b>Weight:</b>{' '}
                                  {
                                    openPrintPDfDialogData?.details[0]
                                      ?.netWeight
                                  }{' '}
                                  LBS
                                </div>{' '}
                                <div>
                                  <b>Pieces:</b>{' '}
                                  {openPrintPDfDialogData?.details[0]?.pieces}
                                </div>{' '}
                                <div>
                                  <b>PO #:</b>{' '}
                                  {openPrintPDfDialogData.clientPONumber}
                                </div>{' '}
                                <div>
                                  <b>BOL #:</b>{' '}
                                  {openPrintPDfDialogData.bolNumber}
                                </div>{' '}
                              </div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', marginTop: 10 }}>
                            <div style={{ marginLeft: -25 }}>
                              <b>TO:</b>
                            </div>
                            <div
                              className='mx-2'
                              style={{
                                fontSize: 15
                              }}
                            >
                              <p
                                style={{
                                  marginBottom: 5,
                                  fontWeight: 'bold'
                                }}
                              >
                                {openPrintPDfDialogData.destinationCompany}
                              </p>

                              <p
                                style={{
                                  marginBottom: 5
                                }}
                              >
                                {' '}
                                {openPrintPDfDialogData.destinationAddress}
                              </p>
                              <p
                                style={{
                                  marginBottom: 5
                                }}
                              >
                                {' '}
                                {openPrintPDfDialogData.destinationCity +
                                  ' ' +
                                  openPrintPDfDialogData.destinationState +
                                  ' ' +
                                  openPrintPDfDialogData.destinationZipCode}
                              </p>
                              <p
                                style={{ marginBottom: 5, fontWeight: 'bold' }}
                              >
                                {' '}
                                {
                                  openPrintPDfDialogData.destinationContactPerson
                                }
                              </p>
                              <p style={{ marginBottom: 5 }}>
                                {' '}
                                {openPrintPDfDialogData.destinationContactPhone}
                              </p>
                            </div>
                          </div>
                          <div
                          // style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {openPrintPDfDialogData.proNumber && (
                              <div>
                                <div
                                  style={{
                                    margin: '10 0 -5 10'
                                  }}
                                >
                                  <b>
                                    PRO #: {openPrintPDfDialogData.proNumber}
                                  </b>
                                </div>
                                <Barcode
                                  displayValue={false}
                                  height={45}
                                  fontSize={20}
                                  value={openPrintPDfDialogData.proNumber}
                                />
                              </div>
                            )}
                            <div
                              style={{
                                marginTop: 10,
                                display: 'flex',
                                justifyContent: 'space-evenly'
                              }}
                            >
                              <img
                                style={{ objectFit: 'cover' }}
                                alt=''
                                height={80}
                                width={80}
                                src={
                                  openPrintPDfDialogData.carrierLogo
                                    ? base_logo_url +
                                      openPrintPDfDialogData.carrierLogo
                                    : NoLogo
                                }
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  fontSize: 13
                                  // marginLeft: 10
                                }}
                              >
                                {openPrintPDfDialogData.carrierName}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center'
              }}
            >
              <div>
                <label style={{ fontSize: '13' }} htmlFor='printSelect'>
                  Number of Prints:
                </label>
                <select
                  className='mx-1'
                  id='printSelect'
                  value={numberOfPrintsColmnVise}
                  onChange={e =>
                    setNumberOfPrintsColmnVise(parseInt(e.target.value, 10))
                  }
                >
                  <option value={1}>2</option>
                  <option value={2}>4</option>
                  <option value={3}>6</option>
                  {/* <option value={4}>8</option>
                  <option value={5}>10</option> */}
                </select>
              </div>
              <Button size='small' variant='contained' onClick={generatePDF}>
                Generate PDF
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        sx={{ minWidth: 400 }}
        open={DateFilterModal}
        onClose={handleDateFilterModalClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Date Filter</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <TextField
              // value={holdShipmentRemarks}
              onChange={handleDateFilterModalClose}
              variant='standard'
              type='date'
              // fullWidth
              sx={{ width: '13vw' }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleDateFilterModalClose}>
            Cancel
          </Button>
          <Button
            variant='contained'

            // autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{ minWidth: 400 }}
        open={openHoldShipmentDialog}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {markDeliverDialog
            ? 'Are you sure to mark as Deilvered ?'
            : shipmentDialogName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {!markDeliverDialog && (
              <TextField
                value={holdShipmentRemarks}
                onChange={e => setHoldShipmentRemarks(e.target.value)}
                variant='standard'
                label='Remarks'
                multiline
                maxRows={4}
                type='text'
                sx={{ width: '21vw' }}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={
              markDeliverDialog
                ? markAsDeliverRequest
                : shipmentDialogName === 'Hold Shipment'
                ? handleHoldShipmentRequest
                : handleUnBookShipmentRequest
            }
            // autoFocus
          >
            {markDeliverDialog ? 'Confirm' : shipmentDialogName}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{ minWidth: 700 }}
        open={addUpdateLocationDialog}
        onClose={handleCloseAddUpdateLocation}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Update Location</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {agencyLocationsLoader ? (
              <Box
                sx={{
                  width: '27vw',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <CircularProgress size={25} />
              </Box>
            ) : (
              <Autocomplete
                sx={{ width: '27vw' }}
                size='small'
                value={agencyLocations.find(
                  opt => opt.name === clickedRowLocation
                )}
                onChange={(event, newValue) =>
                  handleAdminLocationChange(event, newValue)
                }
                renderOption={(PreviousProps, option, { selected }) => (
                  <li {...PreviousProps}>
                    {`${option.name}: ${option.city}  ${
                      option.state ? option.state : option.stateOrProvinceCode
                    }  ${option.postalCode}`}
                  </li>
                )}
                getOptionLabel={
                  option =>
                    // option.city ?
                    `${option.city}  ${
                      option.state ? option.state : option.stateOrProvinceCode
                    }  ${option.postalCode}`
                  // : 'Select location'
                }
                options={agencyLocations}
                renderInput={params => (
                  <TextField
                    required
                    label='Locations'
                    variant='standard'
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 13 }
                    }}
                    // error={Boolean(validationErrors['locationsError'])}
                    // helperText={validationErrors['locationsError']}
                  />
                )}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size='small'
            variant='outlined'
            onClick={handleCloseAddUpdateLocation}
          >
            Cancel
          </Button>
          <Button
            onClick={handleOpenAddUpdateLocReq}
            // disabled={addUpdateLocationID ? false : true}
            size='small'
            variant='contained'
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {!openHoldShipmentDialog && (
        <div ref={componentRef}>
          <ToastContainer
            style={{ width: 'auto' }}
            position='top-center'
            autoClose={6800}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <link
            rel='stylesheet'
            href='https://fonts.googleapis.com/icon?family=Material+Icons'
          />

          <div>
            <MaterialReactTable
              // enableExpanding={
              //   (userPermissionsArr &&
              //     userPermissionsArr.filter(permissions => permissions.level2)
              //       .length) ||
              //   UserIsAdmin
              //     ? true
              //     : false
              // }
              // enableExpandAll={
              //   (userPermissionsArr &&
              //     userPermissionsArr.filter(permissions => permissions.level2)
              //       .length) ||
              //   UserIsAdmin
              //     ? true
              //     : false
              // }
              resetColumnOrder
              // autoResetAll
              autoResetExpanded={true}
              // onColumnFiltersChange={setColumnFilters}
              onColumnVisibilityChange={setColumnVisibility}
              onColumnOrderChange={setColumnOrder}
              state={{
                isLoading: shipmentsLoader,
                columnVisibility,
                columnOrder
                // draggingColumn
                // columnFilters,
                // density,
                // globalFilter,
                // showColumnFilters,
                // showGlobalFilter,
                // sorting
              }}
              localization={{
                rowNumbers: '20'
              }}
              columns={tableCol}
              muiTableBodyRowProps={{ row: { backgroundColor: 'red' } }}
              data={handleFilteredTableData()}
              enableColumnOrdering
              enableColumnActions
              enableGrouping={expandedDetail}
              enableRowActions={value === 2 ? false : true}
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '1px' }}>
                  {(value === 0 || value === 1) &&
                    row.original.quoteId !== 0 && (
                      <Tooltip title='View' arrow>
                        <Button
                          onClick={() =>
                            handlePickupOpen(row.original, row, 'Get Quote')
                          }
                        >
                          <VisibilityIcon />
                        </Button>
                      </Tooltip>
                    )}
                  {(value === 0 || value === 1) &&
                    row.original.quoteId === 0 &&
                    (checkLocLoader && row.index === checkLocIndex ? (
                      <CircularProgress sx={{ ml: 2.5 }} size={23} />
                    ) : (
                      <Tooltip
                        title={
                          row.original.freightTerms.toLowerCase() === 'c' ||
                          row.original.freightTerms.toLowerCase() === 'n'
                            ? 'Pickup'
                            : 'Book'
                        }
                        arrow
                      >
                        <Button
                          onClick={() =>
                            row.original.freightTerms.toLowerCase() === 'c' ||
                            row.original.freightTerms.toLowerCase() === 'n'
                              ? handlePickupOpen(row.original, row, 'Dispatch')
                              : handlePickupOpen(row.original, row, 'Get Quote')
                          }
                        >
                          {row.original.freightTerms.toLowerCase() === 'c' ||
                          row.original.freightTerms.toLowerCase() === 'n' ? (
                            <LaunchTwoToneIcon />
                          ) : (
                            <MonetizationOnTwoToneIcon />
                          )}
                        </Button>
                      </Tooltip>
                    ))}
                  {/* {value === 0 && (
                      <Tooltip title='Update Location' arrow>
                        <Button
                          onClick={() => handleOpenAddUpdateLocation(row)}
                        >
                          <EditLocationAltIcon />
                        </Button>
                      </Tooltip>
                    )} */}
                  {value === 2 && (
                    <>
                      <Tooltip title='Picked Up' arrow>
                        <Button
                          onClick={() => {
                            handlePickupOpen(row.original, row, 'Dispatch');
                          }}
                        >
                          <LaunchTwoToneIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={
                          row.original.shipmentStatus.toLowerCase() === 'hold'
                            ? 'UnHold'
                            : 'Hold'
                        }
                        arrow
                      >
                        <Button
                          onClick={() => {
                            row.original.shipmentStatus.toLowerCase() === 'hold'
                              ? handleUnHoldShipmentRequest(row.original)
                              : handleClickOpen('Hold', row.original);
                          }}
                        >
                          {row.original.shipmentStatus.toLowerCase() ===
                          'hold' ? (
                            <DoNotTouchIcon />
                          ) : (
                            <PanToolTwoToneIcon />
                          )}
                        </Button>
                      </Tooltip>
                      <Tooltip title='UnBook' arrow>
                        <Button
                          onClick={() => {
                            handleClickOpen('UnBook', row.original);
                          }}
                        >
                          <DisabledByDefaultTwoToneIcon />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                  {value !== 0 &&
                    value !== 1 &&
                    row.original.shipmentType !== 'TL' && (
                      <>
                        <Tooltip title='Print Labels' arrow>
                          <Button
                            sx={{ zIndex: 1 }}
                            onClick={() => printData(row.original)}
                          >
                            <PrintIcon />
                          </Button>
                        </Tooltip>
                      </>
                    )}
                  {value === 3 &&
                    row.original.shipmentType === 'TL' &&
                    new Date(row.original.actualDeliveryDate) < new Date() && (
                      <div
                        style={{
                          backgroundColor: 'yellow',
                          width: '5450',
                          height: '60',
                          position: 'absolute',
                          zIndex: 0,
                          margin: '-10 0 0 0',
                          opacity: '0.2',
                          cursor: 'default'
                        }}
                      ></div>
                    )}
                  {value === 3 && row.original.shipmentType === 'TL' && (
                    <Tooltip title='Mark as Delivered' arrow>
                      <Button
                        sx={{ zIndex: 1 }}
                        onClick={() =>
                          handleMarkDeliver(row.original, 'MarkDeliver')
                        }
                      >
                        <TaskAltIcon />
                      </Button>
                    </Tooltip>
                  )}
                </Box>
              )}
              // // enableColumnResizing

              enableRowSelection
              // muiTableBodyRowProps={({ row, isDetailPanel }) => ({
              //   backgroundColor: 'red'
              // })}
              renderDetailPanel={({ row }) => handleViewShipments(row)}
              // muiTableHeadCellFilterTextFieldProps={{ placeholder: '' }}
              // enableStickyHeader
              // enableStickyFooter

              muiTableContainerProps={{
                sx: { maxHeight: '70vh', minHeight: '70vh' }
              }}
              initialState={{
                // showColumnFilters: true,
                density: 'compact'
              }}
              positionToolbarAlertBanner='bottom'
              renderToolbarInternalActions={({ table }) => (
                <div style={{ display: 'flex' }}>
                  <MRT_ToggleGlobalFilterButton table={table} title='Search' />
                  <Tooltip arrow title='Print'>
                    <IconButton
                      sx={{ m: 0.5 }}
                      onClick={() => {
                        handlePrint();
                      }}
                    >
                      <LocalPrintshopOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow title='Import'>
                    <IconButton
                      sx={{ m: 0.5 }}
                      onClick={() => fileInputRef?.current?.click()}
                    >
                      <input
                        ref={fileInputRef}
                        type='file'
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      ></input>
                      <svg
                        style={{ transform: 'rotate(180deg)' }}
                        width='17'
                        height='21'
                        viewBox='0 0 17 21'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10.625 0.828125H2.625C1.525 0.828125 0.635 1.72812 0.635 2.82812L0.625 18.8281C0.625 19.9281 1.515 20.8281 2.615 20.8281H14.625C15.725 20.8281 16.625 19.9281 16.625 18.8281V6.82812L10.625 0.828125ZM14.625 18.8281H2.625V2.82812H9.625V7.82812H14.625V18.8281ZM4.625 13.8381L6.035 15.2481L7.625 13.6681V17.8281H9.625V13.6681L11.215 15.2581L12.625 13.8381L8.635 9.82812L4.625 13.8381Z'
                          fill='currentColor'
                        />
                      </svg>
                    </IconButton>
                  </Tooltip>
                  {/* Export funtion */}
                  {/* <Tooltip
                      sx={{ m: 0.5 }}
                      arrow
                      title={
                        !table.getIsSomeRowsSelected() &&
                        !table.getIsAllRowsSelected()
                          ? 'Export All Rows'
                          : 'Export Selected Rows'
                      }
                    >
                      <IconButton
                        // onClick={handleExportData}
                        onClick={() =>
                          !table.getIsSomeRowsSelected() &&
                          !table.getIsAllRowsSelected()
                            ? handleExportData(table.getCoreRowModel().rows)
                            : handleExportRows(table.getSelectedRowModel().rows)
                        }
                        // sx={{ marginRight: -1.5 }}
                      >
                        <svg
                          width='17'
                          height='21'
                          viewBox='0 0 17 21'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10.625 0.828125H2.625C1.525 0.828125 0.635 1.72812 0.635 2.82812L0.625 18.8281C0.625 19.9281 1.515 20.8281 2.615 20.8281H14.625C15.725 20.8281 16.625 19.9281 16.625 18.8281V6.82812L10.625 0.828125ZM14.625 18.8281H2.625V2.82812H9.625V7.82812H14.625V18.8281ZM4.625 13.8381L6.035 15.2481L7.625 13.6681V17.8281H9.625V13.6681L11.215 15.2581L12.625 13.8381L8.635 9.82812L4.625 13.8381Z'
                            fill='currentColor'
                          />
                        </svg>
                      </IconButton>
                    </Tooltip> */}

                  <MRT_ToggleFiltersButton
                    table={table}
                    title='Filters'
                    // onClick={() => table.resetColumnFilters(true)}
                  />
                  <MRT_ToggleDensePaddingButton table={table} title='Spacing' />
                  <MRT_ShowHideColumnsButton
                    table={table}
                    title='Show/Hide Columns'
                  />
                  <MRT_FullScreenToggleButton
                    table={table}
                    title='Fullscreen'
                  />

                  <Tooltip title='Clear All Filters' arrow>
                    <IconButton
                      onClick={() => {
                        table.resetColumnFilters(true);
                        table.setShowColumnFilters(false);
                      }}
                    >
                      <FilterAltOffIcon />
                    </IconButton>
                  </Tooltip>
                  {/* <Tooltip title='Apply current grid settings' arrow>
                      <IconButton onClick={handleGridApplySettings}>
                        <GridOnIcon />
                      </IconButton>
                    </Tooltip> */}
                </div>
              )}
              renderTopToolbarCustomActions={({ table }) => {
                return (
                  <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <Tabs
                      sx={{ mb: 1 }}
                      value={value}
                      onChange={handleTabChange}
                      aria-label='basic tabs example'
                    >
                      <Tab
                        sx={{
                          fontWeight: 600,
                          fontSize: 14,
                          width: 150
                        }}
                        label=<div className='Tabs_Badge_Title'>
                          <Badge
                            color='primary'
                            sx={{ padding: '0 15 0 0' }}
                            badgeContent={
                              savedShipments.length === 0
                                ? '0'
                                : savedShipments.length
                            }
                          />
                          <div>Saved</div>
                        </div>
                        {...a11yProps(0)}
                      />
                      <Tab
                        sx={{
                          fontWeight: 600,
                          fontSize: 14,
                          width: 150
                        }}
                        label=<div className='Tabs_Badge_Title'>
                          <Badge
                            color='error'
                            sx={{ padding: '0 15 0 0' }}
                            badgeContent={
                              tenderedShipments.length === 0
                                ? '0'
                                : tenderedShipments.length
                            }
                          />
                          <div>Tendered</div>
                        </div>
                        {...a11yProps(1)}
                      />
                      <Tab
                        sx={{
                          fontWeight: 600,
                          fontSize: 14,
                          width: 150
                        }}
                        label=<div className='Tabs_Badge_Title'>
                          <div style={{ backgroundColor: '#f3e5f5' }}>
                            <Badge
                              sx={{ padding: '0 15 0 0' }}
                              badgeContent={
                                bookedShipments.length === 0
                                  ? '0'
                                  : bookedShipments.length
                              }
                              variant='standard'
                              color='secondary'
                            />
                          </div>
                          <div>Booked</div>
                        </div>
                        {...a11yProps(2)}
                      />
                      <Tab
                        sx={{
                          fontWeight: 600,
                          fontSize: 14,
                          width: 150
                        }}
                        label=<div className='Tabs_Badge_Title'>
                          <div style={{ backgroundColor: '#f3e5f5' }}>
                            <Badge
                              sx={{ padding: '0 15 0 0' }}
                              badgeContent={
                                inTransitShipments.length === 0
                                  ? '0'
                                  : inTransitShipments.length
                              }
                              variant='standard'
                              color='warning'
                            />
                          </div>
                          <div>In-Transit</div>
                        </div>
                        {...a11yProps(3)}
                      />
                      <Tab
                        sx={{
                          fontWeight: 600,
                          fontSize: 14,
                          width: 150
                        }}
                        label=<div className='Tabs_Badge_Title'>
                          <div style={{ backgroundColor: '#f3e5f5' }}>
                            <Badge
                              sx={{ padding: '0 15 0 0' }}
                              badgeContent={
                                deliveredShipments.length === 0
                                  ? '0'
                                  : deliveredShipments.length
                              }
                              variant='standard'
                              color='success'
                            />
                          </div>
                          <div>Delivered</div>
                        </div>
                        {...a11yProps(4)}
                      />
                    </Tabs>
                  </div>
                );
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
export default memo(BasicTreeData);

import { Button } from '@mui/material';
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    // this.state = { hasError: false };
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo
    });
    // You can log the error to an error reporting service
    console.error('Error caught by error boundary:', error, errorInfo);
  }
  handleRedirect = () => {
    // Replace 'new-url' with the URL you want to redirect to
    window.location.href = '/dashboard';
  };
  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'ghostwhite'
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <h2>Whoops, Something went wrong.</h2>
            <br />
            <div>
              <p>
                We apologize for the inconvenience. Please either refresh the
                page or return home to try again.
                {/* {this.state.error.toString()} */}
              </p>
              {/* <details style={{ whiteSpace: 'pre-wrap' }}>
                {this.state.errorInfo.componentStack}
              </details> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={this.handleRedirect}
                variant='contained'
                color='success'
              >
                Home
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
